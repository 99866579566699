import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    product: {
        type: '',
        customerApprovals: [],
        hasScript: false,
        energyUsageLimitationType: 'NONE',
        energyHasEanBlocker: false,
        solarPanelPower: null,
        hasFixedPanelCount: false,
        fixedPanelCount: null,
        solarMinPanelCount: null,
    },
    types: [],
    productFlowQuestions: [],
    products: [],
    files: [],
    loadingSyncTariffForProduct: false,
    loadingProducts: false,
    loadingProductTypes: false,
    loadingProduct: true,
    loadingFlowQuestions: true,
    loadingPatchFlowQuestions: false,
    loadingCreateProduct: false,
    loadingPatchProduct: false,
    loadingDeleteProduct: false,
    loadingProductFiles: false,
    loadingProductFilesUpload: false,
    loadingDeleteProductFile: false,
    errorProducts: false,
    errorProduct: false,
    errorProductTypes: false,
    errorCreateProduct: false,
    errorPatchProduct: false,
    errorDeleteProduct: false,
    errorFlowQuestions: false,
    errorPatchFlowQuestions: false,
    errorProductFiles: false,
    errorProductFilesUpload: false,
    errorDeleteProductFile: false,
    errorSyncTariffForProduct: false,
    count: 0,
    currentPage: 1,
    pageSize: 20,
    filters: {
        organizationID: [],
        roleID: [],
        status: [],
        page: 1,
        pageSize: state.pageSize,
    },
    filesCount: 0,
    filesCurrentPage: 1,
});

const actions = {
    /**
     *
     * Get product
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getProduct({commit}, id) {
        try {
            await commit('productDataBegin');
            const response = await DataService.get(`/products/${id}`);
            await commit('productDataSuccess', response.data);
        } catch (err) {
            await commit('productDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create product
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createProduct({commit}, data) {
        try {
            await commit('productDataCreateBegin');
            const response = await DataService.post(`/products`, data.value);
            await commit('productDataCreateSuccess', response.data);
            data.close();
        } catch (err) {
            await commit('productDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update product
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchProduct({commit}, data) {
        try {
            await commit('productDataPatchBegin');
            const response = await DataService.patch(`/products/${data.id}`, data);
            await commit('productDataPatchSuccess', response.data);
            addNotificationSuccess('Wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('productDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete product (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteProduct({commit}, id) {
        try {
            await commit('productDataDeleteBegin');
            const response = await DataService.delete(`/products/${id}`);
            await commit('productDataDeleteSuccess', response.data);
            addNotificationSuccess('Succesvol verwijderd.');
        } catch (err) {
            await commit('productDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get products (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getProducts({commit}, data) {
        try {
            await commit('productsDataBegin');
            const response = await DataService.get('/products', data);
            await commit('productsDataSuccess', response.data);
        } catch (err) {
            await commit('productsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get products (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getProductFlowQuestions({commit}, id) {
        try {
            await commit('productFlowQuestionsDataBegin');
            const response = await DataService.get(`/products/${id}/flowquestions`);
            await commit('productFlowQuestionsDataSuccess', response.data);
        } catch (err) {
            await commit('productFlowQuestionsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchProductFlowQuestion({commit}, data) {
        try {
            await commit('productFlowQuestionDataPatchBegin');
            await DataService.patch(`/products/${data.id}/flowquestions`, data.flowQuestions);
            await commit('productFlowQuestionDataPatchSuccess');
            addNotificationSuccess('Wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('productFlowQuestionDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getProductFiles({commit}, id) {
        try {
            await commit('productFilesDataBegin');
            const response = await DataService.get(`/products/${id}/files`, {pageSize: 10});
            await commit('productFilesDataSuccess', response.data);
        } catch (err) {
            await commit('productFilesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param dispatch
     * @param data
     * @returns {Promise<void>}
     */
    async uploadProductFile({commit, dispatch}, data) {
        try {
            await commit('productFilesUploadBegin');
            await DataService.postMultiPart(`/products/${data.id}/files`, data.formData);
            await commit('productFilesUploadSuccess');
            await dispatch('getProductFiles', data.id)
        } catch (err) {
            await commit('productFilesUploadErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteProductFile({commit}, data) {
        try {
            await commit('productFileDeleteBegin');
            const response = await DataService.delete(`/products/${data.productID}/files/${data.fileID}`);
            await commit('productFileDeleteSuccess', response.data);
            addNotificationSuccess('Succesvol verwijderd.');
        } catch (err) {
            await commit('productFileDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageProduct({commit}, page) {
        await commit('setCurrentPageProduct', page);
    },
    /**
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async syncTariffForProduct({commit}, data) {
        try {
            await commit('syncTariffForProductBegin');
            await DataService.post(`/products/${data.id}/synctariff`);
            await commit('syncTariffForProductSuccess');
        } catch (err) {
            await commit('syncTariffForProductErr', err);
        }
    },
    /**
     *
     * Get product
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async fetchAvailableProductTypes({commit}) {
        try {
            await commit('availableProductTypesBegin');
            const response = await DataService.get('/products/availabletypes');
            await commit('availableProductTypesSuccess', response.data);
        } catch (err) {
            await commit('availableProductTypesErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
