export default {
    aclDataBegin(state) {
        state.loading = true;
        state.error = false;
    },
    aclDataSuccess(state, data) {
        localStorage.setItem('ability', JSON.stringify(data))
        state.loading = false;
    },
    aclDataErr(state, err) {
        state.loading = false;
        state.error = err;
    },
};
