import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "@/config/dataService/dataService";
// import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    order: {
        type: 'HYDROMX',
        relation: {
            type: 'CONSUMER',
        },
        relationID: null,
        signatureType: 'ESIGNATURE',
        signatureData: null,
        amount: null,
        flowQuestions: [],
    },
    errorPreFillRelationOnOrder: false,
    loadingPreFillRelationOnOrder: false,
    loadingCreateOrder: false,
    errorCreateOrder: false,
    errorFetchHydromxQuestions: false,
    loadingFetchHydromxQuestions: false,
    loadingCalculatePrice: false,
    errorCalculatePrice: false,
});

const actions = {
    async setOrderFlowHydromx({commit}, flow) {
        await commit('setOrderFlowHydromx', flow);
    },
    async setSignatureHydromxData({commit}, flow) {
        await commit('setSignatureHydromxData', flow);
    },
    async addRelationToHydromxOrder({commit}, data) {
        await commit('addRelationToHydromxOrder', data.value);
        await data.stepRoute();
    },
    async preFillRelationOnHydromxOrder({commit}, data) {
        try {
            await commit('preFillRelationOnHydromxOrderBegin');
            await commit('addRelationToHydromxOrder', data.value);
            await commit('preFillRelationData', data.value)
            await commit('preFillRelationOnHydromxOrderSuccess');
            await data.stepRoute();
        } catch (err) {
            await commit('preFillRelationOnHydromxOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async calculatePrice({commit}, data) {
        try {
            await commit('calculatePriceBegin');
            await commit('addQuestionsHydromxOrder', data.value);
            const response = await DataService.post('/offerflow/hydromx/draftcalculation', data.value);
            await commit('calculatePriceSuccess', response.data);
            await data.stepRoute();
        } catch (err) {
            await commit('calculatePriceErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createHydromxOrder({commit, dispatch, state}, data) {
        try {
            await commit('hydromxOrderCreateBegin');
            await DataService.post(`/orders`, state.order);
            await commit('hydromxOrderCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetHydromxOrder');
        } catch (err) {
            await commit('hydromxOrderCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async resetHydromxOrder({commit}) {
        await commit('resetHydromxOrder');
    },
    async patchHydromxQuestions({state, commit}, id) {
        try {
            await commit('patchHydromxQuestionsBegin');
            await DataService.patch(`/orders/${id}/hydromxquestions`, state.hydromxQuestions);
            await commit('patchHydromxQuestionsSuccess');
            await addNotificationSuccess('Succesvol geupdate!');
        } catch (err) {
            await commit('patchHydromxQuestionsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
