import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (msg) => {
    notification.success({
        message: msg,
    });
};

const state = () => ({
    organization: {
        id: null,
        type: null,
        parentID: null,
        name: null,
        active: null,
        senderID: null,
        imageFile: null,
        primaryColor: null,
        secondaryColor: null,
        cireID: null,
        createTimestamp: null,
        modifiedTimestamp: null,
        deletedTimestamp: null,
        enforceTFA: false,
        productTree: null,
        allowEsignatureSigning: false,
        allowSignatureSigning: false,
    },
    organizations: [],
    loadingOrganizations: false,
    loadingOrganization: true,
    loadingCreateOrganization: false,
    loadingPatchOrganization: false,
    loadingDeleteOrganization: false,
    errorOrganizations: false,
    errorOrganization: false,
    errorCreateOrganization: false,
    errorPatchOrganization: false,
    errorDeleteOrganization: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get Organization
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getOrganization({commit}, id) {
        try {
            await commit('organizationDataBegin');
            const response = await DataService.get(`/organizations/${id}`);
            await commit('organizationDataSuccess', response.data);
        } catch (err) {
            await commit('organizationDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create organization
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createOrganization({commit}, data) {
        try {
            await commit('organizationDataCreateBegin');
            const response = await DataService.post(`/organizations`, data.value);
            await commit('organizationDataCreateSuccess', response.data);
            data.close();
        } catch (err) {
            await commit('organizationDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update organization
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchOrganization({commit}, data) {
        try {
            await commit('organizationDataPatchBegin');
            const response = await DataService.patch(`/organizations/${data.id}`, data);
            await commit('organizationDataPatchSuccess', response.data);
            addNotificationSuccess('Wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('organizationDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete organization (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteOrganization({commit}, id) {
        try {
            await commit('organizationDataDeleteBegin');
            const response = await DataService.delete(`/organizations/${id}`);
            await commit('organizationDataDeleteSuccess', response.data);
            addNotificationSuccess('Succesvol verwijderd.');
        } catch (err) {
            await commit('organizationDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get organizations (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getOrganizations({commit}, data) {
        try {
            await commit('organizationsDataBegin');
            const response = await DataService.get('/organizations', data);
            await commit('organizationsDataSuccess', response.data);
        } catch (err) {
            await commit('organizationsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     Organization   *
     * Get product tree config
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getOrganizationAllowedProductTree({commit}, id) {
        try {
            await commit('organizationAllowedProductTreeDataBegin');
            const response = await DataService.get(`/organizations/${id}/allowedproducttree`);
            await commit('organizationAllowedProductTreeDataSuccess', response.data);
        } catch (err) {
            await commit('organizationAllowedProductTreeDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Patch product tree config
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async patchOrganizationAllowedProductTree({commit}, data) {
        try {
            await commit('organizationPatchAllowedProductTreeDataBegin');
            const response = await DataService.patch(`/organizations/${data.id}/allowedproducttree`, data.productTree);
            await commit('organizationPatchAllowedProductTreeDataSuccess', response.data);
            addNotificationSuccess('Wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('organizationPatchAllowedProductTreeDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get product tree config
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getOrganizationProductTreeConfig({commit}) {
        try {
            await commit('organizationProductTreeConfigDataBegin');
            const response = await DataService.get(`/organizations/producttree`);
            await commit('organizationProductTreeConfigDataSuccess', response.data);
        } catch (err) {
            await commit('organizationProductTreeConfigDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageOrganization({commit}, page) {
        await commit('setCurrentPageOrganization', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
