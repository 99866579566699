import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    sender: {
        city: null,
        country: null,
        createTimestamp: null,
        email: null,
        houseNumber: null,
        houseNumberAddition: null,
        id: null,
        imageFileURL: '',
        name: null,
        phoneNumber: null,
        postalCode: null,
        primaryColor: null,
        secondaryColor: null,
        senderEmail: null,
        senderEmailVerified: null,
        senderName: null,
        street: null,
        smsOriginator: null,
    },
    senders: [],
    loadingSenders: false,
    loadingSender: true,
    loadingCreateSender: false,
    loadingPatchSender: false,
    loadingDeleteSender: false,
    errorSenders: false,
    errorSender: false,
    errorCreateSender: false,
    errorPatchSender: false,
    errorDeleteSender: false,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get sender
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getSender({commit}, id) {
        try {
            await commit('senderDataBegin');
            const response = await DataService.get(`/senders/${id}`);
            await commit('senderDataSuccess', response.data);
        } catch (err) {
            await commit('senderDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create sender
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createSender({commit, dispatch}, data) {
        try {
            await commit('senderDataCreateBegin');
            const response = await DataService.post(`/senders`, data.value);
            await commit('senderDataCreateSuccess', response.data);
            await dispatch('getSenders');
            data.close();
        } catch (err) {
            await commit('senderDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update sender
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchSender({commit, dispatch}, data) {
        try {
            await commit('senderDataPatchBegin');
            await DataService.patch(`/senders/${data.id}`, data);
            await commit('senderDataPatchSuccess');
            addNotificationSuccess('De leverancier is met succes aangepast.');
            await dispatch('getSenders');
        } catch (err) {
            await commit('senderDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete sender (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteSender({commit, dispatch}, id) {
        try {
            await commit('senderDataDeleteBegin');
            await DataService.delete(`/senders/${id}`);
            await commit('senderDataDeleteSuccess');
            await dispatch('getSenders');
            addNotificationSuccess('De afzender is met succes verwijderd.');
        } catch (err) {
            await commit('senderDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get senders (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getSenders({commit}, data) {
        try {
            await commit('sendersDataBegin');
            const response = await DataService.get('/senders', data);
            await commit('sendersDataSuccess', response.data);
        } catch (err) {
            await commit('sendersDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Show or hide sender edit screen
     *
     * @param commit
     * @param bool
     * @returns {Promise<void>}
     */
    async showHideEdit({commit}, bool) {
        await commit('showHideEdit', bool);
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageSenders({commit}, page) {
        await commit('setCurrentPageSenders', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
