export default {
    dataBeginEdsn(state) {
        state.loading = true;
        state.error = false;
    },
    dataSuccessEdsn(state) {
        state.loading = false;
    },
    dataErrEdsn(state, err) {
        state.loading = false;
        state.error = err;
    },
};
