import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
import '@/core/plugins/ant-design';
import '@/core/plugins/vendor';
import '@/core/plugins/fonts';
import '@/core/plugins/maps';
import '@/core/plugins/masonry';
import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import {abilitiesPlugin} from "@casl/vue";
import ability from "./core/plugins/acl/ability";
import * as Sentry from "@sentry/vue";
import {vVisible} from "@nanogiants/vue3-visible";
import cronAnt from '@vue-js-cron/ant'
import '@vue-js-cron/ant/dist/ant.css'

app.config.productionTip = false;


app.use(cronAnt)
app.use(store);
app.use(router);
app.directive('visible', vVisible);
app.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
});
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        dsn: "https://4213c035176d4f2e810a177df6c22989@o1239016.ingest.sentry.io/6426104",
    });
}
app.mount('#app');
