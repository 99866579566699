export default {
    patchSVMQuestionsBegin(state) {
        state.errorPatchSVMQuestions = false;
        state.loadingPatchSVMQuestions = true;
    },
    patchSVMQuestionsSuccess(state) {
        state.loadingPatchSVMQuestions = false;
    },
    patchSVMQuestionsErr(state, err) {
        state.loadingPatchSVMQuestions = false;
        state.errorPatchSVMQuestions = err;
    },
    fetchSVMQuestionsBegin(state) {
        state.svmQuestions = [];
        state.errorFetchSVMQuestions = false;
        state.loadingFetchSVMQuestions = true;
    },
    fetchSVMQuestionsSuccess(state, data) {
        state.svmQuestions = data;
        state.loadingFetchSVMQuestions = false;
    },
    fetchSVMQuestionsErr(state, err) {
        state.loadingFetchSVMQuestions = false;
        state.errorFetchSVMQuestions = err;
    },
    preFillRelationOnSVMOrderBegin(state) {
        state.errorPreFillRelationOnOrder = false;
        state.loadingPreFillRelationOnOrder = true;
    },
    preFillRelationOnSVMOrderSuccess(state) {
        state.loadingPreFillRelationOnOrder = false;
    },
    preFillRelationOnSVMOrderErr(state, err) {
        state.loadingPreFillRelationOnOrder = false;
        state.errorPreFillRelationOnOrder = err;
    },
    setOrderFlowLead(state, data) {
        const order = state.order;
        order.signatureType = data.flow;
        order.type = data.config.type;
        state.order = order;
    },
    setOrderFlowSVM(state, data) {
        const order = state.order;
        order.signatureType = data.flow;
        order.type = data.config.type;
        state.order = order;
    },
    addRelationToSVMOrder(state, data) {
        let order = state.order;
        order.relation = data;
        if (data.id !== undefined) {
            order.relationID = data.id;
        }

        state.order = order;
    },
    addSVMComponent(state, item) {
        const order = state.order;
        order.svmQuestions.usages.push(JSON.parse(item));
        state.order = order;
    },
    addSVMOnOrderComponent(state, item) {
        const svmQuestions = state.svmQuestions;
        svmQuestions.usages.push(JSON.parse(item));
        state.svmQuestions = svmQuestions;
    },
    removeSVMComponent(state, index) {
        const order = state.order;
        order.svmQuestions.usages.splice(index, 1);
        state.order = order;
    },
    removeSVMOnOrderComponent(state, index) {
        const svmQuestions = state.svmQuestions;
        svmQuestions.usages.splice(index, 1);
        state.svmQuestions = svmQuestions;
    },
    setSignatureSVMData(state, data) {
        const order = state.order;
        order.signatureData = data;
        state.order = order;
    },
    resetSVMOrder(state) {
        state.order = {
            minimisSupport: null,
            type: 'SVM',
            relation: {type: 'BUSINESS'},
            relationID: null,
            signatureType: 'ESIGNATURE',
            signatureData: null,
            svmQuestions: {
                energyTariff: 0.44,
                gasTariff: 1.98,
                oldLightingCount: null,
                newLightingCount: null,
                hasSolar: false,
                solarAvailable: false,
                roofSurface: null,
                hasSmartThermostat: false,
                glassSurface: null,
                currentGlassType: null,
                usages: [
                    {
                        id: 'LIGHTING',
                        type: 'ENERGY',
                        group: 'LICHT',
                        description: 'Totaal aantal lampen',
                        usage: 0,
                        amount: 0,
                        amountPerUnit: 180,
                        unit: 'Stuk(s)',
                        default: true,
                    },
                ],
            },
        };
    },
    svmOrderCreateBegin(state) {
        state.loadingCreateOrder = true;
        state.errorCreateOrder = false;
    },
    svmOrderCreateSuccess(state) {
        state.loadingCreateOrder = false;
    },
    svmOrderCreateErr(state, err) {
        state.loadingCreateOrder = false;
        state.errorCreateOrder = err;
    },
};
