import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    substatus: {
        id: null,
        name: null,
    },
    allPermissions: [],
    substatusses: [],
    loadingSubStatusses: false,
    loadingSubStatus: false,
    loadingCreateSubStatus: false,
    loadingPatchSubStatus: false,
    loadingDeleteSubStatus: false,
    errorSubStatuss: false,
    errorSubStatus: false,
    errorCreateSubStatus: false,
    errorPatchSubStatus: false,
    errorDeleteSubStatus: false ,
    count: 0,
    currentPage: 1,
    pageSize: 10,
    parentStatusses: [],
});

const actions = {
    /**
     *
     * Get substatus
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getSubStatus({commit}, id) {
        try {
            await commit('substatusDataBegin');
            const response = await DataService.get(`/substatuses/${id}`);
            await commit('substatusDataSuccess', response.data);
        } catch (err) {
            await commit('substatusDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create substatus
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createSubStatus({commit, dispatch}, data) {
        try {
            await commit('substatusDataCreateBegin');
            await DataService.post(`/substatuses`, data.value);
            await commit('substatusDataCreateSuccess');
            await dispatch('getSubStatusses');
            data.close();
        } catch (err) {
            await commit('substatusDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update substatus
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchSubStatus({commit, dispatch}, data) {
        try {
            await commit('substatusDataPatchBegin');
            await DataService.patch(`/substatuses/${data.id}`, data);
            await commit('substatusDataPatchSuccess');
            await dispatch('getSubStatusses');
            addNotificationSuccess('De wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('substatusDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete substatus (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteSubStatus({commit, dispatch}, id) {
        try {
            await commit('substatusDataDeleteBegin');
            await DataService.delete(`/substatuses/${id}`);
            await commit('substatusDataDeleteSuccess');
            await dispatch('getSubStatusses');
            addNotificationSuccess('De substatus is met succes verwijderd.');
        } catch (err) {
            await commit('substatusDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get substatusses (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getSubStatusses({commit}) {
        try {
            await commit('substatussesDataBegin');
            const response = await DataService.get('/substatuses');
            await commit('substatussesDataSuccess', response.data);
        } catch (err) {
            await commit('substatussesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getParentStatusses({commit}, data) {
        try {
            // await commit('substatussesDataBegin');
            const response = await DataService.get('/statuses', data);
            await commit('parentstatussesDataSuccess', response.data);
        } catch (err) {
            await commit('substatussesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get all permissions (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getSubStatusPermissions({commit}, data) {
        try {
            // await commit('substatusDataBegin');
            const response = await DataService.get('/substatusses/allpermissions', data);
            await commit('substatusPermissionsDataSuccess', response.data);
        } catch (err) {
            await commit('substatusDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageSubStatus({commit}, page) {
        await commit('setCurrentPageSubStatus', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
