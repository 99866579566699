const routes = [
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
    meta: {
      action: 'view',
      resource: 'auth',
    },
  },
  {
    path: 'password',
    name: 'password',
    component: () => import(/* webpackChunkName: "password" */ '@/view/authentication/SetPassword.vue'),
    meta: {
      action: 'view',
      resource: 'auth',
    },
  },
];

export default routes;
