export default {
    dataBegin(state) {
        state.loadingSearch = true;
        state.errorSearch = false;
    },
    dataSuccess(state, data) {
        state.data = data;
        state.loadingSearch = false;
    },
    dataErr(state, err) {
        state.loadingSearch = false;
        state.errorSearch = err;
    },
    dataHouseNumberAdditionsBegin(state) {
        state.loadingHouseNumberAdditions = true;
        state.disabledGlobalSearchButton = true;
        state.houseNumberAdditions = [];
        state.errorHouseNumberAdditions = false;
    },
    dataHouseNumberAdditionsSuccess(state, data) {
        const options = []

        data.forEach((item) => {
            options.push(
                {
                    key: item !== '' ? item : 'Geen',
                    value: item !== '' ? item : 'geen',
                }
            )
        });

        const filteredOptions = options.length === 1 && options[0].value === 'geen' ? [] : options;
        state.houseNumberAdditions = filteredOptions;
        state.loadingHouseNumberAdditions = false;
        state.disabledGlobalSearchButton = false;
    },
    dataHouseNumberAdditionsErr(state, err) {
        state.disabledGlobalSearchButton = true;
        state.loadingHouseNumberAdditions = false;
        state.errorHouseNumberAdditions = err;
    },

    cleanGlobalSearch(state) {
        state.data = null;
    },
};
