import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    team: {
        id: null,
        name: null,
        description: '',
    },
    teams: [],
    loadingTeams: false,
    loadingTeam: true,
    loadingCreateTeam: false,
    loadingPatchTeam: false,
    loadingDeleteTeam: false,
    errorTeams: null,
    errorTeam: null,
    errorCreateTeam: null,
    errorPatchTeam: null,
    errorDeleteTeam: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get team
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getTeam({commit}, id) {
        try {
            await commit('teamDataBegin');
            const response = await DataService.get(`/teams/${id}`);
            await commit('teamDataSuccess', response.data);
        } catch (err) {
            await commit('teamDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create team
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createTeam({commit, dispatch}, data) {
        try {
            await commit('teamDataCreateBegin');
            const response = await DataService.post(`/teams`, data.value);
            await commit('teamDataCreateSuccess', response.data);
            await dispatch('getTeams');
            data.close();
        } catch (err) {
            await commit('teamDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update team
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchTeam({commit, dispatch}, data) {
        try {
            await commit('teamDataPatchBegin');
            await DataService.patch(`/teams/${data.id}`, data);
            await commit('teamDataPatchSuccess');
            addNotificationSuccess('De wijzigingen zijn doorgevoerd.');
            await dispatch('getTeams');
        } catch (err) {
            await commit('teamDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete team (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteTeam({commit, dispatch}, id) {
        try {
            await commit('teamDataDeleteBegin');
            await DataService.delete(`/teams/${id}`);
            await commit('teamDataDeleteSuccess');
            await dispatch('getTeams');
            addNotificationSuccess('De team is met succes verwijderd.');
        } catch (err) {
            await commit('teamDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get teams (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getTeams({commit}, data) {
        try {
            await commit('teamsDataBegin');
            const response = await DataService.get('/teams', data);
            await commit('teamsDataSuccess', response.data);
        } catch (err) {
            await commit('teamsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageTeams({commit}, page) {
        await commit('setCurrentPageTeams', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
