import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    supplier: {
        city: null,
        cocNumber: null,
        country: null,
        createTimestamp: null,
        deletedTimestamp: null,
        description: null,
        displayName: null,
        email: null,
        externalReference: null,
        houseNumber: null,
        houseNumberAddition: null,
        id: null,
        imageFile: null,
        imageFileURL: '',
        modifiedTimestamp: null,
        name: null,
        phoneNumber: null,
        postalCode: null,
        street: null,
        website: null,
    },
    suppliers: [],
    loadingSuppliers: false,
    loadingSupplier: false,
    loadingCreateSupplier: false,
    loadingPatchSupplier: false,
    loadingDeleteSupplier: false,
    errorSuppliers: false,
    errorSupplier: false,
    errorCreateSupplier: false,
    errorPatchSupplier: false,
    errorDeleteSupplier: false,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get supplier
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getSupplier({commit}, id) {
        try {
            await commit('supplierDataBegin');
            const response = await DataService.get(`/suppliers/${id}`);
            await commit('supplierDataSuccess', response.data);
        } catch (err) {
            await commit('supplierDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create supplier
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createSupplier({commit}, data) {
        try {
            await commit('supplierDataCreateBegin');
            await DataService.post(`/suppliers`, data.value);
            await commit('supplierDataCreateSuccess');
            data.close();
        } catch (err) {
            await commit('supplierDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update supplier
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchSupplier({commit}, data) {
        try {
            await commit('supplierDataPatchBegin');
            const response = await DataService.patch(`/suppliers/${data.id}`, data);
            addNotificationSuccess('De leverancier is met succes aangepast.');
            await commit('supplierDataPatchSuccess', response.data);
        } catch (err) {
            await commit('supplierDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete supplier (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteSupplier({commit}, id) {
        try {
            await commit('supplierDataDeleteBegin');
            const response = await DataService.delete(`/suppliers/${id}`);
            await commit('supplierDataDeleteSuccess', response.data);
            addNotificationSuccess('De organisatie is met succes verwijderd.');
        } catch (err) {
            await commit('supplierDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get suppliers (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getSuppliers({commit}, data) {
        try {
            await commit('suppliersDataBegin');
            const response = await DataService.get('/suppliers', data);
            await commit('suppliersDataSuccess', response.data);
        } catch (err) {
            await commit('suppliersDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageSupplier({commit}, page) {
        await commit('setCurrentPageSupplier', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
