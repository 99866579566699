<template>

 <div v-if="isLoading" class="spin">
  <a-spin/>
 </div>
 <ThemeProvider
  v-else
  :theme="{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }"
 >
  <AppWrapper>
   <Suspense>
    <template #default>
     <router-view></router-view>
    </template>
    <template #fallback>
     <div class="spin">
      <a-spin/>
     </div>
    </template>
   </Suspense>
  </AppWrapper>
 </ThemeProvider>
</template>
<script>
import {computed, defineComponent, onMounted} from 'vue'
import {ThemeProvider} from "vue3-styled-components";
import {theme} from "./config/theme/themeVariables";
import {useStore} from "vuex";
import moment from "moment";
import {AppWrapper} from "./view/styled";
import {authBroadcasting, triggerGeneralNotificationSuccess, triggerOrderSignNotification} from "@/utility/utility";
import {ABILITY_TOKEN, useAbility} from '@casl/vue';
import {useRouter} from "vue-router";

export default defineComponent({
 name: "App",
 inject: {
  $ability: {from: ABILITY_TOKEN}
 },
 components: {
  ThemeProvider,
  AppWrapper,
 },
 setup() {
  const {dispatch, state} = useStore();
  const rtl = computed(() => state.themeLayout.rtlData);
  const isLoading = computed(() => state.themeLayout.loading);
  const darkMode = computed(() => state.themeLayout.data);
  const topMenu = computed(() => state.themeLayout.topMenu);
  const router = useRouter();
  const ability = useAbility()
  // const router = useRouter();
  let mountCounter = 1;

  onMounted(() => {
   if (localStorage.getItem('access_token')) {
    dispatch('getProfile');
   }
   if (localStorage.getItem('themeConfig') === undefined || localStorage.getItem('themeConfig') === null || localStorage.getItem('themeConfig').length <= 0) {
    dispatch('fetchThemeConfig')
   } else {
    const parsed = JSON.parse(localStorage.getItem('themeConfig'));
    if (moment() > moment(parsed?.fetchTimestamp).add(1, 'minute')) {
     dispatch('fetchThemeConfig')
    }
    dispatch('setFavicon', parsed)
    dispatch('setTitle', parsed)
   }

   mountCounter = mountCounter + 1
   if (mountCounter <= 2 && localStorage.getItem('access_token')) {
    authBroadcasting()

    window.Echo.private(`me.${JSON.parse(localStorage.getItem('tenant'))}.${JSON.parse(localStorage.getItem('id'))}`)
     .listen(".OrderSigned", (e) => {
      dispatch('orderIsSinged', e.id)
      triggerOrderSignNotification(e)
     })
     .listen(".UserStateChanged", async () => {
      await dispatch('getAcl');
      await dispatch('getProfile');
      await ability.update(JSON.parse(localStorage.getItem('ability')));
      await router.push('/');
      router.go(0);
     })
     .listen(".GeneralNotification", (e) => {
      triggerGeneralNotificationSuccess(e)
     })
   }
  })

  return {
   theme,
   rtl,
   darkMode,
   topMenu,
   isLoading,
   moment,
  };
 },
});
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

*:not(.fas,.fa) {
 font-family: 'Poppins' !important;
 text-shadow: none !important;
}

.ant-btn > span {
 font-size: 90% !important;
}
</style>
