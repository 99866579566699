import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    loadingSends: false,
    errorSend: null,
});

const actions = {
    async sendCommunication({commit}, data) {
        try {
            await commit('sendDataBegin');
            const response = await DataService.post(`/orders/${data.id}/sendcommunication`, data);
            await commit('sendDataSuccess', response.data);
            addNotificationSuccess(data.type === 'OFFERSMS' ? 'SMS is verzonden!':'Email is verzonden!');
        } catch (err) {
            await commit('sendDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
