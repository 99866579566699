export default {
    relationDataBegin(state) {
        state.loadingRelation = true;
        state.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: null,
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };
        state.errorRelation = false;
    },
    relationDataSuccess(state, data) {
        state.relation = data;
        state.loadingRelation = false;
    },
    relationDataErr(state, err) {
        state.loadingRelation = false;
        state.errorRelation = err;
    },
    relationOrdersDataBegin(state) {
        state.loadingRelationOrders = true;
        state.orders = [];
        state.errorRelationOrders = false;
    },
    relationOrdersDataSuccess(state, data) {
        state.orders = data.data;
        state.orderCount = data.data.length;
        state.loadingRelationOrders = false;
    },
    relationOrdersDataErr(state, err) {
        state.loadingRelationOrders = false;
        state.errorRelationOrders = err;
    },
    relationsDataBegin(state) {
        state.loadingRelations = true;
        state.errorRelations = false;
    },
    relationsDataSuccess(state, data) {
        state.relations = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingRelations = false;
    },
    relationsDataErr(state, err) {
        state.errorRelations = err;
        state.loadingRelations = false;
    },
    relationDataCreateBegin(state) {
        state.loadingCreateRelation = true;
        state.errorCreateRelation = false;
    },
    relationDataCreateSuccess(state) {
        state.loadingCreateRelation = false;
    },
    relationDataCreateErr(state, err) {
        state.errorCreateRelation = err;
        state.loadingCreateRelation = false;
    },
    relationDataPatchBegin(state) {
        state.loadingPatchRelation = true;
        state.errorPatchRelation = false;
    },
    relationDataPatchSuccess(state) {
        state.loadingPatchRelation = false;
    },
    relationDataPatchErr(state, err) {
        state.errorPatchRelation = err;
        state.loadingPatchRelation = false;
    },
    relationDataDeleteBegin(state) {
        state.loadingDeleteRelation = true;
        state.errorDeleteRelation = false;
    },
    relationDataDeleteSuccess(state) {
        state.loadingDeleteRelation = false;
    },
    relationDataDeleteErr(state, err) {
        state.errorDeleteRelation = err;
        state.loadingDeleteRelation = false;
    },
    preFillRelationData(state, data) {
        const currentRelation = state.relation;
        const newRelation = {...currentRelation, ...data};
        state.relation = newRelation;
    },
    setCurrentPageRelation(state, page) {
        state.currentPage = page;
    },
    setCurrentPageRelationOrders(state, page) {
        const filters = state.orderFilters;
        filters.currentPage = page;
        filters.page = page;
        state.orderFilters = filters;
    },
};
