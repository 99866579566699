export default {
    calculatePriceBegin(state) {
        state.errorCalculatePrice = false;
        state.loadingCalculatePrice = true;
    },
    calculatePriceSuccess(state, data) {
        state.amount = data;
        state.loadingCalculatePrice = false;
    },
    calculatePriceErr(state, err) {
        state.loadingcalculatePrice = false;
        state.errorCalculatePrice = err;
    },
    patchHydromxQuestionsBegin(state) {
        state.errorPatchHydromxQuestions = false;
        state.loadingPatchHydromxQuestions = true;
    },
    patchHydromxQuestionsSuccess(state) {
        state.loadingPatchHydromxQuestions = false;
    },
    patchHydromxQuestionsErr(state, err) {
        state.loadingPatchHydromxQuestions = false;
        state.errorPatchHydromxQuestions = err;
    },
    fetchHydromxQuestionsBegin(state) {
        state.hydromxQuestions = [];
        state.errorFetchHydromxQuestions = false;
        state.loadingFetchHydromxQuestions = true;
    },
    fetchHydromxQuestionsSuccess(state, data) {
        state.hydromxQuestions = data;
        state.loadingFetchHydromxQuestions = false;
    },
    fetchHydromxQuestionsErr(state, err) {
        state.loadingFetchHydromxQuestions = false;
        state.errorFetchHydromxQuestions = err;
    },
    preFillRelationOnHydromxOrderBegin(state) {
        state.errorPreFillRelationOnOrder = false;
        state.loadingPreFillRelationOnOrder = true;
    },
    preFillRelationOnHydromxOrderSuccess(state) {
        state.loadingPreFillRelationOnOrder = false;
    },
    preFillRelationOnHydromxOrderErr(state, err) {
        state.loadingPreFillRelationOnOrder = false;
        state.errorPreFillRelationOnOrder = err;
    },
    setOrderFlowHydromx(state, data) {
        const order = state.order;
        order.signatureType = data.flow;
        order.type = data.config.type;
        state.order = order;
    },
    addRelationToHydromxOrder(state, data) {
        let order = state.order;
        order.relation = data;
        if (data.id !== undefined) {
            order.relationID = data.id;
        }

        state.order = order;
    },
    addQuestionsHydromxOrder(state, data) {
        console.log(data)
        let order = state.order;
        let flowQuestions = [];
        flowQuestions.push({id: 1, value: data.type});
        flowQuestions.push({id: 2, value: data.usage});
        flowQuestions.push({id: 3, value: data.radiators});
        flowQuestions.push({id: 4, value: data.underFloorHeating});
        order.flowQuestions = flowQuestions;
        state.order = order;
    },
    setSignatureHydromxData(state, data) {
        const order = state.order;
        order.signatureData = data;
        state.order = order;
    },
    resetHydromxOrder(state) {
        state.order = {
            type: 'HYDROMX',
            relation: {
                type: 'CONSUMER',
            },
            relationID: null,
            signatureType: 'ESIGNATURE',
            signatureData: null,
            amount: null,
            flowQuestions: [],
        };
    },
    hydromxOrderCreateBegin(state) {
        state.loadingCreateOrder = true;
        state.errorCreateOrder = false;
    },
    hydromxOrderCreateSuccess(state) {
        state.loadingCreateOrder = false;
    },
    hydromxOrderCreateErr(state, err) {
        state.loadingCreateOrder = false;
        state.errorCreateOrder = err;
    },
};
