import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    export: null,
    exports: [],
    loadingExports: false,
    loadingExport: false,
    loadingCreateExport: false,
    loadingPatchExport: false,
    loadingDeleteExport: false,
    errorExports: null,
    errorExport: null,
    errorCreateExport: null,
    errorPatchExport: null,
    errorDeleteExport: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get exports
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getExport({commit}, id) {
        try {
            await commit('exportDataBegin');
            const response = await DataService.get(`/exports/${id}`);
            await commit('exportDataSuccess', response.data);
        } catch (err) {
            await commit('exportDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create exports
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createExport({commit, dispatch}, data) {
        try {
            await commit('exportDataCreateBegin');
            const response = await DataService.post(`/exports`, data.value);
            await commit('exportDataCreateSuccess', response.data);
            data.close();
            await dispatch('getExports');
        } catch (err) {
            await commit('exportDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update exports
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchExport({commit, dispatch}, data) {
        try {
            await commit('exportDataPatchBegin');
            await DataService.patch(`/exports/${data.id}`, data);
            await commit('exportDataPatchSuccess');
            await dispatch('getExports');
        } catch (err) {
            await commit('exportDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete exports (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteExport({commit, dispatch}, id) {
        try {
            await commit('exportDataDeleteBegin');
            await DataService.delete(`/exports/${id}`);
            await commit('exportDataDeleteSuccess');
            await dispatch('getExports');
            addNotificationSuccess('De exports is met succes verwijderd.');
        } catch (err) {
            await commit('exportDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get exports (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getExports({commit}, data) {
        try {
            await commit('exportsDataBegin');
            const response = await DataService.get('/exports', data);
            await commit('exportsDataSuccess', response.data);
        } catch (err) {
            await commit('exportsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageExport({commit}, page) {
        await commit('setCurrentPageExport', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
