import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    tariffs: [],
    loadingTariffs: false,
    loadingCreateTariff: false,
    loadingPatchTariff: false,
    errorTariffs: null,
    errorCreateTariff: null,
    errorPatchTariff: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Create tariff
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createTariff({commit, dispatch}, data) {
        try {
            await commit('tariffDataCreateBegin');
            await DataService.post(`products/${data.productID}/energytariffs`, data);
            await commit('tariffDataCreateSuccess');
            await dispatch('getTariffs',{id:data.productID, page:state.currentPage, pageSize: state.pageSize});
            addNotificationSuccess('De tarieven zijn aangemaakt.');
        } catch (err) {
            await commit('tariffDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update tariff
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchTariff({commit, dispatch}, data) {
        try {
            await commit('tariffDataPatchBegin');
            await DataService.patch(`products/${data.productID}/energytariffs/${data.id}`, data);
            await commit('tariffDataPatchSuccess');
            await dispatch('getTariffs',{id:data.productID, page:state.currentPage, pageSize: state.pageSize});
        } catch (err) {
            await commit('tariffDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get tariffs (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getTariffs({commit},data) {
        try {
            await commit('tariffsDataBegin');
            const response = await DataService.get(`products/${data.id}/energytariffs`, {page: data.page, pageSize: data.pageSize});
            await commit('tariffsDataSuccess', response.data);
        } catch (err) {
            await commit('tariffsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageTariff({commit}, page) {
        await commit('setCurrentPage', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
