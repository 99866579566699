export default {
    defaultDashboardCountsBegin(state) {
        state.loadingDefaultDashboardCounts = true;
        state.errorDefaultDashboardCounts = false;
    },
    defaultDashboardCountsSuccess(state, data) {
        state.counts = data;
        state.loadingDefaultDashboardCounts = false;
    },
    defaultDashboardCountsErr(state, err) {
        state.loadingDefaultDashboardCounts = false;
        state.errorDefaultDashboardCounts = err;
    },
    defaultDashboardOrdersBegin(state) {
        state.loadingDefaultDashboardOrders = true;
        state.errorDefaultDashboardOrders = false;
    },
    defaultDashboardOrdersSuccess(state, data) {
        state.orders = data;
        state.loadingDefaultDashboardOrders = false;
    },
    defaultDashboardOrdersErr(state, err) {
        state.loadingDefaultDashboardOrders = false;
        state.errorDefaultDashboardOrders = err;
    },
    defaultDashboardProductsBegin(state) {
        state.loadingDefaultDashboardProducts = true;
        state.errorDefaultDashboardProducts = false;
    },
    defaultDashboardProductsSuccess(state, data) {
        state.products = data;
        state.loadingDefaultDashboardRelations = false;
    },
    defaultDashboardProductsErr(state, err) {
        state.loadingDefaultDashboardProducts = false;
        state.errorDefaultDashboardProducts = err;
    },
    defaultDashboardCancelConversionBegin(state) {
        state.loadingDefaultDashboardCancelConversion = true;
        state.errorDefaultDashboardCancelConversion = false;
    },
    defaultDashboardCancelConversionSuccess(state, data) {
        state.cancelConversion = data;
        state.loadingDefaultDashboardCancelConversion = false;
    },
    defaultDashboardCancelConversionErr(state, err) {
        state.loadingDefaultDashboardCancelConversion = false;
        state.errorDefaultDashboardCancelConversion = err;
    },
    defaultDashboardMonthlySaleCountsBegin(state) {
        state.loadingDefaultDashboardMonthlySaleCounts = true;
        state.errorDefaultDashboardMonthlySaleCounts = false;
    },
    defaultDashboardMonthlySaleCountsSuccess(state, data) {
        state.monthlySaleCounts = data;
        state.loadingDefaultDashboardMonthlySaleCounts = false;
    },
    defaultDashboardMonthlySaleCountsErr(state, err) {
        state.loadingDefaultDashboardMonthlySaleCounts = false;
        state.errorDefaultDashboardMonthlySaleCounts = err;
    },
    defaultDashboardChartBegin(state) {
        state.loadingDefaultChart = true;
        state.errorDefaultDashboardChart = false;
    },
    defaultDashboardChartSuccess(state, data) {
        state.chart = data;
        state.loadingDefaultChart = false;
    },
    defaultDashboardChartErr(state, err) {
        state.loadingDefaultChart = false;
        state.errorDefaultDashboardChart = err;
    },
    defaultDashboardSignConversionBegin(state) {
        state.loadingDefaultDashboardSignConversion = true;
        state.errorDefaultDashboardSignConversion = false;
    },
    defaultDashboardSignConversionSuccess(state, data) {
        state.signConversion = data;
        state.loadingDefaultDashboardSignConversion = false;
    },
    defaultDashboardSignConversionErr(state, err) {
        state.loadingDefaultDashboardSignConversion = false;
        state.errorDefaultDashboardSignConversion = err;
    },

    defaultDashboardActivitiesBegin(state) {
        state.loadingDefaultActivities = true;
        state.errorDefaultDashboardActivities = false;
    },
    defaultDashboardActivitiesSuccess(state, data) {
        state.activities = data;
        state.loadingDefaultActivities = false;
    },
    defaultDashboardActivitiesErr(state, err) {
        state.loadingDefaultActivities = false;
        state.errorDefaultDashboardActivities = err;
    },
};
