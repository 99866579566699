import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    solarSubProduct: {
        active: false,
        description: '',
        id: null,
        imageFile: null,
        imageFileURL: null,
        maxWattPeak: null,
        name: null,
        price: null,
        productID: null,
        roofType: null,
        type: null,
        wattPeak: null,
    },
    subProductsForOrder: [],
    solarSubProducts: [],
    loadingSolarSubProducts: false,
    loadingSolarSubProduct: false,
    loadingCreateSolarSubProduct: false,
    loadingPatchSolarSubProduct: false,
    loadingDeleteSolarSubProduct: false,
    loadingSolarSubProductsForOrder: false,
    errorSolarSubProducts: false,
    errorSolarSubProduct: false,
    errorCreateSolarSubProduct: false,
    errorPatchSolarSubProduct: false,
    errorDeleteSolarSubProduct: false,
    errorSolarSubProductsForOrder: false,
    count: 0,
    currentPage: 1,
    pageSize: 20,
});

const actions = {
    /**
     *
     * Get solarSubProduct
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getSolarSubProduct({commit}, data) {
        try {
            await commit('solarSubProductDataBegin');
            const response = await DataService.get(`/products/${data.productID}/solarsubproducts/${data.id}`);
            await commit('solarSubProductDataSuccess', response.data);
        } catch (err) {
            await commit('solarSubProductDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create solarSubProduct
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createSolarSubProduct({commit, dispatch}, data) {
        try {
            await commit('solarSubProductDataCreateBegin');
            const response = await DataService.post(`/products/${data.productID}/solarsubproducts`, data);
            await commit('solarSubProductDataCreateSuccess', response.data);
            await dispatch('getSolarSubProducts', data);
            data.close();
        } catch (err) {
            await commit('solarSubProductDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create solarSubProduct
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createSolarSubProductDrawer({commit, dispatch}, data) {
        try {
            await commit('solarSubProductDataCreateBegin');
            const response = await DataService.post(`/products/${data.value.productID}/solarsubproducts`, data.value);
            await commit('solarSubProductDataCreateSuccess', response.data);
            await dispatch('getSolarSubProducts', {});
            data.close();
        } catch (err) {
            await commit('solarSubProductDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update solarSubProduct
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchSolarSubProduct({commit, dispatch}, data) {
        try {
            await commit('solarSubProductDataPatchBegin');
            await DataService.patch(`/products/${data.productID}/solarsubproducts/${data.id}`, data);
            await commit('solarSubProductDataPatchSuccess');
            await dispatch('getSolarSubProducts', data);
            addNotificationSuccess('De wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('solarSubProductDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update solarSubProduct
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchSolarSubProductDrawer({commit, dispatch}, data) {
        try {
            await commit('solarSubProductDataPatchBegin');
            await DataService.patch(`/products/${data.value.productID}/solarsubproducts/${data.value.id}`, data.value);
            await commit('solarSubProductDataPatchSuccess');
            await dispatch('getSolarSubProducts', data.value);
            data.close();
            addNotificationSuccess('De wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('solarSubProductDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete solarSubProduct (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteSolarSubProduct({commit, dispatch}, data) {
        try {
            await commit('solarSubProductDataDeleteBegin');
            await DataService.delete(`/products/${data.productID}/solarsubproducts/${data.id}`);
            await commit('solarSubProductDataDeleteSuccess');
            await dispatch('getSolarSubProducts', {productID: data.productID, page: 1});
            addNotificationSuccess('Product is met succes verwijderd.');
        } catch (err) {
            await commit('solarSubProductDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get solarSubProducts (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getSolarSubProducts({commit}, data) {
        try {
            await commit('solarSubProductsDataBegin');
            const response = await DataService.get(`/products/${data.productID}/solarsubproducts`, data);
            await commit('solarSubProductsDataSuccess', response.data);
        } catch (err) {
            await commit('solarSubProductsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async getSolarSubProductsForOrder({commit}, orderID) {
        try {
            await commit('solarSubProductsForOrderDataBegin');
            const response = await DataService.get(`/orders/${orderID}/solarsimplesubproducts`);
            await commit('solarSubProductsForOrderDataSuccess', response.data);
        } catch (err) {
            await commit('solarSubProductsForOrderDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageSolarSubProduct({commit}, page) {
        await commit('setCurrentPageSolarSubProduct', page);
    },
    async resetSolarSubProduct({commit}) {
        commit('resetSolarSubProduct');
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
