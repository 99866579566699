export default {
    fetchShopProductsBegin(state) {
        state.errorFetchShopProducts = false;
        state.loadingFetchShopProducts = true;
    },
    fetchShopProductsSuccess(state, data) {
        state.products = data.data;
        state.productCount = data.meta.total;
        state.currentPageProduct = data.meta.current_page;
        state.fromProduct = data.meta.from;
        state.toProduct = data.meta.to;
        state.loadingFetchShopProducts = false;
    },
    fetchShopProductsErr(state, err) {
        state.loadingFetchShopProducts = false;
        state.errorFetchShopProducts = err;
    },
    fetchShopSubProductsBegin(state) {
        state.errorFetchShopSubProducts = false;
        state.loadingFetchShopSubProducts = true;
    },
    fetchShopSubProductsSuccess(state, data) {
        const productsSubProducts = JSON.parse(JSON.stringify(state.productsSubProducts));
        productsSubProducts.data = data.data;
        productsSubProducts.total = data.total;
        productsSubProducts.currentPage = data.current_page;
        productsSubProducts.from = data.from;
        productsSubProducts.to = data.to;
        productsSubProducts.key = Math.random();
        state.productsSubProducts = productsSubProducts;
        state.loadingFetchShopSubProducts = false;
    },
    fetchShopSubProductsErr(state, err) {
        state.loadingFetchShopSubProducts = false;
        state.errorFetchShopSubProducts = err;
    },
    orderSubProductsDataBegin(state) {
        state.errorOrderSubProducts = false;
        state.loadingOrderSubProducts = true;
    },
    orderSubProductsDataSuccess(state, data) {
        const orderSubProducts = JSON.parse(JSON.stringify(state.orderSubProducts));
        let amount = 0;
        orderSubProducts.data = data.data;
        for (let i = 0; i < orderSubProducts.data.length; i++) {
            // eslint-disable-next-line no-unused-vars
            amount = amount + (orderSubProducts.data[i].totalAmount * orderSubProducts.data[i].quantity);
        }
        orderSubProducts.total = amount
        orderSubProducts.data = data.data;
        orderSubProducts.key = Math.random();
        state.orderSubProducts = orderSubProducts;
        state.loadingOrderSubProducts = false;
    },
    orderSubProductsDataErr(state, err) {
        state.loadingOrderSubProducts = false;
        state.errorOrderSubProducts = err;
    },
    getShopSubProductDataBegin(state) {
        state.errorShopSubProduct = false;
        state.loadingShopSubProduct = true;
    },
    getShopSubProductDataSuccess(state, data) {
        let subProduct = JSON.parse(JSON.stringify(state.subProduct));
        subProduct.id = data.id;
        subProduct.name = data.name;
        subProduct.active = data.active;
        subProduct.hasAutoSync = data.hasAutoSync;
        subProduct.imageFileURL = data.imageFileURL;
        subProduct.description = data.description ? data.description : '';
        subProduct.externalReference = data.externalReference;
        subProduct.subCategoryID = data.subCategoryID;
        subProduct.amount = data.amount;
        state.subProduct = subProduct;
        state.loadingShopSubProduct = false;
    },
    getShopSubProductDataErr(state, err) {
        state.loadingShopSubProduct = false;
        state.errorShopSubProduct = err;
    },
    getShopSubCategoriesDataBegin(state) {
        state.errorShopSubCategories = false;
        state.loadingShopSubCategories = true;
    },
    getShopSubCategoriesDataSuccess(state, data) {
        state.productSubCategories = data;
        state.loadingShopSubCategories = false;
    },
    getShopSubCategoriesDataErr(state, err) {
        state.loadingShopSubCategories = false;
        state.errorShopSubCategories = err;
    },
    fetchShopCategoriesBegin(state) {
        state.errorFetchShopCategories = false;
        state.loadingFetchShopCategories = true;
    },
    fetchShopCategoriesSuccess(state, data) {
        state.categories = data.data;
        state.loadingFetchShopCategories = false;
    },
    fetchShopCategoriesErr(state, err) {
        state.loadingFetchShopCategories = false;
        state.errorFetchShopCategories = err;
    },
    fetchShopFlowQuestionsBegin(state) {
        state.errorFetchShopFlowQuestions = false;
        state.loadingFetchShopFlowQuestions = true;
    },
    fetchShopFlowQuestionsSuccess(state, flowQuestions) {
        const order = JSON.parse(JSON.stringify(state.order));
        order.flowQuestions = flowQuestions.data;
        order.key = Math.random();
        state.order = order;
        state.loadingFetchShopFlowQuestions = false;
    },
    fetchShopFlowQuestionsErr(state, err) {
        state.loadingFetchShopFlowQuestions = false;
        state.errorFetchShopFlowQuestions = err;
    },
    preFillRelationOnShopOrderBegin(state) {
        state.errorPreFillRelationOnShopOrder = false;
        state.loadingPreFillRelationOnShopOrder = true;
    },
    preFillRelationOnShopOrderSuccess(state) {
        state.loadingPreFillRelationOnShopOrder = false;
    },
    preFillRelationOnShopOrderErr(state, err) {
        state.loadingPreFillRelationOnShopOrder = false;
        state.errorPreFillRelationOnShopOrder = err;
    },
    setLeadAreaTimeBlockID(state, data) {
        const order = JSON.parse(JSON.stringify(state.order));
        order['leadAreaTimeBlockID'] = data;
        order.key = Math.random();
        state.order = order;
    },
    setOrderSubTypeShop(state, data) {
        const order = JSON.parse(JSON.stringify(state.order));
        order.subType = data;
        order.key = Math.random();
        state.order = order;
    },
    setOrderFlowShop(state, data) {
        const order = JSON.parse(JSON.stringify(state.order));
        order.signatureType = data.flow;
        order.productID = data.config.productID;
        order.type = data.config.type;
        order.subType = data.config.subType;
        order.key = Math.random();
        state.order = order;
    },
    addRelationToShopOrder(state, data) {
        let order = state.order;

        if (data.type === 'CONSUMER') {
            data.companyName = null;
            data.companyCocNumber = null;
        }

        order.relation = data;
        if (data.id !== undefined) {
            order.relationID = data.id;
        }

        state.order = order;
    },
    setSignatureShopData(state, data) {
        const order = state.order;
        order.signatureData = data;
        state.order = order;
    },
    resetShopOrder(state) {
        const order = JSON.parse(JSON.stringify(state.order));
        const subproducts = JSON.parse(JSON.stringify(state.order));
        let productSubCategories = JSON.parse(JSON.stringify(state.productSubCategories));
        order.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };

        order.relationID = null;
        order.type = 'SHOP';
        order.signatureType = 'ESIGNATURE';
        order.signatureData = null;
        order.subProducts = [];
        state.order = order;

        subproducts.data = [];
        subproducts.key = null;
        subproducts.total = 0;
        state.subproducts = subproducts;

        productSubCategories = [];
        state.productSubCategories = productSubCategories;
    },
    resetRelationShopOrder(state) {
        const order = JSON.parse(JSON.stringify(state.order));
        order.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };

        order.relationID = null;
        order.key = Math.random();
        state.order = order;
    },
    orderDataShopCreateBegin(state) {
        state.errorCreateShopOrder = false;
        state.loadingCreateShopOrder = true;
    },
    orderDataShopCreateSuccess(state) {
        state.loadingCreateShopOrder = false;
    },
    orderDataShopCreateErr(state, err) {
        state.loadingCreateShopOrder = false;
        state.errorCreateShopOrder = err;
    },
    setProductFilters(state, data) {
        let productFilters = JSON.parse(JSON.stringify(state.productFilters));
        if (data.type === 'category') {
            productFilters.categoryID = data.categoryID
        }
        if (data.type === 'priceRange') {
            productFilters.priceFrom = data.priceFrom
            productFilters.priceTill = data.priceTill
        }
        if (data.type === 'search') {
            productFilters.q = data.q
        }

        state.productFilters = productFilters;
    },
    calculateSubProductTotal(state) {
        let subProducts = JSON.parse(JSON.stringify(state.subProducts));
        let amount = 0;
        for (let i = 0; i < subProducts.data.length; i++) {
            // eslint-disable-next-line no-unused-vars
            amount = amount + (subProducts.data[i].totalAmount * subProducts.data[i].quantity);
        }
        subProducts.total = amount
        state.subProducts = subProducts;
    },
    setProductQuantity(state, data) {
        let products = JSON.parse(JSON.stringify(state.products));
        if (data.type === 'min') {
            products[data.index].quantity = (parseInt(products[data.index].quantity) - 1)
        }
        if (data.type === 'plus') {
            products[data.index].quantity = (parseInt(products[data.index].quantity) + 1)
        }
        state.products = products;
    },
    setSubProductQuantity(state, data) {
        let subProducts = JSON.parse(JSON.stringify(state.subProducts));
        if (data.type === 'min') {
            subProducts.data[data.index].quantity = (parseInt(subProducts.data[data.index].quantity) - 1)
        }
        if (data.type === 'plus') {
            subProducts.data[data.index].quantity = (parseInt(subProducts.data[data.index].quantity) + 1)
        }
        subProducts.key = Math.random();
        state.subProducts = subProducts;
    },
    removeSubProduct(state, index) {
        let subProducts = JSON.parse(JSON.stringify(state.subProducts));
        subProducts.data.splice(index, 1)
        subProducts.key = Math.random();
        state.subProducts = subProducts;
    },
    handleBatteryDiscount(state, data) {
        let o = JSON.parse(JSON.stringify(state.order));
        o.discountPercentage = data;
        state.order = o;
    },
    addProductToCard(state, data) {
        let products = JSON.parse(JSON.stringify(state.products));
        let order = JSON.parse(JSON.stringify(state.order));
        let subProducts = JSON.parse(JSON.stringify(state.subProducts));
        order.subProducts = [];
        subProducts.data = [];
        subProducts.key = Math.random();

        const i = order.subProducts.findIndex(e => e.id === data.value.id);
        const a = subProducts.data.findIndex(e => e.id === data.value.id);
        if (i > -1) {
            order.subProducts[i].quantity = order.subProducts[i].quantity + data.value.quantity
        } else {
            order.subProducts.push(data.value);
        }

        if (a > -1) {
            subProducts.data[a].quantity = subProducts.data[a].quantity + data.value.quantity
            subProducts.key = Math.random();
        } else {
            subProducts.data.push(data.value);
            subProducts.key = Math.random();
        }

        products[data.index].quantity = 1
        order.key = Math.random();
        state.order = order;
        state.subProducts = subProducts;
        state.products = products;
    },
    addSubProductOnOrder(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.subProducts = state.subProducts.data;
        order.key = Math.random();
        state.order = order
    },
    setShopSubProductsCurrentPage(state, page) {
        let productsSubProducts = JSON.parse(JSON.stringify(state.productsSubProducts));
        productsSubProducts.currentPage = page;
        productsSubProducts.key = Math.random();
        state.productsSubProducts = productsSubProducts;
    },
    resetShopSubProduct(state) {
        let subProduct = JSON.parse(JSON.stringify(state.subProduct));
        subProduct.id = null;
        subProduct.name = null;
        subProduct.active = null;
        subProduct.hasAutoSync = null;
        subProduct.imageFileURL = null;
        subProduct.description = '';
        subProduct.externalReference = null;
        subProduct.subCategoryID = null;
        state.subProduct = subProduct;
    },
    shopSubProductDataPatchBegin(state) {
        state.loadingPatchShopSubProduct = true;
        state.errorPatchShopSubProduct = false;
    },
    shopSubProductDataPatchSuccess(state) {
        state.loadingPatchShopSubProduct = false;
    },
    shopSubProductDataPatchErr(state, err) {
        state.errorPatchShopSubProduct = err;
        state.loadingPatchShopSubProduct = false;
    },
    shopSubProductDataCreateBegin(state) {
        state.loadingCreateShopSubProduct = true;
        state.errorCreateShopSubProduct = false;
    },
    shopSubProductDataCreateSuccess(state) {
        state.loadingCreateShopSubProduct = false;
    },
    shopSubProductDataCreateErr(state, err) {
        state.errorCreateShopSubProduct = err;
        state.loadingCreateShopSubProduct = false;
    },
    syncShopProductsDataBegin(state) {
        state.loadingSyncShopProducts = true;
        state.errorSyncShopProducts = false;
    },
    syncShopProductsDataErr(state, err) {
        state.errorSyncShopProducts = err;
        state.loadingSyncShopProducts = false;
    },
};
