export default [
    {
        path: '/log/audit',
        name: 'auditlog',
        component: () => import(/* webpackChunkName: "auditlog" */ '@/view/trail/TrailList.vue'),
        meta: {
            action: 'index',
            resource: 'auditlog',
        },
    },
]
