import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";
// import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    order: {
        minimisSupport: null,
        type: 'SVM',
        relation: {type: 'BUSINESS'},
        relationID: null,
        signatureType: 'ESIGNATURE',
        signatureData: null,
        svmQuestions: {
            energyTariff: 0.44,
            gasTariff: 1.98,
            oldLightingCount: null,
            newLightingCount: null,
            hasSolar: false,
            solarAvailable: false,
            roofSurface: null,
            hasSmartThermostat: false,
            glassSurface: null,
            currentGlassType: null,
            usages: [
                {
                    id: 'LIGHTING',
                    type: 'ENERGY',
                    group: 'LICHT',
                    description: 'Totaal aantal lampen',
                    usage: 0,
                    amount: 0,
                    amountPerUnit: 180,
                    unit: 'stuk(s)',
                    default: true,
                },
            ],
        },
    },
    svmQuestions: [],
    errorPreFillRelationOnOrder: false,
    loadingPreFillRelationOnOrder: false,
    loadingCreateOrder: false,
    errorCreateOrder: false,
    errorFetchSVMQuestions: false,
    loadingFetchSVMQuestions: false,
    errorPatchSVMQuestions: false,
    loadingPatchSVMQuestions: false,
});

const actions = {
    async addSVMComponent({commit}, index) {
        await commit('addSVMComponent', index);
    },
    async addSVMOnOrderComponent({commit}, index) {
        await commit('addSVMOnOrderComponent', index);
    },
    async removeSVMOnOrderComponent({commit}, flow) {
        await commit('removeSVMOnOrderComponent', flow);
    },
    async removeSVMComponent({commit}, flow) {
        await commit('removeSVMComponent', flow);
    },
    async setOrderFlowSVM({commit}, flow) {
        await commit('setOrderFlowSVM', flow);
    },
    async setSignatureSVMData({commit}, flow) {
        await commit('setSignatureSVMData', flow);
    },
    async addRelationToSVMOrder({commit}, data) {
        await commit('addRelationToSVMOrder', data.value);
        await data.stepRoute();
    },
    async preFillRelationOnSVMOrder({commit}, data) {
        try {
            await commit('preFillRelationOnSVMOrderBegin');
            await commit('addRelationToSVMOrder', data.value);
            await commit('preFillRelationData', data.value)
            await commit('preFillRelationOnSVMOrderSuccess');
            await data.stepRoute();
        } catch (err) {
            await commit('preFillRelationOnSVMOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createSVMOrder({commit, dispatch, state}, data) {
        try {
            await commit('svmOrderCreateBegin');
            await DataService.post(`/orders`, state.order);
            await commit('svmOrderCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetSVMOrder');
        } catch (err) {
            await commit('svmOrderCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async resetSVMOrder({commit}) {
        await commit('resetSVMOrder');
    },
    async patchSVMQuestions({state, commit}, id) {
        try {
            await commit('patchSVMQuestionsBegin');
            await DataService.patch(`/orders/${id}/svmquestions`, state.svmQuestions);
            await commit('patchSVMQuestionsSuccess');
            await addNotificationSuccess('Succesvol geupdate!');
        } catch (err) {
            await commit('patchSVMQuestionsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async fetchSVMQuestions({commit}, id) {
        try {
            await commit('fetchSVMQuestionsBegin');
            const response = await DataService.get(`/orders/${id}/svmquestions`);
            await commit('fetchSVMQuestionsSuccess', response.data);
        } catch (err) {
            await commit('fetchSVMQuestionsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
