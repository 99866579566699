import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

// const addNotificationSuccess = (err) => {
//     notification.success({
//         message: err,
//     });
// };

const state = () => ({
    order: {
        type: 'SOLARSIMPlE',
        relation: {type: 'CONSUMER'},
        relationID: null,
        signatureType: 'ESIGNATURE',
        signatureData: null,
        flowQuestions: [],
        panelCount: null,
        usageElectricity: null,
    },
    products: [],
    solarSimpleQuestions: [],
    loadingCreateOrder: false,
    errorCreateOrder: false,
    errorFetchSolarSimpleQuestions: false,
    loadingFetchSolarSimpleQuestions: false,
    errorPatchSolarSimpleQuestions: false,
    loadingPatchSolarSimpleQuestions: false,
    errorFetchSolarSimpleProducts: false,
    loadingFetchSolarSimpleProducts: true,
    errorPreFillRelationOnSolarSimpleOrder: false,
    loadingPreFillRelationOnSolarSimpleOrder: false,
});

const actions = {
    async setOrderFlowSolarSimple({commit}, flow) {
        await commit('setOrderFlowSolarSimple', flow);
    },
    async setSignatureSolarSimpleData({commit}, flow) {
        await commit('setSignatureSolarSimpleData', flow);
    },
    async addRelationToSolarSimpleOrder({commit}, data) {
        await commit('addRelationToSolarSimpleOrder', data.value);
        await data.stepRoute();
    },
    async preFillRelationOnSolarSimpleOrder({commit}, data) {
        try {
            await commit('preFillRelationOnSolarSimpleOrderBegin');
            await commit('addRelationToSolarSimpleOrder', data.value);
            await commit('preFillRelationData', data.value)
            await commit('preFillRelationOnSolarSimpleOrderSuccess');
            await data.stepRoute();
        } catch (err) {
            await commit('preFillRelationOnSolarSimpleOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createSolarSimpleOrder({commit, dispatch, state}, data) {
        try {
            await commit('solarSimpleOrderCreateBegin');
            await DataService.post(`/orders`, state.order);
            await commit('solarSimpleOrderCreateSuccess');
            await dispatch('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetSolarSimpleOrder');
        } catch (err) {
            await commit('solarSimpleOrderCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async resetSolarSimpleOrder({commit}) {
        await commit('resetSolarSimpleOrder');
    },
    async fetchSolarProducts({commit}, panelCount, options) {
        try {
            await commit('fetchSolarSimpleProductsBegin');
            const response = await DataService.post(`/offerflow/solarsimple/products`, panelCount, options);
            await commit('fetchSolarSimpleProductsSuccess', response.data);
        } catch (err) {
            await commit('fetchSolarSimpleProductsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setProductOnSolarSimpleOrder({commit}, data) {
        await commit('setProductOnSolarSimpleOrder', data);
        await data.stepRoute();
    },
    setPanelCountOnSolarSimpleOrder({commit}, data) {
        commit('setPanelCountOnSolarSimpleOrder', data);
    },
    setElectricityUsageOnSolarSimpleOrder({commit}, data) {
        commit('setElectricityUsageOnSolarSimpleOrder', data);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
