import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    question: {
        id: null,
        type: null,
        name: null,
        displayName: null,
        inputType: null,
        inputValues: [],
        inpuDefaultValue: null,
    },
    questions: [],
    questionsOrder: [],
    loadingQuestions: false,
    loadingQuestion: true,
    loadingCreateQuestion: false,
    loadingPatchQuestion: false,
    loadingDeleteQuestion: false,
    errorQuestions: null,
    errorQuestion: null,
    errorCreateQuestion: null,
    errorPatchQuestion: null,
    errorDeleteQuestion: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get question
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getQuestion({commit}, id) {
        try {
            await commit('questionDataBegin');
            const response = await DataService.get(`/flowquestions/${id}`);
            await commit('questionDataSuccess', response.data);
        } catch (err) {
            await commit('questionDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create question
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createQuestion({commit, dispatch}, data) {
        try {
            await commit('questionDataCreateBegin');
            const response = await DataService.post(`/flowquestions`, data.value);
            await commit('questionDataCreateSuccess', response.data);
            await dispatch('getQuestions');
            data.close();
        } catch (err) {
            await commit('questionDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update question
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchQuestion({commit, dispatch}, data) {
        try {
            await commit('questionDataPatchBegin');
            await DataService.patch(`/flowquestions/${data.id}`, data);
            await commit('questionDataPatchSuccess');
            addNotificationSuccess('De productvraag is met succes aangepast.');
            await dispatch('getQuestions');
        } catch (err) {
            await commit('questionDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete question (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteQuestion({commit, dispatch}, id) {
        try {
            await commit('questionDataDeleteBegin');
            await DataService.delete(`/flowquestions/${id}`);
            await commit('questionDataDeleteSuccess');
            await dispatch('getQuestions');
            addNotificationSuccess('De question is met succes verwijderd.');
        } catch (err) {
            await commit('questionDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get questions (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getQuestions({commit}, data) {
        try {
            await commit('questionsDataBegin');
            const response = await DataService.get('/flowquestions', data);
            await commit('questionsDataSuccess', response.data);
        } catch (err) {
            await commit('questionsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get questions (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getQuestionsForOrder({commit}, data) {
        try {
            await commit('questionsDataBegin');
            const response = await DataService.get(`/orders/${data.id}/flowquestions`, data);
            await commit('questionsDataSuccess', response.data);
        } catch (err) {
            await commit('questionsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageQuestions({commit}, page) {
        await commit('setCurrentPageQuestions', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
