export default {
    tariffsDataBegin(state) {
        state.loadingTariffs = true;
    },
    tariffsDataSuccess(state, data) {
        state.tariffs = data.data;
        state.count = data.total;
        state.currentPage = data['current_page'];
        state.loadingTariffs = false;
    },
    tariffsDataErr(state, err) {
        state.loadingTariffs = false;
        state.errorTariffs = err;
    },
    tariffDataCreateBegin(state) {
        state.errorCreateTariff = false;
        state.loadingCreateTariff = true;
    },
    tariffDataCreateSuccess(state) {
        state.loadingCreateTariff = false;
    },
    tariffDataCreateErr(state, err) {
        state.loadingCreateTariff = false;
        state.errorCreateTariff = err;
    },
    tariffDataPatchBegin(state) {
        state.loadingPatchTariff = true;
    },
    tariffDataPatchSuccess(state) {
        state.loadingPatchTariff = false;
    },
    tariffDataPatchErr(state, err) {
        state.loadingPatchTariff = false;
        state.errorPatchTariff = err;
    },
    setCurrentPageTariff(state, page) {
        state.currentPage = page;
    },
};
