import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    productCommunicationsFilters: null,
    userOrganization: [],
    orderOrganization: [],
    orderStatus:[],
    orderTeams: [],
    roleFilters: [],
    productFilters: [],
    userFilters: [],
    loadingProductCommunicationsFilters: false,
    errorProductCommunicationsFilters: null,
    loadingUserOrganizationFilters: false,
    errorUserOrganizationFilters: false,
    loadingOrderOrganizationFilters: false,
    errorOrderOrganizationFilters: null,
    loadingOrderStatusFilters: false,
    errorOrderStatusFilters: false,
    loadingOrderTeamsFilters: false,
    errorOrderTeamsFilters: false,
    loadingOrderRolesFilters: false,
    errorOrderRolesFilters: false,
    loadingProductFilters: false,
    errorProductFilters: false,
    loadingUserFilters: false,
    errorUserFilters: false,
});

const actions = {
    /**
     *
     * Get team
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getProductCommunicationsFilters({commit}) {
        try {
            await commit('productCommunicationsFiltersBegin');
            const response = await DataService.get('/productcommunicationtemplates/filters');
            await commit('productCommunicationsFiltersSuccess', response.data);
        } catch (err) {
            await commit('productCommunicationsFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
    async getUserOrganizationFilters({commit}) {
        try {
            await commit('userOrganizationFiltersBegin');
            const response = await DataService.get('/filters/users/organizations');
            await commit('userOrganizationFiltersSuccess', response.data);
        } catch (err) {
            await commit('userOrganizationFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderOrganizationFilters({commit}) {
        try {
            await commit('orderOrganizationFiltersBegin');
            const response = await DataService.get('/filters/orders/organizations');
            await commit('orderOrganizationFiltersSuccess', response.data);
        } catch (err) {
            await commit('orderOrganizationFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
    async getUserFilters({commit}, data) {
        try {
            await commit('userFiltersBegin');
            const response = await DataService.get('/filters/users', data);
            await commit('userFiltersSuccess', response.data);
        } catch (err) {
            await commit('userFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderProductFilters({commit}) {
        try {
            await commit('orderProductFiltersBegin');
            const response = await DataService.get('/filters/products');
            await commit('orderProductFiltersSuccess', response.data);
        } catch (err) {
            await commit('orderProductFiltersErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderStatusFilters({commit}) {
        try {
            await commit('orderStatusFiltersBegin');
            const response = await DataService.get('/filters/statuses');
            await commit('orderStatusFiltersSuccess', response.data);
        } catch (err) {
            await commit('orderStatusFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderTeamFilters({commit}) {
        try {
            await commit('orderTeamFiltersBegin');
            const response = await DataService.get('/filters/teams');
            await commit('orderTeamFiltersSuccess', response.data);
        } catch (err) {
            await commit('orderTeamFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
    async getRolesFilters({commit}) {
        try {
            await commit('orderRolesFiltersBegin');
            const response = await DataService.get('/filters/roles');
            await commit('orderRolesFiltersSuccess', response.data);
        } catch (err) {
            await commit('orderRolesFiltersErr', err);
            // addNotificationError(err?.response?.data?.message);
        }
    },
};


export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
