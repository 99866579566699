import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    script: {
        id: null,
        name: null,
        description: '',
    },
    scripts: [],
    loadingScripts: false,
    loadingScript: false,
    loadingCreateScript: false,
    loadingPatchScript: false,
    loadingDeleteScript: false,
    errorScripts: false,
    errorScript: false,
    errorCreateScript: false,
    errorPatchScript: false,
    errorDeleteScript: false,
    count: 0,
    currentPage: 1,
    pageSize: 100,
});

const actions = {
    /**
     *
     * Get script
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getScript({commit}, id) {
        try {
            await commit('scriptDataBegin');
            const response = await DataService.get(`/scripts/${id}`);
            await commit('scriptDataSuccess', response.data);
        } catch (err) {
            await commit('scriptDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create script
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createScript({commit, dispatch}, data) {
        try {
            await commit('scriptDataCreateBegin');
            const response = await DataService.post(`/scripts`, data.value);
            await commit('scriptDataCreateSuccess', response.data);
            await dispatch('getScripts');
            data.close();
        } catch (err) {
            await commit('scriptDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update script
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchScript({commit}, data) {
        try {
            await commit('scriptDataPatchBegin');
            await DataService.patch(`/scripts/${data.id}`, data);
            await commit('scriptDataPatchSuccess');
            addNotificationSuccess('De script is met succes aangepast.');
        } catch (err) {
            await commit('scriptDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete script (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteScript({commit, dispatch}, id) {
        try {
            await commit('scriptDataDeleteBegin');
            await DataService.delete(`/scripts/${id}`);
            await commit('scriptDataDeleteSuccess');
            await dispatch('getScripts');
            addNotificationSuccess('De script is met succes verwijderd.');
        } catch (err) {
            await commit('scriptDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get scripts (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getScripts({commit}, data) {
        try {
            await commit('scriptsDataBegin');
            const response = await DataService.get('/scripts', data);
            await commit('scriptsDataSuccess', response.data);
        } catch (err) {
            await commit('scriptsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageScript({commit}, page) {
        await commit('setCurrentPageScript', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
