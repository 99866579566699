export default {
    organizationDataBegin(state) {
        state.loadingOrganization = true;
        state.errorOrganization = false;
    },
    organizationDataSuccess(state, data) {
        state.organization = data;
        state.loadingOrganization = false;
    },
    organizationDataErr(state, err) {
        state.loadingOrganization = false;
        state.errorOrganization = err;
    },
    organizationsDataBegin(state) {
        state.loadingOrganizations = true;
        state.errorOrganizations = false;
    },
    organizationsDataSuccess(state, data) {
        state.organizations = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingOrganizations = false;
    },
    organizationsDataErr(state, err) {
        state.errorOrganizations = err;
        state.loadingOrganizations = false;
    },
    organizationDataCreateBegin(state) {
        state.loadingCreateOrganization = true;
        state.errorCreateOrganization = false;
    },
    organizationDataCreateSuccess(state) {
        state.loadingCreateOrganization = false;
    },
    organizationDataCreateErr(state, err) {
        state.errorCreateOrganization = err;
        state.loadingCreateOrganization = false;
    },
    organizationDataPatchBegin(state) {
        state.loadingPatchOrganization = true;
        state.errorPatchOrganization = false;
    },
    organizationDataPatchSuccess(state) {
        state.loadingPatchOrganization = false;
    },
    organizationDataPatchErr(state, err) {
        state.errorPatchOrganization = err;
        state.loadingPatchOrganization = false;
    },
    organizationDataDeleteBegin(state) {
        state.loadingDeleteOrganization = true;
        state.errorDeleteOrganization = false;
    },
    organizationDataDeleteSuccess(state) {
        state.loadingDeleteOrganization = false;
    },
    organizationDataDeleteErr(state, err) {
        state.errorDeleteOrganization = err;
        state.loadingDeleteOrganization = false;
    },
    organizationAllowedProductTreeDataBegin(state) {
        state.loadingOrganizationProductTree = true;
        state.errorOrganizationProductTree = false;
    },
    organizationAllowedProductTreeDataSuccess(state, data) {
        state.organization.productTree = data;
        state.loadingOrganizationProductTree = false;
    },
    organizationAllowedProductTreeDataErr(state, err) {
        state.loadingOrganizationProductTree = false;
        state.errorOrganizationProductTree = err;
    },
    organizationPatchAllowedProductTreeDataBegin(state) {
        state.loadingPatchOrganizationProductTree = true;
        state.errorPatchOrganizationProductTree = false;
    },
    organizationPatchAllowedProductTreeDataSuccess(state) {
        state.loadingPatchOrganizationProductTree = false;
    },
    organizationPatchAllowedProductTreeDataErr(state, err) {
        state.loadingPatchOrganizationProductTree = false;
        state.errorPatchOrganizationProductTree = err;
    },
    organizationProductTreeConfigDataBegin(state) {
        state.loadingOrganizationProductTree = true;
        state.errorOrganizationProductTree = false;
    },
    organizationProductTreeConfigDataSuccess(state, data) {
        state.productTreeConfig = data;
        state.loadingOrganizationProductTree = false;
    },
    organizationProductTreeConfigDataErr(state, err) {
        state.loadingOrganizationProductTree = false;
        state.errorOrganizationProductTree = err;
    },
    setCurrentPageOrganization(state, page) {
        state.currentPage = page;
    },
};
