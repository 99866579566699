export default {
    exportDataBegin(state) {
        state.loadingExport = true;
        state.export = {
            id: null,
            name: null,
            description: '',
        };
        state.errorExport = false;
    },
    exportDataSuccess(state, data) {
        state.export = data;
        state.showEdit = true;
        state.loadingExport = false;
    },
    exportDataErr(state, err) {
        state.loadingExport = false;
        state.errorExport = err;
    },
    exportsDataBegin(state) {
        state.loadingExports = true;
    },
    exportsDataSuccess(state, data) {
        state.exports = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.currentPage;
        state.loadingExports = false;
    },
    exportsDataErr(state, err) {
        state.loadingExports = false;
        state.errorExports = err;
    },
    exportDataCreateBegin(state) {
        state.loadingCreateExport = true;
    },
    exportDataCreateSuccess(state) {
        state.loadingCreateExport = false;
        state.showEdit = false;
    },
    exportDataCreateErr(state, err) {
        state.loadingCreateExport = false;
        state.errorCreateExport = err;
    },
    exportDataPatchBegin(state) {
        state.loadingPatchExport = true;
    },
    exportDataPatchSuccess(state) {
        state.loadingPatchExport = false;
    },
    exportDataPatchErr(state, err) {
        state.loadingPatchExport = false;
        state.errorPatchExport = err;
    },
    exportDataDeleteBegin(state) {
        state.loadingDeleteExport = true;
    },
    exportDataDeleteSuccess(state) {
        state.loadingDeleteExport = false;
    },
    exportDataDeleteErr(state, err) {
        state.loadingDeleteExport = false;
        state.errorDeleteExport = err;
    },
    setCurrentPageExport(state, page) {
        state.currentPage = page;
    },
};
