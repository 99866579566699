export default {
    teamDataBegin(state) {
        state.loadingTeam = true;
        state.team = {
            id: null,
            name: null,
            description: '',
        };
        state.errorTeam = false;
    },
    teamDataSuccess(state, data) {
        state.team = data;
        state.showEdit = true;
        state.loadingTeam = false;
    },
    teamDataErr(state, err) {
        state.loadingTeam = false;
        state.errorTeam = err;
    },
    teamsDataBegin(state) {
        state.loadingTeams = true;
    },
    teamsDataSuccess(state, data) {
        state.teams = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingTeams = false;
    },
    teamsDataErr(state, err) {
        state.loadingTeams = false;
        state.errorTeams = err;
    },
    teamDataCreateBegin(state) {
        state.loadingCreateTeam = true;
    },
    teamDataCreateSuccess(state) {
        state.loadingCreateTeam = false;
        state.showEdit = false;
    },
    teamDataCreateErr(state, err) {
        state.loadingCreateTeam = false;
        state.errorCreateTeam = err;
    },
    teamDataPatchBegin(state) {
        state.loadingPatchTeam = true;
    },
    teamDataPatchSuccess(state) {
        state.loadingPatchTeam = false;
    },
    teamDataPatchErr(state, err) {
        state.loadingPatchTeam = false;
        state.errorPatchTeam = err;
    },
    teamDataDeleteBegin(state) {
        state.loadingDeleteTeam = true;
    },
    teamDataDeleteSuccess(state) {
        state.loadingDeleteTeam = false;
    },
    teamDataDeleteErr(state, err) {
        state.loadingDeleteTeam = false;
        state.errorDeleteTeam = err;
    },
    setCurrentPageTeams(state, page) {
        state.currentPage = page;
    },
};
