import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    order: null,
    orderCreate: {
        type: 'ENERGY',
        productID: null,
        connections: [],
        relation: {type: 'BUSINESS'},
        relationID: null,
        flowQuestions: [],
        signatureType: 'ESIGNATURE',
        signatureData: null,
        calculations: [],
        product: null,
        startDate: null,
        productEnergyTariffID: null,
        roofType: null,
    },
    orderReOffer: {
        type: 'ENERGY',
        productID: null,
        connections: [],
        relation: {type: 'BUSINESS'},
        relationID: null,
        flowQuestions: [],
        signatureType: 'ESIGNATURE',
        signatureData: null,
        calculations: [],
        product: null,
        startDate: null,
        productEnergyTariffID: null,
        roofType: null,
    },
    sendOrderCreate: null,
    orders: [],
    productCalculations: [],
    flowQuestions: [],
    connections: [],
    connectionModelGas: [],
    communication: [],
    activity: [],
    files: [],
    energyCalculation: null,
    script: null,
    loadingChangeStatusOrder: false,
    loadingCancelOrder: false,
    loadingSendExternalOrder: false,
    loadingPdfRegenerateOrder: false,
    loadingStatusOrder: false,
    loadingOrderFiles: false,
    loadingOrderActivity: false,
    loadingOrderCommunication: false,
    loadingOrderFlowQuestions: false,
    loadingOrderConnections: false,
    loadingOrderEnergyCalculation: false,
    loadingOrderScript: false,
    loadingOrders: false,
    loadingOrder: true,
    loadingCreateOrder: false,
    loadingPatchOrder: false,
    loadingPatchOrderFlowQuestions: false,
    loadingPatchOrderConnection: false,
    loadingDeleteOrder: false,
    loadingProductCalculations: false,
    loadingPreFillRelationOnOrder: false,
    loadingOrderFileUpload: false,
    loadingSignManualOrder: false,
    errorSignManualOrder: false,
    errorOrderFlowQuestions: false,
    errorOrderEnergyCalculation: false,
    errorOrderActivity: false,
    errorOrderCommunication: false,
    errorOrderScript: false,
    errorOrders: false,
    errorOrderFileUpload: false,
    errorOrder: false,
    errorOrderFiles: false,
    errorCreateOrder: false,
    errorPatchOrder: false,
    errorPatchOrderFlowQuestions: false,
    errorPatchOrderConnection: false,
    errorDeleteOrder: false,
    errorPdfRegenerateOrder: false,
    errorSendExternalOrder: false,
    errorOrdersProductCalculations: false,
    errorOrderConnections: false,
    errorStatusOrder: false,
    errorCancelOrder: false,
    errorChangeStatusOrder: false,
    errorPreFillRelationOnOrder: false,
    errorOrderShopFlowQuestions: false,
    loadingOrderShopFlowQuestions: false,
    filesCount: 0,
    count: 0,
    filters: {
        q: null,
        organizationID: [],
        roleID: [],
        status: [],
        productID: [],
        userID: [],
        teamID: [],
        dateFrom: null,
        dateTill: null,
        dateFilterType: 'CREATETIMESTAMP',
        productCustomerType: [],
        isCancelled: null,
        isSigned: null,
        isSent: null,
        page: 1,
        currentPage: 1,
        pageSize: 20,
    },
    filterValues: {
        q: null,
        organizationID: [],
        roleID: [],
        status: [],
        productID: [],
        userID: [],
        teamID: [],
        dateFrom: null,
        dateTill: null,
        dateFilterType: 'CREATETIMESTAMP',
        productCustomerType: [],
        isCancelled: null,
        isSigned: null,
        isSent: null,
        page: 1,
        currentPage: 1,
        pageSize: 20,
    },
});

const actions = {
    /**
     *
     * Get Order
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getOrder({commit, dispatch}, id) {
        try {
            await commit('orderDataBegin');
            const response = await DataService.get(`/orders/${id}`);
            if (response.data.abilities.tabs.energy) {
                dispatch('getOrderEnergyCalculation', id);
                dispatch('getConnections', id);
            }
            if (response.data.abilities.tabs.script) {
                dispatch('getOrderScript', id);
            }

            dispatch('getOrderActivity', id);
            dispatch('getOrderCommunication', id);
            dispatch('getOrderFiles', id);
            dispatch('getStatusses', id);
            commit('orderDataSuccess', response.data);
        } catch (err) {
            await commit('orderDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async orderIsSinged({commit, dispatch, state}, id) {
        commit('orderIsSinged', id);
        if (state.order && state.order.id === id) {
            dispatch('getOrder', id);
        }
    },
    /**
     *
     * Create order
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createOrder({commit, state}, data) {
        try {
            await commit('orderDataCreateBegin');
            await DataService.post(`/orders`, state.sendOrderCreate);
            await commit('orderDataCreateSuccess');
            await commit('setCurrentPageOrder', 1);
            await data.successRoute();
            await commit('resetCreateOrderConnections');
        } catch (err) {
            await commit('orderDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param state
     * @param data
     * @returns {Promise<void>}
     */
    async cancelOrder({commit}, data) {
        try {
            await commit('orderDataCancelBegin');
            await DataService.post(`/orders/${data.id}/cancel`, data);
            await commit('orderDataCancelSuccess');
            addNotificationSuccess('Order is geannuleerd!');
        } catch (err) {
            await commit('orderDataCancelErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async changeStatus({commit}, data) {
        try {
            await commit('orderDataChangeStatusBegin');
            await DataService.post(`/orders/${data.id}/changestatus`, data);
            await commit('orderDataChangeStatusSuccess');
            addNotificationSuccess('Order status us gewijzigd!');
        } catch (err) {
            await commit('orderDataChangeStatusErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update order
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchOrder({commit}, data) {
        try {
            await commit('orderDataPatchBegin');
            const response = await DataService.patch(`/orders/${data.id}`, data);
            await commit('orderDataPatchSuccess', response.data);
        } catch (err) {
            await commit('orderDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete order (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteOrder({commit}, id) {
        try {
            await commit('orderDataDeleteBegin');
            const response = await DataService.delete(`/orders/${id}`);
            await commit('orderDataDeleteSuccess', response.data);
            addNotificationSuccess('De organisatie is met succes verwijderd.');
        } catch (err) {
            await commit('orderDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get orders (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getOrders({state, commit}) {
        try {
            await commit('ordersDataBegin');
            const response = await DataService.get('/orders', state.filterValues);
            await commit('ordersDataSuccess', response.data);
        } catch (err) {
            await commit('ordersDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async addEans({commit}, data) {
        await commit('addEans', data);
    },
    /**
     *
     * @param commit
     * @param ean
     * @returns {Promise<void>}
     */
    async deleteEan({commit}, index) {
        await commit('deleteEan', index);
    },
    async preFillRelationOnOrder({commit, dispatch}, data) {
        try {
            await commit('preFillRelationOnOrderDataBegin');
            await commit('addRelationToOrder', data.value);
            await commit('preFillRelationData', data.value)
            await dispatch("searchEdsn", {
                postalCode: data.value.postalCode,
                houseNumber: data.value.houseNumber,
                houseNumberAddition: data.value.houseNumberAddition ? data.value.houseNumberAddition : null,
                country: 'NL'
            });
            await commit('preFillRelationOnOrderDataSuccess');
            await data.stepRoute();
        } catch (err) {
            await commit('preFillRelationOnOrderDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async addRelationToOrder({commit}, data) {
        await commit('addRelationToOrder', data.value);
        await data.stepRoute();
    },

    async getProductCalculations({commit, state}, data) {
        try {
            await commit('ordersProductCalculationsDataBegin');
            const response = await DataService.post('/offerflow/energy/productcalculations?sort=price', {
                signatureType: state.orderCreate.signatureType,
                customerType: state.orderCreate.relation.type,
                connections: state.orderCreate.connections,
            });
            await commit('ordersProductCalculationsDataSuccess', response.data.data);
            await data.stepRoute();
        } catch (err) {
            await commit('ordersProductCalculationsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setProductOnOrder({commit}, data) {
        await commit('setProductOnOrder', data);
        await data.stepRoute();
    },
    async setProductOnReOrder({commit}, data) {
        await commit('setProductOnReOrder', data);
        await data.stepRoute();
    },
    async getOrderEnergyCalculation({commit, dispatch}, id) {
        try {
            await commit('orderEnergyCalculationDataBegin');
            const response = await DataService.get(`/orders/${id}/energycalculation`);
            await dispatch('getOrderFlowQuestions', id);
            await commit('orderEnergyCalculationDataSuccess', response.data);
        } catch (err) {
            await commit('orderEnergyCalculationDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderScript({commit}, id) {
        try {
            await commit('orderScriptDataBegin');
            const response = await DataService.get(`/orders/${id}/script`);
            await commit('orderScriptDataSuccess', response.data.html);
        } catch (err) {
            await commit('orderScriptDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getConnections({commit}, id) {
        try {
            await commit('orderConnectionDataBegin');
            const response = await DataService.get(`/orders/${id}/energyconnections`);
            await commit('orderConnectionDataSuccess', response.data.data);
        } catch (err) {
            await commit('orderCConnectionDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async patchConnection({commit}, data) {
        try {
            await commit('orderConnectionDataPatchBegin');
            await DataService.patch(`/orders/${data.id}/energyconnections/${data.connection.id}`, data.connection);
            await commit('orderConnectionDataPatchSuccess');
        } catch (err) {
            await commit('orderConnectionDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderFlowQuestions({commit}, id) {
        try {
            await commit('orderFlowQuestionsDataBegin');
            const response = await DataService.get(`/orders/${id}/flowquestions`);
            await commit('orderFlowQuestionsDataSuccess', response.data.data);
        } catch (err) {
            await commit('orderFlowQuestionsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderShopFlowQuestions({commit}, id) {
        try {
            await commit('orderFlowQuestionsShopBegin');
            const response = await DataService.get(`/orders/${id}/flowquestions`);
            await commit('orderFlowQuestionsShopSuccess', response.data.data);
        } catch (err) {
            await commit('orderFlowQuestionsShopErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async patchOrderFlowQuestions({commit}, data) {
        try {
            await commit('orderFlowQuestionsDataPatchBegin');
            await DataService.patch(`/orders/${data.id}/flowquestions`, data.flowQuestions);
            await commit('orderFlowQuestionsDataPatchSuccess');
            addNotificationSuccess('Succesvol geupdate!');
        } catch (err) {
            await commit('orderFlowQuestionsDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderActivity({commit}, id) {
        try {
            await commit('orderActivityDataBegin');
            const response = await DataService.get(`/orders/${id}/activities`);
            await commit('orderActivityDataSuccess', response.data.data);
        } catch (err) {
            await commit('orderActivityDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderCommunication({commit}, id) {
        try {
            await commit('orderCommunicationDataBegin');
            const response = await DataService.get(`/orders/${id}/communications`);
            await commit('orderCommunicationDataSuccess', response.data.data);
        } catch (err) {
            await commit('orderCommunicationDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async uploadFile({commit, dispatch}, data) {
        try {
            await commit('orderFileUploadBegin');
            await DataService.postMultiPart(`/orders/${data.id}/files`, data.formData);
            await commit('orderFileUploadSuccess');
            await dispatch('getOrderFiles', data.id)
        } catch (err) {
            await commit('orderFileUploadErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getOrderFiles({commit}, id) {
        try {
            await commit('orderFilesDataBegin');
            const response = await DataService.get(`/orders/${id}/files`);
            await commit('orderFilesDataSuccess', response.data);
        } catch (err) {
            await commit('orderFilesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getStatusses({commit}, id) {
        try {
            await commit('ordersStatusDataBegin');
            const response = await DataService.get(`/orders/${id}/availablestatusses`);
            await commit('ordersStatusDataSuccess', response.data.data);
        } catch (err) {
            await commit('ordersStatusDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async sendExternal({commit}, id) {
        try {
            await commit('ordersSendExternalDataBegin');
            const response = await DataService.post(`/orders/${id}/sendexternal`);
            await commit('ordersSendExternalDataSuccess', response.data);
            addNotificationSuccess('De order is met succes verzonden naar de leverancier.');
        } catch (err) {
            await commit('ordersSendExternalDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async signManual({commit}, data) {
        try {
            await commit('orderSignManualDataBegin');
            await DataService.post(`/orders/${data.id}/signmanual`, data);
            await commit('orderSignManualDataSuccess');
        } catch (err) {
            await commit('orderSignManualDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async pdfRegenerate({commit}, id) {
        try {
            await commit('ordersPdfRegenerateDataBegin');
            await DataService.post(`/orders/${id}/pdf/contract/regenerate`);
            await commit('ordersPdfRegenerateDataSuccess');
            addNotificationSuccess('De contract is opnieuw gegenereerd.');
        } catch (err) {
            await commit('ordersPdfRegenerateDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageOrder({commit}, page) {
        await commit('setCurrentPageOrder', page);
    },
    async resetRelationOnOrder({commit}) {
        await commit('preFillRelationData', {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        })
        await commit('resetRelationOnOrder');
    },
    async resetCreateOrder({commit}) {
        await commit('resetCreateOrder');
    },
    async resetCreateOrderConnections({commit}) {
        await commit('resetCreateOrderConnections');
    },
    async setOrderFilters({commit}, filters) {
        await commit('setOrderFilters', filters);
    },
    async orderCreateValidationError() {
        addNotificationError('Vul alle benodigde informatie in.');
    },
    async setOrderFlow({commit}, flow) {
        await commit('setOrderFlow', flow);
    },
    async setSignatureData({commit}, flow) {
        await commit('setSignatureData', flow);
    },
    async resetOrder({commit}) {
        await commit('resetOrder');
    },
    async prefillOrder({commit}, data) {
        await commit('preFillRelationData', data.values.order.relationRevision)
        await commit('prefillOrder', data.values);
        data.navigate();
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
