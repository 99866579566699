import axios from 'axios';
import {getItem, removeItem} from '@/utility/localStorageControl';

// export const API_ENDPOINT = "https://api.app.birdsviewsolutions.nl/v1/";
export const API_WSKEY = "cktprod";
export const API_WSHOST = "api." + window.location.hostname;
export const API_ENDPOINT = "https://api." + window.location.hostname + "/v1/";
// export const API_ENDPOINT = "https://api.app.logicsales.nl/v1/";
// export const API_WSKEY = "cktdev";
// export const API_WSHOST = "dev.api.contrackt.cloud";
// export const API_ENDPOINT = "https://dev.api.contrackt.cloud/v1/";
// export const API_ENDPOINT = "https://api.app.contrackt.nl/v1/";
// const IS_DEV = process.env.VUE_APP_CKT_DEV;

const getApiURL = () => {
    // return "https://api." + window.location.hostname + "/v1/";
    return API_ENDPOINT;

//     // return API_ENDPOINT;
//     if(typeof window === 'undefined' || IS_DEV)
//         return API_ENDPOINT;
// // //@TODO dirty fix!!! check is dev
//     return "https://api." + window.location.hostname + "/v1/";
}

const authHeader = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
});

const authHeaderMultiPart = () => ({
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'multipart/form-data',
});

const client = axios.create({
    baseURL: getApiURL(),
    headers: {
        Authorization: `Bearer ${getItem('access_token')}`,
    },
});


class DataService {
    static get(path = '', params = {}) {
        return client({
            method: 'GET',
            url: path,
            params: params,
            headers: {...authHeader()},
        });
    }

    static post(path = '', data = {}, optionalHeader = {}) {
        return client({
            method: 'POST',
            url: path,
            data,
            headers: {...authHeader(), ...optionalHeader},
        });
    }

    static postMultiPart(path = '', data = {}) {
        return client({
            method: 'POST',
            url: path,
            data,
            headers: {...authHeaderMultiPart()},
        });
    }

    static patch(path = '', data = {}) {
        return client({
            method: 'PATCH',
            url: path,
            data: JSON.stringify(data),
            headers: {...authHeader()},
        });
    }

    static delete(path = '', data = {}) {
        return client({
            method: 'DELETE',
            url: path,
            data: JSON.stringify(data),
            headers: {...authHeader()},
        });
    }

    static put(path = '', data = {}) {
        return client({
            method: 'PUT',
            url: path,
            data: JSON.stringify(data),
            headers: {...authHeader()},
        });
    }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use((config) => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    const {headers} = config;
    requestConfig.headers = {...headers, Authorization: `Bearer ${getItem('access_token')}`};

    return requestConfig;
});

client.interceptors.response.use(
    (response) => response,
    (error) => {
        const {response} = error;
        // const originalRequest = error.config;
        if (response) {
            if (response.status === 401) {
                removeItem('access_token');
                removeItem('ability');
                window.location.reload();
                return;
            }
        }
        return Promise.reject(error);
    },
);
export {DataService, getApiURL};
