export default {
    importDataBegin(state) {
        state.loadingImport = true;
        state.import = {
            id: null,
            name: null,
            description: '',
        };
        state.errorImport = false;
    },
    importDataSuccess(state, data) {
        state.import = data;
        state.showEdit = true;
        state.loadingImport = false;
    },
    importDataErr(state, err) {
        state.loadingImport = false;
        state.errorImport = err;
    },
    importsDataBegin(state) {
        state.loadingImports = true;
    },
    importsDataSuccess(state, data) {
        state.imports = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.currentPage;
        state.loadingImports = false;
    },
    importsDataErr(state, err) {
        state.loadingImports = false;
        state.errorImports = err;
    },
    importDataCreateBegin(state) {
        state.loadingCreateImport = true;
    },
    importDataCreateSuccess(state) {
        state.loadingCreateImport = false;
        state.showEdit = false;
    },
    importDataCreateErr(state, err) {
        state.loadingCreateImport = false;
        state.errorCreateImport = err;
    },
    importDataPatchBegin(state) {
        state.loadingPatchImport = true;
    },
    importDataPatchSuccess(state) {
        state.loadingPatchImport = false;
    },
    importDataPatchErr(state, err) {
        state.loadingPatchImport = false;
        state.errorPatchImport = err;
    },
    importDataDeleteBegin(state) {
        state.loadingDeleteImport = true;
    },
    importDataDeleteSuccess(state) {
        state.loadingDeleteImport = false;
    },
    importDataDeleteErr(state, err) {
        state.loadingDeleteImport = false;
        state.errorDeleteImport = err;
    },
    setCurrentPageImport(state, page) {
        state.currentPage = page;
    },
};
