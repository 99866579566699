import {removeItem, setItem} from "../../../../utility/localStorageControl";
import Pusher from 'pusher-js';

window.Pusher = Pusher;

export default {
    loginBegin(state) {
        state.loadingLogin = true;
        state.errorLogin = false;
    },

    loginSuccess(state) {
        state.loadingLogin = false;
    },

    setLoginData(state, data) {
        if (data.requirePasswordChange) {
            state.passwordResetToken = data.passwordResetToken;
            state.type = 'PASSWORD';
        } else if (state.requireTFA) {
            state.type = 'TFA';
        } else {
            state.type = 'LOGIN';
            setItem('access_token', data.accessToken);
            state.isLoggedIn = true;
        }
    },

    removeAccessToken(state) {
        state.isLoggedIn = false;
        removeItem('access_token');
        removeItem('tenant');
        removeItem('id');
        removeItem('ability');
        // disconnect websocket
        if (window.Echo) {
            window.Echo.disconnect();
            window.Echo = null;
        }
    },

    loginErr(state, err) {
        state.loadingLogin = false;
        state.errorLogin = err;
    },

    logoutBegin(state) {
        state.loadingLogout = true;
    },

    logoutSuccess(state) {
        state.loadingLogout = false;
        // disconnect websocket
        if (window.Echo) {
            window.Echo.disconnect();
            window.Echo = null;
        }
    },

    logoutErr(state, err) {
        state.loadingLogout = false;
        state.error = err;
    },
    setPasswordBegin(state) {
        state.loadingSetPassword = true;
    },

    setPasswordSuccess(state) {
        state.loadingSetPassword = false;
    },

    setPasswordErr(state, err) {
        state.loadingSetPassword = false;
        state.errorSetPassword = err;
    },
    setChangePasswordBegin(state) {
        state.errorChangePassword = false;
        state.loadingChangePassword = true;
    },

    setChangePasswordSuccess(state) {
        state.loadingChangePassword = false;
    },

    setChangePasswordErr(state, err) {
        state.loadingChangePassword = false;
        state.errorChangePassword = err;
    },
};
