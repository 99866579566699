import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    relation: {
        id: null,
        createTimestamp: null,
        modifiedTimestamp: null,
        deletedTimestamp: null,
        isAnonymized: null,
        organizationID: null,
        externalReference: null,
        type: 'CONSUMER',
        isParent: null,
        parentID: null,
        firstName: null,
        middleName: null,
        lastName: null,
        gender: 'MALE',
        birthDate: null,
        phoneNumber: null,
        mobileNumber: null,
        email: null,
        street: null,
        houseNumber: null,
        houseNumberAddition: null,
        postalCode: null,
        city: null,
        country: null,
        companyName: null,
        companyCocNumber: null,
        bankAccountType: null,
        bankAccountNumber: null,
        debtorName: null,
        saveToRevision: false,
        companyLegalForm: null,
    },
    relations: [],
    orders: [],
    orderCount: 0,
    orderFilters: {
        page: 1,
        currentPage: 1,
        pageSize: 10,
    },
    loadingRelations: false,
    loadingRelation: false,
    loadingRelationOrders: false,
    loadingCreateRelation: false,
    loadingPatchRelation: false,
    loadingDeleteRelation: false,
    errorRelations: null,
    errorRelation: null,
    errorRelationOrders: null,
    errorCreateRelation: null,
    errorPatchRelation: null,
    errorDeleteRelation: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get Relation
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getRelation({commit}, id) {
        try {
            await commit('relationDataBegin');
            const response = await DataService.get(`/relations/${id}`);
            await commit('relationDataSuccess', response.data);
        } catch (err) {
            await commit('relationDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get Orders for Relation
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getRelationOrders({commit}, id) {
        try {
            await commit('relationOrdersDataBegin');
            const response = await DataService.get(`/relations/${id}/orders`);
            await commit('relationOrdersDataSuccess', response.data);
        } catch (err) {
            await commit('relationOrdersDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setCurrentPageRelationOrders({commit}, page) {
        await commit('setCurrentPageRelationOrders', page);
    },
    /**
     *
     * Create relation
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createRelation({commit, dispatch}, data) {
        try {
            await commit('relationDataCreateBegin');
            const response = await DataService.post(`/relations`, data);
            await commit('relationDataCreateSuccess', response.data);
            await dispatch('getRelations');
        } catch (err) {
            await commit('relationDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    }, /**
     *
     * Update relation
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchRelation({commit, dispatch}, data) {
        try {
            if (data.relationObjectType === 'ORDEREDIT') {
                await commit('relationDataPatchBegin');
                const response = await DataService.patch(`orders/${data.orderID}/relation`, data);
                await commit('relationDataPatchSuccess', response.data);
                await dispatch('getRelations');
                addNotificationSuccess('De organisatie is met succes aangepast.');
            } else {
                await commit('relationDataPatchBegin');
                const response = await DataService.patch(`/relations/${data.id}`, data);
                await commit('relationDataPatchSuccess', response.data);
                await dispatch('getRelations');
                addNotificationSuccess('De organisatie is met succes aangepast.');
            }
        } catch (err) {
            await commit('relationDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    }, /**
     *
     * Delete relation (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteRelation({commit, dispatch}, id) {
        try {
            await commit('relationDataDeleteBegin');
            const response = await DataService.delete(`/relations/${id}`);
            await commit('relationDataDeleteSuccess', response.data);
            await dispatch('getRelations');
            addNotificationSuccess('De gebruiker is met succes verwijderd.');
        } catch (err) {
            await commit('relationDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    }, /**
     *
     * Get relations (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getRelations({commit}, data) {
        try {
            await commit('relationsDataBegin');
            const response = await DataService.get('/relations', data);
            await commit('relationsDataSuccess', response.data);
        } catch (err) {
            await commit('relationsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param state
     * @param data
     */
    async preFillRelationData({commit}, data) {
        await commit('preFillRelationData', data);
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageRelation({commit}, page) {
        await commit('setCurrentPageRelation', page);
    },
};

export default {
    namespaced: false, state, actions, mutations,
};
