export default {
    userDataBegin(state) {
        state.loadingUser = true;
        state.errorUser = false;
    },
    userDataSuccess(state, data) {
        state.user = data;
        state.loadingUser = false;
    },
    userDataErr(state, err) {
        state.loadingUser = false;
        state.errorUser = err;
    },
    usersDataBegin(state) {
        state.loadingUsers = true;
        state.errorUsers = null;
    },
    usersDataSuccess(state, data) {
        state.users = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingUsers = false;
    },
    usersDataErr(state, err) {
        state.errorUsers = err;
        state.loadingUsers = false;
    },
    // userFilterDataBegin(state) {
    //     state.loadingUserFilter = true;
    //     state.errorUserFilter = null;
    // },
    // userFilterDataSuccess(state, data) {
    //     state.userFilters = data.data;
    //     state.loadingUserFilter = false;
    // },
    // userFilterDataErr(state, err) {
    //     state.errorUserFilter = err;
    //     state.loadingUserFilter = false;
    // },
    userDataCreateBegin(state) {
        state.loadingCreateUser = true;
        state.errorCreateUser = false;
    },
    userDataCreateSuccess(state) {
        state.loadingCreateUser = false;
    },
    userDataCreateErr(state, err) {
        state.errorCreateUser = err;
        state.loadingCreateUser = false;
    },
    userDataPatchBegin(state) {
        state.loadingPatchUser = true;
        state.errorPatchUser = false;
    },
    userDataPatchSuccess(state) {
        state.loadingPatchUser = false;
    },
    userDataPatchErr(state, err) {
        state.errorPatchUser = err;
        state.loadingPatchUser = false;
    },
    userDataPatchPasswordBegin(state) {
        state.loadingPatchPassword = true;
        state.errorPatchPassword = false;
    },
    userDataPatchPasswordSuccess(state) {
        state.loadingPatchPassword = false;
    },
    userDataPatchPasswordErr(state, err) {
        state.errorPatchPassword = err;
        state.loadingPatchPassword = false;
    },
    userDataDeleteBegin(state) {
        state.loadingDeleteUser = true;
        state.errorDeleteUser = false;
    },
    userDataDeleteSuccess(state) {
        state.loadingDeleteUser = false;
    },
    userDataDeleteErr(state, err) {
        state.errorDeleteUser = err;
        state.loadingDeleteUser = false;
    },
    userAllowedProductTreeDataBegin(state) {
        state.loadingUserProductTree = true;
        state.errorUserProductTree = false;
    },
    userAllowedProductTreeDataSuccess(state, data) {
        const user = state.user;
        user.productTree = data;
        state.user = user;
        state.loadingUserProductTree = false;
    },
    userAllowedProductTreeDataErr(state, err) {
        state.loadingUserProductTree = false;
        state.errorUserProductTree = err;
    },
    userPatchAllowedProductTreeDataBegin(state) {
        state.loadingPatchUserProductTree = true;
        state.errorPatchUserProductTree = false;
    },
    userPatchAllowedProductTreeDataSuccess(state) {
        state.loadingPatchUserProductTree = false;
    },
    userPatchAllowedProductTreeDataErr(state, err) {
        state.loadingPatchUserProductTree = false;
        state.errorPatchUserProductTree = err;
    },
    userProductTreeConfigDataBegin(state) {
        state.loadingUserProductTree = true;
        state.errorUserProductTree = false;
    },
    userProductTreeConfigDataSuccess(state, data) {
        state.productTreeConfig = data;
        state.loadingUserProductTree = false;
    },
    userProductTreeConfigDataErr(state, err) {
        state.loadingUserProductTree = false;
        state.errorUserProductTree = err;
    },
    setCurrentPageUsers(state, page) {
        const filters = state.filterValues;
        filters.currentPage = page;
        filters.page = page;
        state.filterValues = filters;
    },
    setUserFilters(state, data) {
        let filters = state.filterValues;
        filters = {...filters, ...data};
        state.filterValues = filters;
    },
    userAllowedPermissionsDataBegin(state) {
        state.loadingUserAllowedPermissions = true;
        state.errorUserAllowedPermissions = false;
    },
    userAllowedPermissionsDataSuccess(state, data) {
        state.userAllowedPermissions = data;
        state.loadingUserAllowedPermissions = false;
    },
    userAllowedPermissionsDataErr(state, err) {
        state.loadingUserAllowedPermissions = false;
        state.errorUserAllowedPermissions = err;
    },
};
