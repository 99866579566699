import mutations from './mutations';
import {DataService} from "../../../../config/dataService/dataService";
import {notification} from "ant-design-vue";
import {getItem} from "../../../../utility/localStorageControl";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    isLoggedIn: getItem('access_token'),
    loadingLogin: false,
    loadingChangePassword: false,
    loadingSetPassword: false,
    error: false,
    errorSetPassword: false,
    errorChangePassword: false,
    type: null,
    passwordResetToken: null,
});

const actions = {
        async login({state, commit, dispatch}, data) {
            try {
                await commit('loginBegin');
                const response = await DataService.post("/auth/login", data.formState)
                await commit('setLoginData', response.data);
                if (state.type && state.type === 'LOGIN') {
                    await dispatch('getAcl')
                    await dispatch('getProfile')
                    await commit('loginSuccess');
                    await data.loginRoute();
                } else {
                    await commit('loginSuccess');
                    await data.loginRoute();
                }
            } catch (err) {
                await commit('loginErr', err);
                addNotificationError(err?.response?.data?.message);
            }
        },
        async logout({commit}, logOutRoot) {
            try {
                await commit('logoutBegin');
                await DataService.post('/auth/logout');
                await commit('removeAccessToken');
                await commit('logoutSuccess');
                await logOutRoot();
            } catch (err) {
                await commit('removeAccessToken');
                await logOutRoot();
            }
        },
        async setPassword({commit}, data) {
            try {
                await commit('setPasswordBegin');
                await DataService.post('/auth/resetpassword', data.form);
                await commit('setPasswordSuccess');
                await data.loginRoute();
            } catch (err) {
                await commit('setPasswordErr');
            }
        },
        async changePassword({commit}, data) {
            try {
                await commit('setChangePasswordBegin');
                await DataService.post('/auth/me/changepassword', data);
                await commit('setChangePasswordSuccess');
                addNotificationSuccess('Uw wachtwoord is met succes gewijzigd.');
            } catch (err) {
                await commit('setChangePasswordErr');
                addNotificationError(err?.response?.data?.message);
            }
        },
    }
;

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
