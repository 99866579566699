import moment from "moment-timezone";

moment.tz.setDefault("Europe/Berlin");
export default {
    orderDataBegin(state) {
        state.errorOrder = false;
        state.loadingOrder = true;
    },
    orderDataSuccess(state, data) {
        const order = data
        state.order = order;
        state.loadingOrder = false;
    },
    orderDataErr(state, err) {
        state.loadingOrder = false;
        state.errorOrder = err;
    },
    orderDataCancelBegin(state) {
        state.errorCancelOrder = false;
        state.loadingCancelOrder = true;
    },
    orderDataCancelSuccess(state) {
        state.loadingCancelOrder = false;
    },
    orderDataCancelErr(state, err) {
        state.loadingCancelOrder = false;
        state.errorCancelOrder = err;
    },
    orderDataChangeStatusBegin(state) {
        state.errorChangeStatusOrder = false;
        state.loadingChangeStatusOrder = true;
    },
    orderDataChangeStatusSuccess(state) {
        state.loadingChangeStatusOrder = false;
    },
    orderDataChangeStatusErr(state, err) {
        state.loadingChangeStatusOrder = false;
        state.errorChangeStatusOrder = err;
    },
    ordersDataBegin(state) {
        state.loadingOrders = true;
    },
    ordersDataSuccess(state, data) {
        state.orders = data.data;
        state.count = data.meta.total;
        state.loadingOrders = false;
    },
    ordersDataErr(state, err) {
        state.loadingOrders = false;
        state.errorOrders = err;
    },
    orderDataCreateBegin(state) {
        state.loadingCreateOrder = true;
        const order = JSON.parse(JSON.stringify(state.orderCreate));
        let flowQuestions = [];
        let startDate = '';

        for (let i = 0; i < order.flowQuestions.length; i++) {
            if (order.flowQuestions[i].inputType !== 'DATE') {
                flowQuestions.push({id: order.flowQuestions[i].id, value: order.flowQuestions[i].value})
            }
            if (order.flowQuestions[i].inputType === 'DATE' && order.flowQuestions[i].inputType !== 'STARTDATE') {
                flowQuestions.push({
                    id: order.flowQuestions[i].id,
                    value: moment(order.flowQuestions[i].value).format('YYYY-MM-DD')
                })
            }
            if (order.flowQuestions[i].inputType === 'STARTDATE') {
                startDate = order.flowQuestions[i].value;
            }
        }

        for (let i = 0; i < order.connections.length; i++) {
            order.connections[i].startDate = moment(startDate).format('YYYY-MM-DD');
        }
        order.flowQuestions = flowQuestions;
        state.sendOrderCreate = order;
    },
    orderDataCreateSuccess(state) {
        state.sendOrderCreate = null;
        state.loadingCreateOrder = false;
    },
    orderDataCreateErr(state, err) {
        state.loadingCreateOrder = false;
        state.errorCreateOrder = err;
    },
    orderDataPatchBegin(state) {
        state.loadingPatchOrder = true;
    },
    orderDataPatchSuccess(state) {
        state.loadingPatchOrder = false;
    },
    orderDataPatchErr(state, err) {
        state.loadingPatchOrder = false;
        state.errorPatchOrder = err;
    },
    orderDataDeleteBegin(state) {
        state.loadingDeleteOrder = true;
    },
    orderDataDeleteSuccess(state) {
        state.loadingDeleteOrder = false;
    },
    orderDataDeleteErr(state, err) {
        state.loadingDeleteOrder = false;
        state.errorDeleteOrder = err;
    },
    addEans(state, data) {
        const order = state.orderCreate
        let ids = order.connections.map(function (item) {
            return item['ean'];
        });
        const filteredEans = data.filter(i => ids.indexOf(i.ean) === -1);
        const configElectricity = {
            capacityCode: '10211',
            switchType: 'SWITCH',
            meterType: 'SINGLE',
            isResidenceFunction: true,
            usageElectricityHigh: null,
            usageElectricityLow: null,
            usageElectricitySingle: null,
            returnElectricityHigh: null,
            returnElectricityLow: null,
            returnElectricitySingle: null,
            hasReturn: false,
            startDate: '',
        }
        const configGas = {
            capacityCode: '20111',
            switchType: 'SWITCH',
            usageGas: null,
            meterType: 'SINGLE',
            startDate: '',
        }
        const eansToAdd = filteredEans.map(object => {
            if (object.marketSegment === 'ELECTRICITY') {
                return {...object, ...configElectricity};
            }

            if (object.marketSegment === 'GAS') {
                return {...object, ...configGas};
            }
        });
        order.connections = [...order.connections, ...eansToAdd];
        state.orderCreate = order;
    },
    deleteEan(state, index) {
        let order = state.orderCreate;
        order.connections.splice(index, 1);
        state.orderCreate = order;
    },
    ordersProductCalculationsDataBegin(state) {
        state.loadingOrdersOfferFlowProductCalculations = true;
        state.productCalculations = [];
        state.errorOrder = false;
        const order = state.orderCreate;
        for (let i = 0; i < order.connections.length; i++) {
            if (order.startDate) {
                order.connections[i].startDate = moment(order.startDate).format('YYYY-MM-DD');
            }
        }
        state.orderCreate = order;
    },
    ordersProductCalculationsDataSuccess(state, data) {
        state.loadingOrdersOfferFlowProductCalculations = false;
        state.productCalculations = data;
    },
    ordersProductCalculationsDataErr(state, err) {
        state.loadingOrdersOfferFlowProductCalculations = false;
        state.errorOrder = err;
    },
    addRelationToOrder(state, data) {
        let order = state.orderCreate;

        if (data.type === 'CONSUMER') {
            data.companyName = null;
            data.companyCocNumber = null;
        }

        order.relation = data;
        if (data.id !== undefined) {
            order.relationID = data.id;
        }

        state.orderCreate = order;
    },
    setProductOnOrder(state, data) {
        let order = state.orderCreate;
        order.productID = data.item.product.id;
        order.product = data.item.product;
        order.calculation = data.item.calculation;
        order.product.customerApprovalsValidation = new Array(data.item.product.customerApprovals.length).fill(false);
        order.flowQuestions = [];
        order.flowQuestions = data.item.flowQuestions;
        let found = false;
        for (let i = 0; i < data.item.flowQuestions.length; i++) {
            if (data.item.flowQuestions[i].inputType === 'STARTDATE') {
                found = true;
                break;
            }
        }
        if (!found) {
            order.flowQuestions.push({
                id: null,
                type: "DATE",
                name: "startDate",
                displayName: "Gewenste startdatum",
                inputType: "STARTDATE",
                inputValues: [],
                isDefault: true,
                inputDefaultValue: "",
                isRequired: true,
                value: order.startDate,
            });
        }
        if (!order.product['isProxy']) {
            order.productEnergyTariffID = data.item.currentTariff?.id;
        }
        state.orderCreate = order;
    },
    setProductOnReOrder(state, data) {
        const order = JSON.parse(JSON.stringify(state.orderCreate));
        order.productID = data.item.product.id;
        order.product = data.item.product;
        order.calculation = data.item.calculation;
        order.product.customerApprovalsValidation = new Array(data.item.product.customerApprovals.length).fill(false);
        for (let i = 0; i < order.flowQuestions.length; i++) {
            if (order.flowQuestions[i].inputType === 'STARTDATE') {
                order.flowQuestions[i].value = null;
                break;
            }
        }

        if (!order.product['isProxy']) {
            order.productEnergyTariffID = data.item.currentTariff?.id;
        }
        state.orderCreate = order;
    },
    orderEnergyCalculationDataBegin(state) {
        state.errorEnergyCalculationOrder = false;
        state.loadingEnergyCalculationOrder = true;
    },
    orderEnergyCalculationDataSuccess(state, data) {
        state.energyCalculation = data;
        state.loadingEnergyCalculationOrder = false;
    },
    orderEnergyCalculationDataErr(state, err) {
        state.loadingEnergyCalculationOrder = false;
        state.errorEnergyCalculationOrder = err;
    },
    orderScriptDataBegin(state) {
        state.errorOrderScript = false;
        state.loadingOrderScript = true;
    },
    orderScriptDataSuccess(state, data) {
        state.script = data;
        state.loadingOrderScript = false;
    },
    orderScriptDataErr(state, err) {
        state.loadingOrderScript = false;
        state.errorOrderScript = err;
    },
    orderFlowQuestionsDataBegin(state) {
        state.errorOrderFlowQuestions = false;
        state.loadingOrderFlowQuestions = true;
    },
    orderFlowQuestionsDataSuccess(state, data) {
        const questions = data;
        questions.push({
            id: 1,
            type: "DATE",
            name: "startDate",
            displayName: "Gewenste startdatum",
            inputType: "STARTDATE",
            inputValues: [],
            isDefault: true,
            inputDefaultValue: "",
            isRequired: true,
            value: moment(state.connections[0].startDate).format('YYYY-MM-DD'),
        });
        state.flowQuestions = questions;
        state.loadingOrderFlowQuestions = false;
    },
    orderFlowQuestionsDataErr(state, err) {
        state.loadingOrderFlowQuestions = false;
        state.errorOrderFlowQuestions = err;
    },
    orderFlowQuestionsShopBegin(state) {
        state.errorOrderShopFlowQuestions = false;
        state.loadingOrderShopFlowQuestions = true;
    },
    orderFlowQuestionsShopSuccess(state, data) {
        state.flowQuestions = data;
        state.loadingOrderShopFlowQuestions = false;
    },
    orderFlowQuestionsShopErr(state, err) {
        state.loadingOrderShopFlowQuestions = false;
        state.errorOrderShopFlowQuestions = err;
    },
    orderFlowQuestionsDataPatchBegin(state) {
        state.loadingPatchOrderFlowQuestions = true;
    },
    orderFlowQuestionsDataPatchSuccess(state) {
        state.loadingPatchOrderFlowQuestions = false;
    },
    orderFlowQuestionsDataPatchErr(state, err) {
        state.loadingPatchOrderFlowQuestions = false;
        state.errorPatchOrderFlowQuestions = err;
    },
    orderConnectionDataBegin(state) {
        state.errorOrderConnections = false;
        state.loadingOrderConnections = true;
    },
    orderConnectionDataSuccess(state, data) {
        state.connections = data;
        state.loadingOrderConnections = false;
    },
    orderConnectionDataErr(state, err) {
        state.loadingOrderConnections = false;
        state.errorOrderConnections = err;
    },
    orderConnectionDataPatchBegin(state) {
        state.loadingPatchOrderConnection = true;
    },
    orderConnectionDataPatchSuccess(state) {
        state.loadingPatchOrderConnection = false;
    },
    orderConnectionDataPatchErr(state, err) {
        state.loadingPatchOrderConnection = false;
        state.errorPatchOrderConnection = err;
    },
    orderActivityDataBegin(state) {
        state.loadingOrderActivity = true;
    },
    orderActivityDataSuccess(state, data) {
        state.activity = data;
        state.loadingOrderActivity = false;
    },
    orderActivityDataErr(state, err) {
        state.loadingOrderActivity = false;
        state.errorOrderActivity = err;
    },
    orderCommunicationDataBegin(state) {
        state.loadingOrderCommunication = true;
    },
    orderCommunicationDataSuccess(state, data) {
        state.communication = data;
        state.loadingOrderCommunication = false;
    },
    orderCommunicationDataErr(state, err) {
        state.loadingOrderCommunication = false;
        state.errorOrderCommunication = err;
    },
    orderFilesDataBegin(state) {
        state.loadingOrderFiles = true;
    },
    orderFilesDataSuccess(state, data) {
        state.files = data.data;
        state.filesCount = data.meta.total;
        state.loadingOrderFiles = false;
    },
    orderFilesDataErr(state, err) {
        state.loadingOrderFiles = false;
        state.errorOrderFiles = err;
    },
    ordersStatusDataBegin(state) {
        state.errorStatusOrder = false;
        state.loadingStatusOrder = true;
    },
    ordersStatusDataSuccess(state, data) {
        state.statusses = data;
        state.loadingStatusOrder = false;
    },
    ordersStatusDataErr(state, err) {
        state.loadingStatusOrder = false;
        state.errorStatusOrder = err;
    },
    ordersSendExternalDataBegin(state) {
        state.errorSendExternalOrder = false;
        state.loadingSendExternalOrder = true;
    },
    ordersSendExternalDataSuccess(state, data) {
        if (data.success) {
            const order = JSON.parse(JSON.stringify(state.order));
            order.status = 'SENT';
            order.statusName = 'Verzonden';
            state.order = order;
        }
        state.loadingSendExternalOrder = false;
    },
    ordersSendExternalDataErr(state, err) {
        state.loadingSendExternalOrder = false;
        state.errorSendExternalOrder = err;
    },
    orderSignManualDataBegin(state) {
        state.errorSignManualOrder = false;
        state.loadingSignManualOrder = true;
    },
    orderSignManualDataSuccess(state) {
        state.loadingSignManualOrder = false;
    },
    orderSignManualDataErr(state, err) {
        state.loadingSignManualOrder = false;
        state.errorSignManualOrder = err;
    },
    ordersPdfRegenerateDataBegin(state) {
        state.errorPdfRegenerateOrder = false;
        state.loadingPdfRegenerateOrder = true;
    },
    ordersPdfRegenerateDataSuccess(state) {
        state.loadingPdfRegenerateOrder = false;
    },
    ordersPdfRegenerateDataErr(state, err) {
        state.loadingPdfRegenerateOrder = false;
        state.errorPdfRegenerateOrder = err;
    },
    setCurrentPageOrder(state, page) {
        const filters = state.filterValues;
        filters.currentPage = page;
        filters.page = page;
        state.filterValues = filters;
    },
    preFillRelationOnOrderDataBegin(state) {
        state.errorPreFillRelationOnOrder = false;
        state.loadingPreFillRelationOnOrder = true;
    },
    preFillRelationOnOrderDataSuccess(state) {
        state.loadingPreFillRelationOnOrder = false;
    },
    preFillRelationOnOrderDataErr(state, err) {
        state.loadingPreFillRelationOnOrder = false;
        state.errorPreFillRelationOnOrder = err;
    },
    resetCreateOrder(state) {
        state.orderCreate = {
            type: 'ENERGY',
            productID: null,
            connections: [],
            relation: {type: 'CONSUMER'},
            relationID: null,
            flowQuestions: [],
            signatureType: 'ESIGNATURE',
            signatureData: null,
            calculations: null,
            product: null,
            productEnergyTariffID: null,
            startDate: null,
        };
    },
    resetCreateOrderConnections(state) {
        let order = JSON.parse(JSON.stringify(state.orderCreate));
        order.connections = [];
        order.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };
        state.orderCreate = order;
    },
    resetRelationOnOrder(state) {
        let order = JSON.parse(JSON.stringify(state.orderCreate));
        order.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };
        state.orderCreate = order;
    },
    setOrderFilters(state, data) {
        let filters = state.filterValues;
        filters = {...filters, ...data};
        state.filterValues = filters;
    },
    setOrderFlow(state, data) {
        const order = state.orderCreate;
        order.signatureType = data.flow;
        order.type = data.config.type;
        state.orderCreate = order;
    },
    setSignatureData(state, data) {
        const order = state.orderCreate;
        order.signatureData = data;
        state.orderCreate = order;
    },
    resetOrder(state) {
        state.order = null;
    },
    orderFileUploadBegin(state) {
        state.errorOrderFileUpload = false;
        state.loadingOrderFileUpload = true;
    },
    orderFileUploadSuccess(state) {
        state.loadingOrderFileUpload = false;
    },
    orderFileUploadErr(state, err) {
        state.loadingOrderFileUpload = false;
        state.errorOrderFileUpload = err;
    },
    orderIsSinged(state, id) {
        const orders = JSON.parse(JSON.stringify(state.orders));
        if (orders.length > 0) {
            // Check if orders has an order with id
            let obj = orders.find(o => o.id === id);
            if (obj) {
                // update object isSigned to true
                orders[orders.indexOf(obj)].isSigned = true;
            }
            state.orders = orders;
        }
    },
    prefillOrder(state, data) {
        const orderCreate = JSON.parse(JSON.stringify(state.orderCreate));
        const connections = data.connections;
        for (let i = 0; i < connections.length; i++) {
            connections[i].startDate = null;
            if (connections[i].marketSegment === 'ELECTRICITY') {
                connections[i].usageElectricityHigh = null;
                connections[i].usageElectricityLow = null;
                connections[i].usageElectricitySingle = null;
                connections[i].returnElectricityHigh = null;
                connections[i].returnElectricityLow = null;
                connections[i].returnElectricitySingle = null;
            }

            if (connections[i].marketSegment === 'GAS') {
                connections[i].usageGas = null;
            }
        }
        orderCreate.flowQuestions = data.flowQuestions;
        orderCreate.connections = connections;
        orderCreate.relation = data.order.relationRevision;
        orderCreate.relationID = data.order.relationID;
        orderCreate.signatureType = 'ESIGNATURE';
        orderCreate.signatureData = null;
        orderCreate.calculations = null;
        state.orderCreate = orderCreate;
    },
};
