export default {
    solarSimpleCalculationBegin(state) {
        state.loadingCalculation = true;
        state.calculation = null;
        state.calculationOrder = null;
        state.errorCalculation = false;
    },
    solarSimpleCalculationSuccess(state, data) {
        state.calculation = data;
        state.loadingCalculation = false;
    },
    solarSimpleCalculationErr(state, err) {
        state.loadingCalculation = false;
        state.errorCalculation = err;
    },
    solarSimpleCalculationOrderBegin(state) {
        state.loadingCalculationOrder = true;
        state.calculationOrder = null;
        state.calculation = null;
        state.errorCalculationOrder = false;
    },
    solarSimpleCalculationOrderSuccess(state, data) {
        state.calculationOrder = data;
        state.loadingCalculationOrder = false;
    },
    solarSimpleCalculationOrderErr(state, err) {
        state.loadingCalculationOrder = false;
        state.errorCalculationOrder = err;
    },
};
