export default [
    {
        name: 'order',
        path: '/order',
        component: () => import(/* webpackChunkName: "order" */ '@/view/orders/OrderList.vue'),
        meta: {
            action: 'index',
            resource: 'order',
        },
    },
    {
        name: 'orderEdit',
        path: 'order/:id',
        component: () => import(/* webpackChunkName: "orderEdit" */ '@/view/orders/OrderEdit.vue'),
        meta: {
            action: 'view',
            resource: 'order',
        },
    },
    {
        path: '/order/flow',
        name: 'orderFlow',
        component: () => import(/* webpackChunkName: "orderFlow" */ '@/view/orders/OrderFlow.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/energy/create',
        name: 'orderCreateEnergy',
        component: () => import(/* webpackChunkName: "orderCreateEnergy" */ '@/view/orders/OrderCreateEnergy.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/lead/create/:productID',
        name: 'orderCreateLead',
        component: () => import(/* webpackChunkName: "orderCreateEnergy" */ '@/view/orders/OrderCreateLead.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/energy/reoffer',
        name: 'orderReOfferEnergy',
        component: () => import(/* webpackChunkName: "orderReOfferEnergy" */ '@/view/orders/OrderReOfferEnergy.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/svm/create',
        name: 'orderCreateSvm',
        component: () => import(/* webpackChunkName: "orderCreateSvm" */ '@/view/orders/OrderCreateSvm.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/solar-simple/create',
        name: 'orderCreateSolarSimple',
        component: () => import(/* webpackChunkName: "orderCreateSolarSimple" */ '@/view/orders/OrderCreateSolarSimple.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/solar/create',
        name: 'orderCreateSolar',
        component: () => import(/* webpackChunkName: "orderCreateSolar" */ '@/view/orders/OrderCreateSolar.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/shop/create/:productID',
        name: 'OrderCreateShop',
        component: () => import(/* webpackChunkName: "OrderCreateShop" */ '@/view/orders/OrderCreateShop.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
    {
        path: '/order/hydromx/create',
        name: 'orderCreateHydromx',
        component: () => import(/* webpackChunkName: "orderCreateSvm" */ '@/view/orders/OrderCreateHydromx.vue'),
        meta: {
            action: 'create',
            resource: 'order',
        },
    },
]
