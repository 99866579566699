const lineAtAngle = (x1, y1, length, angle, canvas, drawArrow) => {
    canvas.moveTo(x1, y1);
    const radians = angle * (Math.PI / 180);
    const x2 = x1 + Math.cos(radians) * length;
    const y2 = y1 + Math.sin(radians) * length;
    canvas.lineTo(x2, y2);
    canvas.stroke();

    if (drawArrow) {
        let endRadians = Math.atan((y2 - y1) / (x2 - x1));
        endRadians += ((x2 > x1) ? 90 : -90) * Math.PI / 180;
        drawArrowhead(canvas, x2, y2, endRadians);
    }
}

const drawArrowhead = (ctx, x, y, radians) => {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.rotate(radians);
    ctx.moveTo(0, 0);
    ctx.lineTo(3, 5);
    ctx.lineTo(-3, 5);
    ctx.closePath();
    ctx.restore();
    ctx.fill();
}

const bearing = (latlng1, latlng2) => {
    const rad = Math.PI / 180,
        lat1 = latlng1.lat * rad,
        lat2 = latlng2.lat * rad,
        lon1 = latlng1.lng * rad,
        lon2 = latlng2.lng * rad,
        y = Math.sin(lon2 - lon1) * Math.cos(lat2),
        x = Math.cos(lat1) * Math.sin(lat2) -
            Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

    const bearing = ((Math.atan2(y, x) * 180 / Math.PI) + 360) % 360;
    return bearing >= 180 ? bearing - 360 : bearing;
}

const isClockwise = (points) => {
    const edges = [];

    for (let i = 0; i < points.length - 1; i++) {
        let x1 = points[i][1];
        let y1 = points[i][0];
        let x2 = points[i + 1][1];
        let y2 = points[i + 1][0];
        edges[i] = (x2 - x1) * (y2 + y1);
    }

    const sum = edges.reduce(function (a, b) {
        return a + b;
    }, 0);

    if (sum < 0) {
        return true;
    } else {
        return false;
    }
}

const calculateInclinationY2 = (inclination) => {
    return 39 - ((39 * 89) * inclination);
}

const calculateInclinationX2 = (inclination) => {
    const y2 = calculateInclinationY2(inclination) - 6;
    return Math.sqrt(1369 + (y2 * y2))
}

const getOrientationOfEdge = (edge, points) => {
    let orientation = bearing(edge[0], edge[1]);
    let clockwise = isClockwise(points);

    if (clockwise) {
        orientation = orientation + 90;
    } else {
        orientation = orientation - 90;
    }

    if (orientation > 180) {
        orientation = orientation - 360;
    }

    orientation = Math.round(orientation);

    return parseInt(orientation);
}

function calculateArea(latLngs) {
    const pointsCount = latLngs.length;
    let area = 0.0;
    const d2r = Math.PI / 180;
    let p1 = null;
    let p2 = null;

    if (pointsCount > 2) {
        for (let i = 0; i < pointsCount; i++) {
            p1 = latLngs[i];
            p2 = latLngs[(i + 1) % pointsCount];
            area += ((p2.lng - p1.lng) * d2r) *
                (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
        }
        area = area * 6378137.0 * 6378137.0 / 2.0;
    }

    return Math.abs(area);
}

const getLayerColor = (roofpotential) => {
    return roofpotential === 10 ? '#00c853' : roofpotential === 9 ? '#aeea00' : roofpotential === 8 ? '#fdd835' : roofpotential === 7 ? '#ffab00' : roofpotential === 6 ? '#ff6d00' : roofpotential === '&#10003' ? '#1fcdff' : '#dd2c00';
}

const getPanelAttribute = (data, type, attrName) => {
    const filteredData = Object.values(data).find(attr => attr.type && attr.type.toUpperCase() === type.toUpperCase());
    return filteredData[attrName]
}

export {
    lineAtAngle,
    bearing,
    isClockwise,
    calculateInclinationY2,
    calculateInclinationX2,
    getOrientationOfEdge,
    calculateArea,
    getLayerColor,
    getPanelAttribute,
    drawArrowhead,
}

