import mutations from './mutations';
import {notification} from "ant-design-vue";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    locale: [],
});

const actions = {
    /**
     *
     * Get audit logs (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    // eslint-disable-next-line no-empty-pattern
    async setLocale({},code) {
        try {
            const url = `../static/${code}.json`
            fetch(url, {mode: 'no-cors'})
                .then(response => response.json())
                .catch(error => console.error(error));
        } catch (err) {
            addNotificationError(err);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
