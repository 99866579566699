import {notification} from "ant-design-vue";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};


const actions = {
    /**
     *
     */
    fileUploadedSuccess() {
        addNotificationSuccess('Bestand succesvol geüpload');
    },
    /**
     *
     */
    fileUploadedError() {
        addNotificationError('Bestand uploaden mislukt.');
    },
};

export default {
    namespaced: false,
    actions,
};
