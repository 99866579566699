export default {
    supplierDataBegin(state) {
        state.supplier = {
            city: null,
            cocNumber: null,
            country: null,
            createTimestamp: null,
            deletedTimestamp: null,
            description: null,
            displayName: null,
            email: null,
            externalReference: null,
            houseNumber: null,
            houseNumberAddition: null,
            id: null,
            imageFile: null,
            imageFileURL: '',
            modifiedTimestamp: null,
            name: null,
            phoneNumber: null,
            postalCode: null,
            street: null,
            website: null,
        };
        state.errorSupplier = false;
    },
    supplierDataSuccess(state, data) {
        state.errorSupplier = false;
        state.supplier = data;
    },
    supplierDataErr(state, err) {
        state.loadingSupplier = false;
        state.errorSupplier = err;
    },
    suppliersDataBegin(state) {
        state.loadingSuppliers = true;
    },
    suppliersDataSuccess(state, data) {
        state.suppliers = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingSuppliers = false;
    },
    suppliersDataErr(state, err) {
        state.loadingSuppliers = false;
        state.errorSuppliers = err;
    },
    supplierDataCreateBegin(state) {
        state.loadingCreateSupplier = true;
    },
    supplierDataCreateSuccess(state) {
        state.loadingCreateSupplier = false;
    },
    supplierDataCreateErr(state, err) {
        state.loadingCreateSupplier = false;
        state.errorCreateSupplier = err;
    },
    supplierDataPatchBegin(state) {
        state.loadingPatchSupplier = true;
    },
    supplierDataPatchSuccess(state) {
        state.loadingPatchSupplier = false;
    },
    supplierDataPatchErr(state, err) {
        state.loadingPatchSupplier = false;
        state.errorPatchSupplier = err;
    },
    supplierDataDeleteBegin(state) {
        state.loadingDeleteSupplier = true;
    },
    supplierDataDeleteSuccess(state) {
        state.loadingDeleteSupplier = false;
    },
    supplierDataDeleteErr(state, err) {
        state.loadingDeleteSupplier = false;
        state.errorDeleteSupplier = err;
    },
    setCurrentPageSupplier(state, page) {
        state.currentPage = page;
    },
};
