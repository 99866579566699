export default {
    substatusDataBegin(state) {
        state.loadingSubStatus = true;
        state.substatus = {
            id: null,
            name: null,
        };
        state.errorSubStatus = false;
    },
    substatusDataSuccess(state, data) {
        state.substatus = data;
        state.loadingSubStatus = false;
    },
    substatusDataErr(state, err) {
        state.loadingSubStatus = false;
        state.errorSubStatus = err;
    },
    substatussesDataBegin(state) {
        state.loadingSubStatusses = true;
    },
    substatussesDataSuccess(state, data) {
        state.substatusses = data.data;
        state.count = data.total;
        state.currentPage = data.current_page;
        state.loadingSubStatusses = false;
    },
    parentstatussesDataSuccess(state, data) {
        state.parentStatusses = data.data;
    },
    substatusPermissionsDataSuccess(state, data) {
      state.allPermissions = data;
    },
    substatussesDataErr(state, err) {
        state.loadingSubStatusses = false;
        state.errorSubStatuss = err;
    },
    substatusDataCreateBegin(state) {
        state.loadingCreateSubStatus = true;
    },
    substatusDataCreateSuccess(state) {
        state.loadingCreateSubStatus = false;
    },
    substatusDataCreateErr(state, err) {
        state.loadingCreateSubStatus = false;
        state.errorCreateSubStatus = err;
    },
    substatusDataPatchBegin(state) {
        state.loadingPatchSubStatus = true;
    },
    substatusDataPatchSuccess(state) {
        state.loadingPatchSubStatus = false;
    },
    substatusDataPatchErr(state, err) {
        state.loadingPatchSubStatus = false;
        state.errorPatchSubStatus = err;
    },
    substatusDataDeleteBegin(state) {
        state.loadingDeleteSubStatus = true;
    },
    substatusDataDeleteSuccess(state) {
        state.loadingDeleteSubStatus = false;
    },
    substatusDataDeleteErr(state, err) {
        state.loadingDeleteSubStatus = false;
        state.errorDeleteSubStatus = err;
    },
    setCurrentPageSubStatus(state, page) {
        state.currentPage = page;
    },
};
