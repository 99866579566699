import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    import: null,
    imports: [],
    loadingImports: false,
    loadingImport: false,
    loadingCreateImport: false,
    loadingPatchImport: false,
    loadingDeleteImport: false,
    errorImports: null,
    errorImport: null,
    errorCreateImport: null,
    errorPatchImport: null,
    errorDeleteImport: null,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get imports
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getImport({commit}, id) {
        try {
            await commit('importDataBegin');
            const response = await DataService.get(`/imports/${id}`);
            await commit('importDataSuccess', response.data);
        } catch (err) {
            await commit('importDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create imports
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createImport({commit, dispatch}, data) {
        try {
            await commit('importDataCreateBegin');
            const response = await DataService.postMultiPart(`/imports`, data.value);
            await commit('importDataCreateSuccess', response.data);
            data.close();
            await dispatch('getImports');
        } catch (err) {
            await commit('importDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update imports
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchImport({commit, dispatch}, data) {
        try {
            await commit('importDataPatchBegin');
            await DataService.patch(`/imports/${data.id}`, data);
            await commit('importDataPatchSuccess');
            await dispatch('getImports');
        } catch (err) {
            await commit('importDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete imports (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteImport({commit, dispatch}, id) {
        try {
            await commit('importDataDeleteBegin');
            await DataService.delete(`/imports/${id}`);
            await commit('importDataDeleteSuccess');
            await dispatch('getImports');
            addNotificationSuccess('De imports is met succes verwijderd.');
        } catch (err) {
            await commit('importDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get imports (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getImports({commit}) {
        try {
            await commit('importsDataBegin');
            const response = await DataService.get('/imports');
            await commit('importsDataSuccess', response.data);
        } catch (err) {
            await commit('importsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageImport({commit}, page) {
        await commit('setCurrentPageImport', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
