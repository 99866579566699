import mutations from './mutations';
import {DataService} from "../../../config/dataService/dataService";

const state = () => ({
    counts: {
        total: {
            relations: 0,
            signedOrders: 0,
            totalOrders: 0
        },
        lastWeek: {
            relations: 0,
            signedOrders: 0,
            totalOrders: 0
        }
    },
    orders: [],
    products: [],
    signConversion: {
        signCount: 0,
        signPercentage: 0,
        totalCount: 0,
    },
    cancelConversion: {
        signCount: 0,
        signPercentage: 0,
        totalCount: 0,
    },
    chart: {
        chartData: {
            gross: [],
            net: [],
        },
        currentMonthCount: {
            gross: 0,
            net: 0,
            cancelled: 0,
        }
    },
    monthlySaleCounts: [],
    activities: [],
    loadingDefaultDashboardCounts: false,
    errorDefaultDashboardCounts: false,
    loadingDefaultDashboardOrders: false,
    errorDefaultDashboardOrders: false,
    loadingDefaultDashboardProducts: false,
    errorDefaultDashboardProducts: false,
    loadingDefaultDashboardSignConversion: false,
    errorDefaultDashboardSignConversion: false,
    loadingDefaultChart: false,
    errorDefaultDashboardChart: false,
    loadingDefaultActivities: false,
    errorDefaultDashboardActivities: false,
    loadingDefaultDashboardCancelConversion: false,
    errorDefaultDashboardCancelConversion: false,
});

const actions = {
    /**
     *
     * Get counts
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardCounts({commit}) {
        try {
            await commit('defaultDashboardCountsBegin');
            const response = await DataService.get('dashboard/default/counts');
            await commit('defaultDashboardCountsSuccess', response.data);
        } catch (err) {
            await commit('defaultDashboardCountsErr', err);
        }
    },
    /**
     *
     * Get orders
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardOrders({commit}) {
        try {
            await commit('defaultDashboardOrdersBegin');
            const response = await DataService.get('dashboard/default/orders');
            await commit('defaultDashboardOrdersSuccess', response.data.data);
        } catch (err) {
            await commit('defaultDashboardOrdersErr', err);
        }
    },
    /**
     *
     * Get orders
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardProducts({commit}) {
        try {
            await commit('defaultDashboardProductsBegin');
            const response = await DataService.get('dashboard/default/products');
            await commit('defaultDashboardProductsSuccess', response.data);
        } catch (err) {
            await commit('defaultDashboardProductsErr', err);
        }
    },
    /**
     *
     * Get signconversion
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardSignConversion({commit}) {
        try {
            await commit('defaultDashboardSignConversionBegin');
            const response = await DataService.get('dashboard/default/signconversion');
            await commit('defaultDashboardSignConversionSuccess', response.data);
        } catch (err) {
            await commit('defaultDashboardSignConversionErr', err);
        }
    },
    /**
     *
     * Get cancelconversion
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardCancelConversion({commit}) {
        try {
            await commit('defaultDashboardCancelConversionBegin');
            const response = await DataService.get('dashboard/default/cancelconversion');
            await commit('defaultDashboardCancelConversionSuccess', response.data);
        } catch (err) {
            await commit('defaultDashboardCancelConversionErr', err);
        }
    },
    /**
     *
     * Get monthlysalecounts
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardMonthlySaleCounts({commit}) {
        try {
            await commit('defaultDashboardMonthlySaleCountsBegin');
            const response = await DataService.get('dashboard/default/monthlysalecounts');
            await commit('defaultDashboardMonthlySaleCountsSuccess', response.data);
        } catch (err) {
            await commit('defaultDashboardMonthlySaleCountsErr', err);
        }
    },
    /**
     *
     * Get monthlysalecounts
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardChart({commit}) {
        try {
            await commit('defaultDashboardChartBegin');
            const response = await DataService.get('dashboard/default/chart');
            await commit('defaultDashboardChartSuccess', response.data);
        } catch (err) {
            await commit('defaultDashboardChartErr', err);
        }
    },
    /**
     *
     * Get activities
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async fetchDefaultDashboardActivities({commit}) {
        try {
            await commit('defaultDashboardActivitiesBegin');
            const response = await DataService.get('dashboard/default/activities');
            await commit('defaultDashboardActivitiesSuccess', response.data.data);
        } catch (err) {
            await commit('defaultDashboardActivitiesErr', err);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
