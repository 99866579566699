<template>
 <ButtonStyled
  :block="block"
  :class="`ant-btn ant-btn-${type} ${shape && `ant-btn-${shape}`} ${block && `ant-btn-block`}`"
  :click="() => load && enterLoading"
  :color="color"
  :data="type"
  :disabled="disabled"
  :ghost="ghost"
  :icon="icon"
  :loading="loading"
  :outlined="outlined"
  :raised="raised"
  :shape="shape"
  :size="size"
  :social="social"
  :squared="squared"
  :transparent="transparented"
  :type="type"
 >
  <slot></slot>
 </ButtonStyled>
</template>
<script>
import VueTypes from 'vue-types';
import {defineComponent} from 'vue';
import {ButtonStyled, ButtonStyledGroup} from "@/components/buttons/styled";

export const Button = defineComponent({
 components: {
  ButtonStyled,
 },
 data() {
  return {
   loading: false,
  };
 },
 methods: {
  enterLoading: function () {
   this.loading = true;
  },
 },
 props: {
  type: VueTypes.oneOf([
   'primary',
   'secondary',
   'success',
   'info',
   'warning',
   'danger',
   'link',
   'dark',
   'light',
   'white',
   'dashed',
   'error',
   'default',
  ]).def('default'),
  shape: VueTypes.string,
  icon: VueTypes.string,
  size: VueTypes.oneOf(['large', 'default', 'small']).def('default'),
  color: VueTypes.string,
  outlined: VueTypes.bool.def(false),
  block: VueTypes.bool.def(false),
  disabled: VueTypes.bool.def(false),
  transparented: VueTypes.bool.def(false),
  raised: VueTypes.bool.def(false),
  squared: VueTypes.bool.def(false),
  social: VueTypes.bool.def(false),
  load: VueTypes.bool.def(false),
  ghost: VueTypes.bool.def(false),
 },
});

export const BtnGroup = {
 components: {
  ButtonStyledGroup,
 },

 render() {
  return <ButtonStyledGroup>{this.$slots.default()}</ButtonStyledGroup>;
 },
};
export default Button;
</script>
