import equals from 'validator/lib/equals';
import moment from "moment";
import Echo from "laravel-echo";
import {notification} from "ant-design-vue";
import {API_ENDPOINT, API_WSHOST, API_WSKEY} from "@/config/dataService/dataService";

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
    return `${text.split(' ').slice(0, size).join(' ')}...`;
};

/**
 *
 * @returns {string} generated password
 */
const generatePassword = () => {
    let CharacterSet = '';
    let password = '';
    const size = 12;
    const charactersArray = 'a-z,A-Z,0-9,#'.split(',');

    if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
    }
    if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
    }
    if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#<>@|+=?S';
    }
    for (let i = 0; i < size; i += 1) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
    }

    return password;
};

/**
 *
 * @param {object} object
 * @returns {boolean} true/false is an object
 */
const isObject = obj => typeof obj === 'object' && obj !== null;

const removeFromArray = data => {
    const dataArray = data.array;
    dataArray.forEach((element, index) => {
        if (element[data.key] === data.value) dataArray.splice(index, 1);
    })

    return dataArray;
}

/**
 *
 * @param {data} as relation object
 * @returns {string} text fullname
 */
const getFullName = data => {
    let fullName = '';

    if (data) {
        if (data.middleName && data.middleName.length > 0) {
            fullName = `${data.firstName} ${data.middleName} ${data.lastName}`;
        } else {
            fullName = `${data.firstName} ${data.lastName}`;
        }
    }
    return fullName;
}

/**
 *
 * @param years
 * @returns {Date}
 */
const todayPlusYears = years => {
    const date = new Date()
    date.setFullYear(date.getFullYear() + years)
    return date
}

/**
 *
 * @param years
 * @returns {`${number}-${number}-${number}`}
 */
const todayMinYears = years => {
    const date = new Date()
    date.setFullYear(date.getFullYear() - years)

    return date
}
/**
 *
 * @param years
 * @returns {Date}
 */
const todayPlusDays = days => {
    const date = new Date()
    moment(date, "DD-MM-YYYY").add(days, 'days');
    return date
}
/**
 *
 * @param years
 * @returns {Date}
 */
const datePlusDays = (date) => {
    const d = moment(date, "YYYY-MM-DD").toDate();
    const dd = d.getDate();
    const mm = d.getMonth() + 1;
    const yyyy = d.getFullYear();

    return `${yyyy}-${mm}-${dd}`;
}

/**
 *
 * @param years
 * @returns {`${number}-${number}-${number}`}
 */
const todayMinDays = days => {
    const date = new Date()
    moment(date, "DD-MM-YYYY").min(days, 'days');

    return date
}

/**
 *
 * @param years
 * @returns {`${number}-${number}-${number}`}
 */
const confirmPassword = (password, confirmPassword) => {
    return equals(password, confirmPassword)
}

const authBroadcasting = () => {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: API_WSKEY,
        cluster: "mt1",
        forceTLS: false,
        wsHost: API_WSHOST,
        wsPort: "443",
        authEndpoint: `${API_ENDPOINT}broadcasting/auth`,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        auth: {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                Accept: "application/json",
            }
        },
    });
}

const triggerOrderSignNotification = (data) => {
    const url = "/order/" + data.id
    notification.success({
        message: (
            <div>
                Order <a href={url}>{data.id}</a> is getekend door {data.relationFullName}.
            </div>
        ),
        duration: 8,
    });
};

const triggerGeneralNotificationSuccess = (data) => {
    if (data.type === 'warning') {
        notification.warning({
            message: data.text,
        });
    }

    if (data.type === 'error') {
        notification.error({
            message: data.text,
        });
    }

    if (data.type === 'success') {
        notification.success({
            message: data.text,
        });
    }
};

const kFormatter = (num) => {
    if (isNaN(num)) {
        return 0;
    } else {
        return (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)
    }
}

const getRoofPotentialInfo = (score) => {
    let roofPotential = {
        potential: '',
        irradiationLoss: ''
    };
    switch (score.toString()) {
        case '10':
            roofPotential.potential = 'Uitstekend';
            roofPotential.irradiationLoss = '0 - 4,99%';
            break;
        case '9':
            roofPotential.potential = 'Zeer goed';
            roofPotential.irradiationLoss = '5 - 9,99%';
            break;
        case '8':
            roofPotential.potential = 'Goed';
            roofPotential.irradiationLoss = '10 - 19,99%';
            break;
        case '7':
            roofPotential.potential = 'Gemiddeld';
            roofPotential.irradiationLoss = '20 - 39,99%';
            break;
        case '6':
            roofPotential.potential = 'Voldoende';
            roofPotential.irradiationLoss = '40 - 64,99%';
            break;
        default:
            roofPotential.potential = 'Matig';
            roofPotential.irradiationLoss = '65 - 100%';
    }

    return roofPotential;
};

const getEnergyLabelColor = (label) => {
    let color = 'darkgrey';
    switch (label) {
        case 'A':
            color = '#2b6539';
            break;
        case 'B':
            color = '#3d8a42';
            break;
        case 'C':
            color = '#9cbf43';
            break;
        case 'D':
            color = '#fbec4e';
            break;
        case 'E':
            color = '#edb54e';
            break;
        case 'F':
            color = '#d9632c';
            break;
        case 'G':
            color = '#ac2b2b';
            break;
        default:
            color = 'darkgrey';
            break;
    }

    return color;
}

const formatterShadowLossText = (value) => {
    let text = ''
    switch (value.toString()) {
        case '1':
            text = 'Beetje schaduw';
            break;
        case '2':
            text = 'Schaduw';
            break;
        default:
            text = 'Geen schaduw';
    }
    return text;
}

const getLeadStatus = (label) => {
    switch (label) {
        case 'NEW':
            return 'Nieuw';
        case 'PLANNED':
            return 'Gepland';
        case 'SALE':
            return 'Sale';
        case 'NOTINTERESTED':
            return 'Geen interesse';
        case 'NOTHOME':
            return 'Niet aanwezig';
        case 'CANCELLED':
            return 'Geannuleerd';
        default:
            return 'Nieuw';
    }
}

export {
    getLeadStatus,
    triggerGeneralNotificationSuccess,
    triggerOrderSignNotification,
    authBroadcasting,
    ellipsis,
    generatePassword,
    isObject,
    removeFromArray,
    getFullName,
    todayPlusYears,
    todayMinYears,
    confirmPassword,
    todayPlusDays,
    todayMinDays,
    kFormatter,
    getRoofPotentialInfo,
    getEnergyLabelColor,
    formatterShadowLossText,
    datePlusDays,
};
