export default {
    sendDataBegin(state) {
        state.loadingSend = true;
        state.errorSend = false;
    },
    sendDataSuccess(state,) {
        state.loadingSend = false;
    },
    sendDataErr(state, err) {
        state.loadingSend = false;
        state.errorSend = err;
    },
};
