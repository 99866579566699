import {createRouter, createWebHistory} from 'vue-router';
import adminRoutes from './AdminRoutes';
import authRoutes from './AuthRoutes';
import store from '@/vuex/store';
// import {canNavigate} from "../core/plugins/acl/routeProtection";
// import ability from "../core/plugins/acl/ability";
// import {canNavigate} from "../core/plugins/acl/routeProtection";

const routes = [
    {
        name: 'Admin',
        path: '/',
        component: () => import(/* webpackChunkName: "admin" */ '@/layout/withAdminLayout.vue'),
        children: [...adminRoutes],
        meta: {
            auth: false,
            action: 'read',
            resource: 'admin',
        },
    },
    {
        name: 'Auth',
        path: '/auth',
        component: () => import(/* webpackChunkName: "auth" */ '@/layout/withAuthLayout.vue'),
        children: [...authRoutes],
        meta: {auth: true},
    },
];

const router = createRouter({
    history: createWebHistory(
        process.env.NODE_ENV === 'production'
            ? process.env.VUE_APP_SUB_ROUTE
                ? process.env.VUE_APP_SUB_ROUTE
                : process.env.BASE_URL
            : process.env.BASE_URL,
    ),
    linkExactActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
    if ((to.meta.auth && store.state.auth.isLoggedIn) || (!to.meta.auth && store.state.auth.isLoggedIn && to.path === '/')) {
        next({path: '/dashboard'});
    } else if (!to.meta.auth && !store.state.auth.isLoggedIn) {
        next({name: 'login'});
    } else {
        next();
    }
});

export default router;
