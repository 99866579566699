import ConfigurationRoutes from "./ConfigurationRoutes";
import OrdersRoutes from "./OrdersRoutes";
import ProductRoutes from "./ProductRoutes";
import CrmRoutes from "./CrmRoutes";
import DataRoutes from "./DataRoutes";
import LogRoutes from "./LogRoutes";
import Communication from "./communication";

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/view/dashboard/Sales.vue'),
        meta: {
            action: 'index',
            resource: 'dashboard',
        },
    },
    ...ConfigurationRoutes,
    ...OrdersRoutes,
    ...ProductRoutes,
    ...CrmRoutes,
    ...DataRoutes,
    ...LogRoutes,
    ...Communication,
];

export default routes;
