import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    eans:[],
    loading: false,
    error: null,
});

const actions = {
    /**
     *
     * Get relation (depending on acl)
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async searchEdsn({commit,dispatch}, data) {
        try {
            await commit('dataBeginEdsn');
            const response = await DataService.get('/search/edsn', data);
            await dispatch('addEans', response.data)
            await commit('dataSuccessEdsn');
        } catch (err) {
            await commit('dataErrEdsn', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
