import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    profile: {
        imageFileURL: '',
    },
    loadingProfile: false,
    loadingPatchProfile: false,
    errorProfiles: null,
    errorProfile: null,
    errorPatchProfile: null,
});

const actions = {
    /**
     *
     * Get profile
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getProfile({commit}) {
        try {
            await commit('profileDataBegin');
            const response = await DataService.get('/auth/me');
            await commit('profileDataSuccess', response.data);
        } catch (err) {
            await commit('profileDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
    /**
     *
     * Update profile
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchProfile({commit, dispatch}, data) {
        try {
            await commit('profileDataPatchBegin');
            await DataService.patch(`/profiles/${data.id}`, data);
            await commit('profileDataPatchSuccess');
            await dispatch('getProfiles');
        } catch (err) {
            await commit('profileDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
