export default {
    roleDataBegin(state) {
        state.loadingRole = true;
        state.role = {
            id: null,
            name: null,
            userScope: [],
            allowedRoleIDs: [],
            hasDayLimit: false,
            hasTimeLimit: false,
            allowedTimeFrom: '',
            allowedTimeTill: '',
            allowedDays: [],
        };
        state.errorRole = false;
    },
    roleDataSuccess(state, data) {
        data.userScope = "SELF";
        data.orderScope = "SELF";
        data.scopes.forEach((item) => {
            if (item.subject === 'order') {
                data.orderScope = item.scope;
            }
            if (item.subject === 'user') {
                data.userScope = item.scope;
            }
        });
        data.allowedRoleIDs = data.allowedRoleIDs ? data.allowedRoleIDs : [];
        state.role = data;
        state.loadingRole = false;
    },
    roleDataErr(state, err) {
        state.loadingRole = false;
        state.errorRole = err;
    },
    rolesDataBegin(state) {
        state.loadingRoles = true;
    },
    rolesDataSuccess(state, data) {
        state.roles = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingRoles = false;
    },
    rolePermissionsDataSuccess(state, data) {
        state.allPermissions = data;
    },
    rolesDataErr(state, err) {
        state.loadingRoles = false;
        state.errorRoles = err;
    },
    roleDataCreateBegin(state) {
        state.loadingCreateRole = true;
    },
    roleDataCreateSuccess(state) {
        state.loadingCreateRole = false;
        state.showEdit = false;
    },
    roleDataCreateErr(state, err) {
        state.loadingCreateRole = false;
        state.errorCreateRole = err;
    },
    roleDataPatchBegin(state) {
        state.loadingPatchRole = true;
    },
    roleDataPatchSuccess(state) {
        state.loadingPatchRole = false;
    },
    roleDataPatchErr(state, err) {
        state.loadingPatchRole = false;
        state.errorPatchRole = err;
    },
    roleDataDeleteBegin(state) {
        state.loadingDeleteRole = true;
    },
    roleDataDeleteSuccess(state) {
        state.loadingDeleteRole = false;
    },
    roleDataDeleteErr(state, err) {
        state.loadingDeleteRole = false;
        state.errorDeleteRole = err;
    },
    setCurrentPageRole(state, page) {
        state.currentPage = page;
    },
};
