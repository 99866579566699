import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    scheduledTask: {
        id: null,
        name: null,
        descheduledTaskion: '',
    },
    scheduledTasks: [],
    loadingScheduledTasks: false,
    loadingScheduledTask: false,
    loadingCreateScheduledTask: false,
    loadingPatchScheduledTask: false,
    loadingDeleteScheduledTask: false,
    loadingRunScheduledTask: false,
    errorScheduledTasks: false,
    errorScheduledTask: false,
    errorCreateScheduledTask: false,
    errorPatchScheduledTask: false,
    errorDeleteScheduledTask: false,
    errorRunScheduledTask: false,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get scheduledTask
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getScheduledTask({commit}, id) {
        try {
            await commit('scheduledTaskDataBegin');
            const response = await DataService.get(`/scheduledtasks/${id}`);
            await commit('scheduledTaskDataSuccess', response.data);
        } catch (err) {
            await commit('scheduledTaskDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create scheduledTask
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createScheduledTask({commit, dispatch}, data) {
        try {
            await commit('scheduledTaskDataCreateBegin');
            const response = await DataService.post(`/scheduledtasks`, data.value);
            await commit('scheduledTaskDataCreateSuccess', response.data);
            await dispatch('getScheduledTasks');
            data.close();
        } catch (err) {
            await commit('scheduledTaskDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update scheduledTask
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchScheduledTask({commit}, data) {
        try {
            await commit('scheduledTaskDataPatchBegin');
            if (data.parameters !== '')
                data.parameters = JSON.parse(data.parameters);
            await DataService.patch(`/scheduledtasks/${data.id}`, data);
            await commit('scheduledTaskDataPatchSuccess');
            addNotificationSuccess('De systeemtaak is met succes aangepast.');
        } catch (err) {
            await commit('scheduledTaskDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete scheduledTask (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteScheduledTask({commit, dispatch}, id) {
        try {
            await commit('scheduledTaskDataDeleteBegin');
            await DataService.delete(`/scheduledtasks/${id}`);
            await commit('scheduledTaskDataDeleteSuccess');
            await dispatch('getScheduledTasks');
            addNotificationSuccess('De scheduledTask is met succes verwijderd.');
        } catch (err) {
            await commit('scheduledTaskDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Run scheduledTask
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async runScheduledTask({commit, dispatch}, id) {
        try {
            await commit('scheduledTaskDataRunBegin');
            await DataService.post(`/scheduledtasks/${id}/run`);
            await commit('scheduledTaskDataRunSuccess');
            await dispatch('getScheduledTasks');
            addNotificationSuccess('De taakexecute is toegevoegd aan de wachtrij.');
        } catch (err) {
            await commit('scheduledTaskDataRunErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get scheduledTasks (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getScheduledTasks({commit}, data) {
        try {
            await commit('scheduledTasksDataBegin');
            const response = await DataService.get('/scheduledtasks', data);
            await commit('scheduledTasksDataSuccess', response.data);
        } catch (err) {
            await commit('scheduledTasksDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageScheduledTask({commit}, page) {
        await commit('setCurrentPageScheduledTask', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
