import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    role: {
        id: null,
        name: null,
        userScope: [],
        allowedRoleIDs: [],
        hasDayLimit: false,
        hasTimeLimit: false,
        allowedTimeFrom: '',
        allowedTimeTill: '',
        allowedDays: [],
        hasOrderViewDayLimit: false,
        orderViewLimitDays: null,
        hasUserLoginInactivityLimit: false,
        userLoginInactivityDays: null,
    },
    allPermissions: [],
    roles: [],
    loadingRoles: false,
    loadingRole: false,
    loadingCreateRole: false,
    loadingPatchRole: false,
    loadingDeleteRole: false,
    errorRoles: false,
    errorRole: false,
    errorCreateRole: false,
    errorPatchRole: false,
    errorDeleteRole: false,
    count: 0,
    currentPage: 1,
    pageSize: 10,
});

const actions = {
    /**
     *
     * Get role
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getRole({commit}, id) {
        try {
            await commit('roleDataBegin');
            const response = await DataService.get(`/roles/${id}`);
            await commit('roleDataSuccess', response.data);
        } catch (err) {
            await commit('roleDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create role
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createRole({commit, dispatch}, data) {
        try {
            await commit('roleDataCreateBegin');
            const response = await DataService.post(`/roles`, data.value);
            await commit('roleDataCreateSuccess', response.data);
            await dispatch('getRoles');
            data.close();
        } catch (err) {
            await commit('roleDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update role
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchRole({commit, dispatch}, data) {
        try {
            await commit('roleDataPatchBegin');
            await DataService.patch(`/roles/${data.id}`, data);
            await commit('roleDataPatchSuccess');
            await dispatch('getRoles');
            addNotificationSuccess('De wijzigingen zijn doorgevoerd.');
        } catch (err) {
            await commit('roleDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete role (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteRole({commit, dispatch}, id) {
        try {
            await commit('roleDataDeleteBegin');
            await DataService.delete(`/roles/${id}`);
            await commit('roleDataDeleteSuccess');
            await dispatch('getRoles');
            addNotificationSuccess('De role is met succes verwijderd.');
        } catch (err) {
            await commit('roleDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get roles (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getRoles({commit}, data) {
        try {
            await commit('rolesDataBegin');
            const response = await DataService.get('/roles', data);
            await commit('rolesDataSuccess', response.data);
        } catch (err) {
            await commit('rolesDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get all permissions (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getRolePermissions({commit}, data) {
        try {
            // await commit('roleDataBegin');
            const response = await DataService.get('/roles/allpermissions', data);
            await commit('rolePermissionsDataSuccess', response.data);
        } catch (err) {
            await commit('roleDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageRole({commit}, page) {
        await commit('setCurrentPageRole', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
