export default {
    productCommunicationsFiltersBegin(state) {
        state.loadingProductCommunicationsFilters = true;
        state.errorProductCommunicationsFilters = false;
    },
    productCommunicationsFiltersSuccess(state, data) {
        state.productCommunicationsFilters = data;
        state.loadingProductCommunicationsFilters = false;
    },
    productCommunicationsFiltersErr(state, err) {
        state.loadingProductCommunicationsFilters = false;
        state.errorProductCommunicationsFilters = err;
    },
    userOrganizationFiltersBegin(state) {
        state.loadingUserOrganizationFilters = true;
        state.errorUserOrganizationFilters = false;
    },
    userOrganizationFiltersSuccess(state, data) {
        state.userOrganization = data;
        state.loadingUserOrganizationFilters = false;
    },
    userOrganizationFiltersErr(state, err) {
        state.loadingUserOrganizationFilters = false;
        state.errorUserOrganizationFilters = err;
    },
    orderOrganizationFiltersBegin(state) {
        state.loadingOrderOrganizationFilters = true;
        state.errorOrderOrganizationFilters = false;
    },
    orderOrganizationFiltersSuccess(state, data) {
        state.orderOrganization = data;
        state.loadingOrderOrganizationFilters = false;
    },
    orderOrganizationFiltersErr(state, err) {
        state.loadingOrderOrganizationFilters = false;
        state.errorOrderOrganizationFilters = err;
    },
    orderStatusFiltersBegin(state) {
        state.loadingOrderStatusFilters = true;
        state.errorOrderStatusFilters = false;
    },
    orderStatusFiltersSuccess(state, data) {
        state.orderStatus = data;
        state.loadingOrderStatusFilters = false;
    },
    orderStatusFiltersErr(state, err) {
        state.loadingOrderStatusFilters = false;
        state.errorOrderStatusFilters = err;
    },
    orderProductFiltersBegin(state) {
        state.loadingProductFilters = true;
        state.errorProductFilters = false;
    },
    orderProductFiltersSuccess(state, data) {
        state.productFilters = data;
        state.loadingProductFilters = false;
    },
    orderProductFiltersErr(state, err) {
        state.loadingProductFilters = false;
        state.errorProductFilters = err;
    },
    orderTeamFiltersBegin(state) {
        state.loadingOrderTeamsFilters = true;
        state.errorOrderTeamsFilters = false;
    },
    orderTeamFiltersSuccess(state, data) {
        state.orderTeams = data;
        state.loadingOrderTeamsFilters = false;
    },
    orderTeamFiltersErr(state, err) {
        state.loadingOrderTeamsFilters = false;
        state.errorOrderTeamsFilters = err;
    },
    orderRolesFiltersBegin(state) {
        state.loadingOrderRolesFilters = true;
        state.errorOrderRolesFilters = false;
    },
    orderRolesFiltersSuccess(state, data) {
        state.roleFilters = data;
        state.loadingOrderRolesFilters = false;
    },
    orderRolesFiltersErr(state, err) {
        state.loadingOrderRolesFilters = false;
        state.errorOrderRolesFilters = err;
    },
    userFiltersBegin(state) {
        state.loadingUserFilters = true;
        state.errorUserFilters = false;
    },
    userFiltersSuccess(state, data) {
        state.userFilters = JSON.parse(JSON.stringify(state.userFilters));
        state.userFilters = data;
        state.loadingUserFilters = false;
    },
    userFiltersErr(state, err) {
        state.loadingUserFilters = false;
        state.errorUserFilters = err;
    },
};
