export default {
    fetchSolarSimpleProductsBegin(state) {
        state.errorFetchSolarSimpleProducts = false;
        state.loadingFetchSolarSimpleProducts = true;
    },
    fetchSolarSimpleProductsSuccess(state, data) {
        state.products = data;
        state.loadingFetchSolarSimpleProducts = false;
    },
    fetchSolarSimpleProductsErr(state, err) {
        state.loadingFetchSolarSimpleProducts = false;
        state.errorFetchSolarSimpleProducts = err;
    },
    preFillRelationOnSolarSimpleOrderBegin(state) {
        state.errorPreFillRelationOnSolarSimpleOrder = false;
        state.loadingPreFillRelationOnSolarSimpleOrder = true;
    },
    preFillRelationOnSolarSimpleOrderSuccess(state) {
        state.loadingPreFillRelationOnSolarSimpleOrder = false;
    },
    preFillRelationOnSolarSimpleOrderErr(state, err) {
        state.loadingPreFillRelationOnSolarSimpleOrder = false;
        state.errorPreFillRelationOnSolarSimpleOrder = err;
    },
    setOrderFlowSolarSimple(state, data) {
        const order = state.order;
        order.signatureType = data.flow;
        order.type = data.config.type;
        state.order = order;
    },
    addRelationToSolarSimpleOrder(state, data) {
        let order = state.order;

        if (data.type === 'CONSUMER') {
            data.companyName = null;
            data.companyCocNumber = null;
        }

        order.relation = data;
        if (data.id !== undefined) {
            order.relationID = data.id;
        }

        state.order = order;
    },
    setSignatureSolarSimpleData(state, data) {
        const order = state.order;
        order.signatureData = data;
        state.order = order;
    },
    resetSolarSimpleOrder(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };

        order.relationID = null;
        order.type = 'SOLARSIMPLE';
        order.signatureType = 'ESIGNATURE';
        order.signatureData = null;
        order.flowQuestions = [];
        order.panelCount = null;
        order.usageElectricity = null;
        state.order = order;
    },
    solarSimpleOrderCreateBegin(state) {
        state.loadingCreateOrder = true;
        state.errorCreateOrder = false;
    },
    solarSimpleOrderCreateSuccess(state) {
        state.loadingCreateOrder = false;
    },
    solarSimpleOrderCreateErr(state, err) {
        state.loadingCreateOrder = false;
        state.errorCreateOrder = err;
    },
    setProductOnSolarSimpleOrder(state, data) {
        let order = state.order;
        if (data.item.product.hasFixedPanelCount) {
            order.panelCount = data.item.product.fixedPanelCount
        }
        order.productID = data.item.product.id;
        order.product = data.item.product;
        order.calculation = data.item.calculation;
        order.subProducts = data.item.subProducts;
        for (let i = 0; i < data.item.flowQuestions.length; i++) {
            if (data.item.flowQuestions[i].type === 'PANELCOUNT') {
                data.item.flowQuestions[i].value = order.panelCount;
            }
        }

        order.flowQuestions = data.item.flowQuestions;
        state.order = order;
    },
    setPanelCountOnSolarSimpleOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.panelCount = data;
        state.order = order;
    },
    setElectricityUsageOnSolarSimpleOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.usageElectricity = data;
        state.order = order;
    },
};
