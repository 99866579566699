export default {
    dnsDataBegin(state) {
        state.loadingDns = true;
        state.errorDns = false;
    },
    dnsDataSuccess(state, data) {
        state.dns = data;
        state.loadingDns = false;
    },
    dnsDataErr(state, err) {
        state.loadingDns = false;
        state.errorDns = err;
    },
};
