import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    timeBlocks: [],
    leads: [],
    areas: [],
    lead: {},
    area: {},
    loadingTimeBlocks: false,
    loadingLeads: false,
    loadingAreaCreate: false,
    loadingTimeBlockCreate: false,
    loadingAreas: false,
    loadingArea: false,
    loadingLead: true,
    count: 0,
    currentPage: 1,
    pageSize: 10,
    countArea: 0,
    currentPageArea: 1,
    pageSizeArea: 10,
    countTimeBlocks: 0,
    currentPageTimeBlocks: 1,
    pageSizeTimeBlocks: 10,
});

const actions = {
    async getLeads({commit}) {
        try {
            await commit('leadsDataBegin');
            const response = await DataService.get('leads');
            await commit('leadsDataSuccess', response.data);
        } catch (err) {
            await commit('leadsDataErr');
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getAreas({commit}) {
        try {
            await commit('areasDataBegin');
            const response = await DataService.get('leadareas');
            await commit('areasDataSuccess', response.data);
        } catch (err) {
            await commit('areasDataErr');
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getTimeBlocks({commit}, id) {
        try {
            await commit('timeBlocksDataBegin');
            const response = await DataService.get(`leadareas/${id}/timeblocks`);
            await commit('timeBlocksDataSuccess', response.data);
        } catch (err) {
            await commit('timeBlocksDataErr');
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createTimeBlock({commit, dispatch}, data) {
        try {
            await commit('timeBlockCreateDataBegin');
            const response = await DataService.post(`leadareas/${data.value.id}/timeblocks`, data.value);
            await commit('timeBlockCreateDataSuccess', response.data);
            addNotificationSuccess('Tijdsblok is met succes aangemaakt.');
            await dispatch('getTimeBlocks', data.value.id);
            data.close();
        } catch (err) {
            await commit('timeBlockCreateDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async createArea({commit, dispatch}, data) {
        try {
            await commit('areasCreateDataBegin');
            const response = await DataService.post(`leadareas`, data.value);
            await commit('areasCreateDataSuccess', response.data);
            addNotificationSuccess('Gebied is met succes aangemaakt.');
            await dispatch('getAreas');
            data.close();
        } catch (err) {
            await commit('areasCreateDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getLead({commit}, id) {
        try {
            await commit('leadDataBegin');
            const response = await DataService.get(`/leads/${id}`);
            await commit('leadDataSuccess', response.data);
        } catch (err) {
            await commit('leadDataErr');
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getArea({commit}, id) {
        try {
            await commit('areaDataBegin');
            const response = await DataService.get(`/leadareas/${id}`);
            await commit('areaDataSuccess', response.data);
        } catch (err) {
            await commit('areaDataErr');
            addNotificationError(err?.response?.data?.message);
        }
    },
    async setCurrentPageLeads({commit}, page) {
        await commit('setCurrentPageLeads', page);
    },
    async setCurrentPageAreas({commit}, page) {
        await commit('setCurrentPageLeads', page);
    },
    async setCurrentPageTimeBlocks({commit}, page) {
        await commit('setCurrentPageTimeBlocks', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
