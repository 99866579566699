export default {
    scheduledTaskDataBegin(state) {
        state.loadingScheduledTask = true;
        state.scheduledTask = {
            id: null,
            name: null,
            descheduledTaskion: '',
        };
        state.errorScheduledTask = false;
    },
    scheduledTaskDataSuccess(state, data) {
        data.parameters = JSON.stringify(data.parameters);
        if(data.parameters === 'null')
            data.parameters = ''
        state.scheduledTask = data;
        state.showEdit = true;
        state.loadingScheduledTask = false;
    },
    scheduledTaskDataErr(state, err) {
        state.loadingScheduledTask = false;
        state.errorScheduledTask = err;
    },
    scheduledTasksDataBegin(state) {
        state.loadingScheduledTasks = true;
    },
    scheduledTasksDataSuccess(state, data) {
        state.scheduledTasks = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingScheduledTasks = false;
    },
    scheduledTasksDataErr(state, err) {
        state.loadingScheduledTasks = false;
        state.errorScheduledTasks = err;
    },
    scheduledTaskDataCreateBegin(state) {
        state.loadingCreateScheduledTask = true;
    },
    scheduledTaskDataCreateSuccess(state) {
        state.loadingCreateScheduledTask = false;
        state.showEdit = false;
    },
    scheduledTaskDataCreateErr(state, err) {
        state.loadingCreateScheduledTask = false;
        state.errorCreateScheduledTask = err;
    },
    scheduledTaskDataPatchBegin(state) {
        state.loadingPatchScheduledTask = true;
    },
    scheduledTaskDataPatchSuccess(state) {
        state.loadingPatchScheduledTask = false;
    },
    scheduledTaskDataPatchErr(state, err) {
        state.loadingPatchScheduledTask = false;
        state.errorPatchScheduledTask = err;
    },
    scheduledTaskDataDeleteBegin(state) {
        state.loadingDeleteScheduledTask = true;
    },
    scheduledTaskDataDeleteSuccess(state) {
        state.loadingDeleteScheduledTask = false;
    },
    scheduledTaskDataDeleteErr(state, err) {
        state.loadingDeleteScheduledTask = false;
        state.errorDeleteScheduledTask = err;
    },
    scheduledTaskDataRunBegin(state) {
        state.loadingRunScheduledTask = true;
    },
    scheduledTaskDataRunSuccess(state) {
        state.loadingRunScheduledTask = false;
    },
    scheduledTaskDataRunErr(state, err) {
        state.loadingRunScheduledTask = false;
        state.errorRunScheduledTask = err;
    },
    setCurrentPageScheduledTask(state, page) {
        state.currentPage = page;
    },
};
