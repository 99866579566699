export default [
  {
    path: 'export',
    name: 'export',
    component: () => import(/* webpackChunkName: "exports" */ '@/view/exports/ExportList.vue'),
    meta: {
      action: 'index',
      resource: 'export',
    },
  },
  {
    path: 'import',
    name: 'import',
    component: () => import(/* webpackChunkName: "imports" */ '@/view/imports/ImportList.vue'),
    meta: {
      action: 'index',
      resource: 'import',
    },
  },
]
