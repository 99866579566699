import {getPanelAttribute} from "@/utility/solarUtility";

export default {
    fetchSolarProductsBegin(state) {
        state.errorFetchSolarProducts = false;
        state.loadingFetchSolarProducts = true;
    },
    fetchSolarProductsSuccess(state, data) {
        state.products = data;
        state.loadingFetchSolarProducts = false;
    },
    fetchSolarProductsErr(state, err) {
        state.loadingFetchSolarProducts = false;
        state.errorFetchSolarProducts = err;
    },
    fetchRoofDataBegin(state) {
        state.errorFetchRoofData = false;
        state.loadingFetchRoofData = true;
    },
    fetchRoofDataSuccess(state, data) {
        state.roofData = data;
        state.loadingFetchRoofData = false;
    },
    fetchRoofDataErr(state, err) {
        state.loadingFetchRoofData = false;
        state.errorFetchRoofData = err;
    },
    preFillRelationOnSolarOrderBegin(state) {
        state.errorPreFillRelationOnSolarOrder = false;
        state.loadingPreFillRelationOnSolarOrder = true;
    },
    preFillRelationOnSolarOrderSuccess(state) {
        state.loadingPreFillRelationOnSolarOrder = false;
    },
    preFillRelationOnSolarOrderErr(state, err) {
        state.loadingPreFillRelationOnSolarOrder = false;
        state.errorPreFillRelationOnSolarOrder = err;
    },
    setOrderFlowSolar(state, data) {
        const order = state.order;
        order.signatureType = data.flow;
        order.discountType = data.config.discountType;
        order.allowDiscount = data.config.allowDiscount;
        order.maxDiscountPercentage = data.config.maxDiscountPercentage;
        order.type = data.config.type;
        order.key = Math.random();
        state.order = order;
    },
    addRelationToSolarOrder(state, data) {
        let order = state.order;

        if (data.type === 'CONSUMER') {
            data.companyName = null;
            data.companyCocNumber = null;
        }

        order.relation = data;
        if (data.id !== undefined) {
            order.relationID = data.id;
        }

        state.order = order;
    },
    setSignatureSolarData(state, data) {
        const order = state.order;
        order.signatureData = data;
        state.order = order;
    },
    resetSolarOrder(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.type = 'SOLAR';
        order.signatureType = 'ESIGNATURE';
        order.signatureData = null;
        order.flowQuestions = [];
        order.key = Math.random();
        order.package = null;
        order.satelliteImageFileURL = null;
        state.order = order;
    },
    solarOrderCreateBegin(state) {
        state.loadingCreateOrder = true;
        state.errorCreateOrder = false;
    },
    solarOrderCreateSuccess(state) {
        state.loadingCreateOrder = false;
    },
    solarOrderCreateErr(state, err) {
        state.loadingCreateOrder = false;
        state.errorCreateOrder = err;
    },
    setProductOnSolarOrder(state, data) {
        let order = state.order;
        order.productID = data.product.id;
        order.product = data.product;
        order.flowQuestions = data.flowQuestions;
        order.energyLabel = data.energyLabel;
        order.panelCount = getPanelAttribute(data.calculation, 'panel', 'count');
        order.product.customerApprovalsValidation = new Array(data.product.customerApprovals.length).fill(false);
        order.package = data
        state.order = order;
    },
    setPanelCountOnSolarOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.panelCount = data;
        state.order = order;
    },
    setElectricityUsageOnSolarOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.solarEnergyUsage = data;
        state.order = order;
    },
    addSurfaceToOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        if (!order.surfaces.includes(data)) {
            order.key = Math.random();
            // set default shadow loss
            data.properties.ShadowLoss = 0;
            data.key = Math.random();
            order.surfaces.push(data);
            state.order = order;
        }
    },
    updateSurfaceOnOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        const index = order.surfaces.findIndex(object => {
            return object.key === data.key;
        });

        if (index >= 0) {
            order.surfaces[index].properties.RoofType = parseInt(data.inclination) >= 5 ? "SLANTEDROOF" : "FLATROOF";
            order.surfaces[index].properties.SurfaceType = parseInt(data.inclination) >= 5 ? "Skillion" : "Flat";
            order.surfaces[index].properties.Inclination = parseInt(data.inclination);
            order.key = Math.random();
            state.order = order;
        }
    },
    updateSurfaceOrientationOnOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        let index = -1;
        index = order.surfaces.findIndex((surface) => {
            if (surface.key === data.key) {
                return true;
            }
        });

        if (index >= 0) {
            order.surfaces[index].properties.Orientation = data.Orientation;
            state.order = order;
        }
    },
    removeSurfaceFromOrderByKey(state, key) {
        let order = JSON.parse(JSON.stringify(state.order));
        let index = -1;

        index = order.surfaces.findIndex((surface) => {
            if (surface.key === key) {
                return true;
            }
        });

        if (index > -1) {
            order.key = Math.random();
            order.surfaces.splice(index, 1);
            state.order = order;
        }
    },
    removeSurfaceFromOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        let index = -1;

        index = order.surfaces.findIndex((surface) => {
            if (surface.properties.ID === data.properties.ID) {
                return true;
            }
        });

        if (index > -1) {
            order.key = Math.random();
            order.surfaces.splice(index, 1);
            state.order = order;
        }
    },
    setRoofSurfaceShadowLossOnOrder(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        let loss = 0
        // convert shadow loss to score
        switch (data.loss) {
            case 1:
                loss = 5;
                break;
            case 2:
                loss = 21;
                break;
            default:
                loss = 0;
        }
        order.surfaces[data.index].properties.ShadowLoss = loss
        state.order = order;
    },
    fetchSolarPackagesDataBegin(state) {
        state.loadingFetchSolarPackagesData = true;
        state.errorFetchSolarPackagesDataErr = false;
    },
    fetchSolarPackagesDataSuccess(state, data) {
        let solarPackages = JSON.parse(JSON.stringify(state.solarPackages));
        const order = JSON.parse(JSON.stringify(state.order));
        order.satelliteImageFileURL = data.satelliteImageFileURL;
        order.energyLabel = data.energyLabel;
        solarPackages.key = Math.random();
        solarPackages.data = data.packages;
        solarPackages.maxPanels = parseInt(data.maxPanels);
        solarPackages.minPanels = parseInt(data.minPanels);

        try {
            for (let i = 0; i < solarPackages.data.length; i++) {

                const panelValues = []
                for (let a = parseInt(solarPackages.data[i].minPanels); a <= parseInt(solarPackages.data[i].maxPanels); a++) {
                    panelValues.push({value: a, key: a})
                }
                solarPackages.data[i]['panelValues'] = panelValues;
            }
        } catch (e) {
            console.error(e)
        }

        state.solarPackages = solarPackages;
        state.order = order;
        state.loadingFetchSolarPackagesData = false;
    },
    fetchSolarPackagesDataErr(state, err) {
        state.loadingFetchSolarPackagesData = false;
        state.errorFetchSolarPackagesDataErr = err;
    },
    fetchSolarPackageDataBegin(state) {
        state.loadingFetchSolarPackageData = true;
        state.errorFetchSolarPackageDataErr = false;
    },
    fetchSolarPackageDataSuccess(state, data) {
        const solarPackages = JSON.parse(JSON.stringify(state.solarPackages));
        let index = -1;
        index = solarPackages.data.findIndex((p) => {
            if (p.product.id === data.product.id) {
                return true;
            }
        });

        if (index > -1) {
            solarPackages.data[index] = data;
            solarPackages.key = Math.random();
        }

        state.solarPackages = solarPackages;
        state.loadingFetchSolarPackageData = false;
    },
    fetchSolarPackageDataErr(state, err) {
        state.loadingFetchSolarPackageData = false;
        state.errorFetchSolarPackageDataErr = err;
    },
    fetchSolarPackageAddonsBegin(state) {
        state.loadingFetchSolarPackageAddons = true;
        state.errorFetchSolarPackageAddonsErr = false;
    },
    fetchSolarPackageAddonsSuccess(state, data) {
        const order = JSON.parse(JSON.stringify(state.order));
        const addons = [];
        for (let i = 0; i < data.length; i++) {
            addons.push(
                {
                    id: data[i].id,
                    productID: data[i].productID,
                    name: data[i].name,
                    price: data[i].price,
                    quantity: 0,
                },
            )
        }
        order.key = Math.random();
        order.solarAddonProducts = addons;
        state.order = order;
        state.loadingFetchSolarPackageAddons = false;
    },
    fetchSolarPackageAddonsErr(state, err) {
        state.loadingFetchSolarPackageAddons = false;
        state.errorFetchSolarPackageAddonsErr = err;
    },
    solarSubProductsDataBegin(state) {
        state.errorSolarSubProducts = false;
        state.loadingSolarSubProducts = true;
    },
    solarSubProductsDataSuccess(state, data) {
        let subProducts = JSON.parse(JSON.stringify(state.subProducts));
        subProducts.data = data.data;
        subProducts.key = Math.random();
        state.subProducts = subProducts;
        state.loadingSolarSubProducts = false;
    },
    solarSubProductsDataErr(state, err) {
        state.loadingSolarSubProducts = false;
        state.errorSolarSubProducts = err;
    },
    solarSubProductDataPatchBegin(state) {
        state.loadingPatchSolarSubProduct = true;
        state.errorPatchSolarSubProduct = false;
    },
    solarSubProductDataPatchSuccess(state) {
        state.loadingPatchSolarSubProduct = false;
    },
    solarSubProductDataPatchErr(state, err) {
        state.errorPatchSolarSubProduct = err;
        state.loadingPatchSolarSubProduct = false;
    },
    resetShopSubProduct(state) {
        let subProduct = JSON.parse(JSON.stringify(state.subProduct));
        subProduct = null;
        state.subProduct = subProduct;
    },
    resetSolarOrderSurfaces(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.surfaces = [];
        order.key = Math.random();
        state.order = order;
    },
    addFeatureToRoofData(state, data) {
        const roofData = JSON.parse(JSON.stringify(state.roofData));
        roofData.features.push(data);
        state.roofData = roofData;
    },
    resetSolarRoof(state) {
        state.roofData = null;
    },
    resetSolarProducts(state) {
        state.products = [];
    },
    resetSelectedRoofLayers(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.selectedRoofLayers = [];
        order.key = Math.random();
        state.order = order;
    },
    addSelectedRoofLayers(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.selectedRoofLayers.push(data);
        order.key = Math.random();
        state.order = order;
    },
    removeSelectedRoofLayers(state, index) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.selectedRoofLayers.splice(index, 1);
        order.key = Math.random();
        state.order = order;
    },
    resetSolarProduct(state) {
        let solarPackages = JSON.parse(JSON.stringify(state.solarPackages));
        solarPackages.data = [];
        solarPackages.key = Math.random();
        state.solarPackages = solarPackages;
    },
    resetSolarUsageData(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.usageEstimator = false;
        order.panelCount = null;
        order.roofType = null;
        order.solarEnergyUsage = null;
        order.discount = null;
        order.key = Math.random();
        state.order = order;
    },
    resetSolarRelation(state) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.relation = order.relation = {
            id: null,
            createTimestamp: null,
            modifiedTimestamp: null,
            deletedTimestamp: null,
            isAnonymized: null,
            organizationID: null,
            externalReference: null,
            type: 'CONSUMER',
            isParent: null,
            parentID: null,
            firstName: null,
            middleName: null,
            lastName: null,
            gender: 'MALE',
            birthDate: null,
            phoneNumber: null,
            mobileNumber: null,
            email: null,
            street: null,
            houseNumber: null,
            houseNumberAddition: null,
            postalCode: null,
            city: null,
            country: null,
            companyName: null,
            companyCocNumber: null,
            bankAccountType: null,
            bankAccountNumber: null,
            debtorName: null,
            saveToRevision: false,
        };
        order.relationID = null;
        order.key = Math.random();
        state.order = order;
    },
    fetchOrderSolarDataBegin(state) {
        state.loadingFetchOrderSolarData = true;
        state.errorFetchOrderSolarData = false;
    },
    fetchOrderSolarDataSuccess(state, data) {
        state.solarData = data;
        state.loadingFetchOrderSolarData = false;
    },
    fetchOrderSolarDataErr(state, err) {
        state.loadingFetchOrderSolarData = false;
        state.errorFetchOrderSolarData = err;
    },
    createConfirmOrderBegin(state) {
        state.loadingCreateConfirmOrder = true;
        state.errorCreateConfirmOrder = false;
    },
    createConfirmOrderSuccess(state, data) {
        state.createConfirmOrder = data;
        state.loadingCreateConfirmOrder = false;
    },
    createConfirmOrderErr(state, err) {
        state.loadingCreateConfirmOrder = false;
        state.errorCreateConfirmOrder = err;
    },
    setSolarAddonQuantity(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));

        if (data.type === 'plus') {
            order.solarAddonProducts[data.index].quantity = order.solarAddonProducts[data.index].quantity + 1
        } else if (data.type === 'min' && order.solarAddonProducts[data.index].quantity > 0) {
            order.solarAddonProducts[data.index].quantity = order.solarAddonProducts[data.index].quantity - 1
        }

        order.key = Math.random();
        state.order = order;
    },
    setSolarSatelliteImage(state, data) {
        let order = JSON.parse(JSON.stringify(state.order));
        order.key = Math.random();
        order.solarSatelliteImage = data;
        state.order = order;
    },
};
