export default [
    {
        name: 'relationList',
        path: 'relation',
        component: () => import(/* webpackChunkName: "relation" */ '@/view/relation/RelationList.vue'),
        meta: {
            action: 'index',
            resource: 'relation',
        },
    },
    {
        name: 'relationEdit',
        path: 'relation/:id',
        component: () => import(/* webpackChunkName: "relationEdit" */ '@/view/relation/RelationEdit.vue'),
        meta: {
            action: 'view',
            resource: 'relation',
        },
    },
    {
        name: 'relationAdd',
        path: 'relation/add',
        component: () => import(/* webpackChunkName: "relationAdd" */ '@/view/relation/RelationAdd.vue'),
        meta: {
            action: 'create',
            resource: 'relation',
        },
    },
    {
        name: 'leadList',
        path: 'leads',
        component: () => import(/* webpackChunkName: "relation" */ '@/view/leads/LeadList.vue'),
        meta: {
            action: 'index',
            resource: 'leads',
        },
    },
    {
        name: 'leadAreaList',
        path: 'area',
        component: () => import(/* webpackChunkName: "relation" */ '@/view/leads/LeadAreaList.vue'),
        meta: {
            action: 'index',
            resource: 'leads',
        },
    },
    {
        name: 'leadAreaEdit',
        path: 'area/:id',
        component: () => import(/* webpackChunkName: "relationEdit" */ '@/view/leads/LeadAreaEdit.vue'),
        meta: {
            action: 'view',
            resource: 'leads',
        },
    },
]
