export default {
    trailsDataBegin(state) {
        state.loadingTrails = true;
    },
    trailsDataSuccess(state, data) {
        state.trails = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingTrails = false;
    },
    trailsDataErr(state, err) {
        state.loadingTrails = false;
        state.errorTrails = err;
    },
    setCurrentPageTrail(state, page) {
        state.currentPage = page;
    },
};
