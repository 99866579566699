import {createStore} from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import user from './modules/user/actionCreator';
import headerSearchData from './modules/headerSearch/actionCreator';
import order from './modules/order/actionCreator';
import profile from './modules/profile/actionCreator';
import team from './modules/team/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import product from './modules/product/actionCreator';
import supplier from './modules/supplier/actionCreator';
import organization from './modules/organization/actionCreator';
import svm from './modules/svm/actionCreator';
import relation from './modules/relation/actionCreator';
import role from './modules/role/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import task from './modules/task/actionCreator';
import locale from './modules/locale/actionCreator';
import searchAddress from './modules/searchAddress/actionCreator';
import searchCocNL from './modules/searchCocNL/actionCreator';
import sender from './modules/sender/actionCreator';
import script from './modules/script/actionCreator';
import exports from './modules/exports/actionCreator';
import imports from './modules/imports/actionCreator';
import acl from './modules/acl/actionCreator';
import globalSearch from './modules/globalSearch/actionCreator';
import edsn from './modules/edsn/actionCreator';
import file from './modules/file/actionCreator';
import dns from './modules/dns/actionCreator';
import tariff from './modules/tariff/actionCreator';
import sendCommunication from './modules/sendCommunication/actionCreator';
import flows from './modules/flows/actionCreator';
import question from './modules/question/actionCreator';
import filter from './modules/filter/actionCreator';
import substatus from './modules/substatus/actionCreator';
import trail from './modules/trail/actionCreator';
import solar from './modules/solar/actionCreator';
import solarSimple from './modules/solarSimple/actionCreator';
import solarSimpleTariff from './modules/solarSimpleTariff/actionCreator';
import solarSubProduct from './modules/solarSubProduct/actionCreator';
import SolarSimpleCalculation from './modules/SolarSimpleCalculation/actionCreator';
import shop from './modules/shop/actionCreator';
import dashboard from './modules/dashboard/actionCreator';
import scheduledTask from './modules/scheduledtask/actionCreator';
import hydromx from './modules/hydromx/actionCreator';
import lead from './modules/lead/actionCreator';

export default createStore({
    modules: {
        filter,
        themeLayout,
        headerSearchData,
        user,
        role,
        substatus,
        order,
        profile,
        team,
        auth,
        product,
        supplier,
        organization,
        relation,
        chartContent,
        task,
        locale,
        searchAddress,
        searchCocNL,
        sender,
        script,
        exports,
        imports,
        acl,
        globalSearch,
        edsn,
        file,
        dns,
        tariff,
        sendCommunication,
        flows,
        question,
        svm,
        trail,
        solarSimple,
        solarSimpleTariff,
        solarSubProduct,
        SolarSimpleCalculation,
        dashboard,
        shop,
        solar,
        scheduledTask,
        hydromx,
        lead,
    },
});
