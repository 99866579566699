export default [
    {
        name: 'task',
        path: '/task',
        component: () => import(/* webpackChunkName: "task" */ '@/view/task/Index.vue'),
        children: [
            {
                name: 'all',
                path: 'all',
                components: {
                    default: () => import(/* webpackChunkName: "task" */ '@/view/task/Index.vue'),
                    child: () => import(/* webpackChunkName: "all" */ '@/view/task/overview/all.vue'),
                },
            },
            {
                name: 'favorites',
                path: 'favorites',
                components: {
                    default: () => import(/* webpackChunkName: "task" */ '@/view/task/Index.vue'),
                    child: () => import(/* webpackChunkName: "favorites" */ '@/view/task/overview/favorites.vue'),
                },
            },
            {
                name: 'completed',
                path: 'completed',
                components: {
                    default: () => import(/* webpackChunkName: "task" */ '@/view/task/Index.vue'),
                    child: () => import(/* webpackChunkName: "completed" */ '@/view/task/overview/completed.vue'),
                },
            },
        ],
    },
]
