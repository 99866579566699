import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const addNotificationSuccess = (err) => {
    notification.success({
        message: err,
    });
};

const state = () => ({
    user: {
        id: null,
        createTimestamp: null,
        modifiedTimestamp: null,
        deletedTimestamp: null,
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        username: null,
        password: null,
        organizationID: null,
        roleID: null,
        status: null,
        street: null,
        houseNumber: null,
        houseNumberAddition: null,
        postalCode: null,
        city: null,
        country: null,
        companyName: null,
        companyCocNumber: null,
        externalReference: null,
        bankAccountType: null,
        bankAccountNumber: null,
        teamID: null,
        productTree: [],
        imageFileURL: '',
        additionalAllowedOrganizationIDs: [],
    },
    users: [],
    userFilters: [],
    userAllowedPermissions: null,
    loadingUsers: false,
    loadingUserFilter: false,
    loadingUser: true,
    loadingCreateUser: false,
    loadingPatchUser: false,
    loadingDeleteUser: false,
    loadingUserProductTree: false,
    loadingUserAllowedPermissions: false,
    loadingUserProductTreeConfig: false,
    loadingPatchUserProductTree: false,
    loadingPatchPassword: false,
    errorUsers: false,
    errorUser: false,
    errorCreateUser: false,
    errorPatchUser: false,
    errorDeleteUser: false,
    errorUserAllowedPermissions: false,
    errorUserProductTree: false,
    errorUserProductTreeConfig: false,
    errorPatchUserProductTree: false,
    errorPatchPassword: false,
    errorUserFilter: false,
    count: 0,
    currentPage: 1,
    productTreeConfig: [],
    pageSize: 10,
    filters: {
        organizationID: [],
        roleID: [],
        status: [],
        page: 1,
        pageSize: 10,
    },
    filterValues: {
        organizationID: [],
        roleID: [],
        status: [],
        page: 1,
        pageSize: 10,
    },
});

const actions = {
    /**
     *
     * Get User
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getUser({commit}, id) {
        try {
            await commit('userDataBegin');
            const response = await DataService.get(`/users/${id}`);
            await commit('userDataSuccess', response.data);
        } catch (err) {
            await commit('userDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Create user
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async createUser({commit, dispatch}, data) {
        try {
            await commit('userDataCreateBegin');
            const response = await DataService.post(`/users`, data.value);
            await commit('userDataCreateSuccess', response.data);
            await dispatch('getUsers');
            data.close();
        } catch (err) {
            await commit('userDataCreateErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update user
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchUser({commit, dispatch}, data) {
        try {
            await commit('userDataPatchBegin');
            const response = await DataService.patch(`/users/${data.id}`, data);
            await commit('userDataPatchSuccess', response.data);
            await dispatch('getUsers');
            addNotificationSuccess('De gebruiker is met succes aangepast.');
        } catch (err) {
            await commit('userDataPatchErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Update user
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async patchPassword({commit}, data) {
        try {
            await commit('userDataPatchPasswordBegin');
            await DataService.patch(`/users/${data.value.id}`, data.value);
            await commit('userDataPatchPasswordSuccess');
            data.showModal()
        } catch (err) {
            await commit('userDataPatchPasswordErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Delete user (this is a soft delete)
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async deleteUser({commit, dispatch}, id) {
        try {
            await commit('userDataDeleteBegin');
            const response = await DataService.delete(`/users/${id}`);
            await commit('userDataDeleteSuccess', response.data);
            await dispatch('getUsers');
            addNotificationSuccess('De gebruiker is met succes verwijderd.');
        } catch (err) {
            await commit('userDataDeleteErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get users (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getUsers({state, commit}) {
        try {
            await commit('usersDataBegin');
            const response = await DataService.get('/users', state.filterValues);
            await commit('usersDataSuccess', response.data);
        } catch (err) {
            await commit('usersDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get users (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    // async getUserFilters({commit}, data) {
    //     try {
    //         await commit('userFilterDataBegin');
    //         const response = await DataService.get('/users', data);
    //         await commit('userFilterDataSuccess', response.data);
    //     } catch (err) {
    //         await commit('userFilterDataErr', err);
    //         addNotificationError(err?.response?.data?.message);
    //     }
    // },
    /**
     *
     * Get product tree config
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getUserAllowedProductTree({commit}, id) {
        try {
            await commit('userAllowedProductTreeDataBegin');
            const response = await DataService.get(`/users/${id}/allowedproducttree`);
            await commit('userAllowedProductTreeDataSuccess', response.data);
        } catch (err) {
            await commit('userAllowedProductTreeDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Patch product tree config
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async patchUserAllowedProductTree({commit}, data) {
        try {
            await commit('userPatchAllowedProductTreeDataBegin');
            const response = await DataService.patch(`/users/${data.id}/allowedproducttree`, data.productTree);
            await commit('userPatchAllowedProductTreeDataSuccess', response.data);
            addNotificationSuccess('De product rechten zijn met succes angepast.');
        } catch (err) {
            await commit('userPatchAllowedProductTreeDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * Get product tree config
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getUserProductTreeConfig({commit}, id) {
        try {
            await commit('userProductTreeConfigDataBegin');
            const response = await DataService.get(`/users/${id}/producttree`);
            await commit('userProductTreeConfigDataSuccess', response.data);
        } catch (err) {
            await commit('userProductTreeConfigDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getUserAllowedPermissions({commit}) {
        try {
            await commit('userAllowedPermissionsDataBegin');
            const response = await DataService.get(`/users/allowedpermissions`);
            await commit('userAllowedPermissionsDataSuccess', response.data);
        } catch (err) {
            await commit('userAllowedPermissionsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageUsers({commit}, page) {
        await commit('setCurrentPageUsers', page);
    },
    /**
     *
     * @param commit
     * @param filters
     * @returns {Promise<void>}
     */
    async setUserFilters({commit}, filters) {
        await commit('setUserFilters', filters);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
