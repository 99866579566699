export default [
    {
        path: 'product',
        name: 'product',
        component: () => import(/* webpackChunkName: "product" */ '@/view/product/ProductList.vue'),
        meta: {
            action: 'index',
            resource: 'product',
        },
    },
    {
        path: '/product/:id',
        name: 'productEdit',
        component: () => import(/* webpackChunkName: "productEdit" */ '@/view/product/ProductEdit.vue'),
        meta: {
            action: 'view',
            resource: 'product',
        },
    },
    {
        path: 'question',
        name: 'question',
        component: () => import(/* webpackChunkName: "question" */ '@/view/questions/QuestionList.vue'),
        meta: {
            action: 'index',
            resource: 'flowquestion',
        },
    },
    {
        path: '/question/:id',
        name: 'questionEdit',
        component: () => import(/* webpackChunkName: "questionEdit" */ '@/view/questions/QuestionEdit.vue'),
        meta: {
            action: 'view',
            resource: 'flowquestion',
        },
    },
    {
        path: 'supplier',
        name: 'supplier',
        component: () => import(/* webpackChunkName: "supplier" */ '@/view/supplier/SupplierList.vue'),
        meta: {
            action: 'index',
            resource: 'supplier',
        },
    },
    {
        path: '/supplier/:id',
        name: 'supplierEdit',
        component: () => import(/* webpackChunkName: "supplierEdit" */ '@/view/supplier/SupplierEdit.vue'),
        meta: {
            action: 'view',
            resource: 'supplier',
        },
    },
    {
        path: 'script',
        name: 'script',
        component: () => import(/* webpackChunkName: "script" */ '@/view/script/ScriptList.vue'),
        meta: {
            action: 'index',
            resource: 'script',
        },
    },
    {
        path: '/script/:id',
        name: 'scriptEdit',
        component: () => import(/* webpackChunkName: "scriptEdit" */ '@/view/script/ScriptEdit.vue'),
        meta: {
            action: 'view',
            resource: 'script',
        },
    }, {
        path: 'role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '@/view/role/RoleList.vue'),
        meta: {
            action: 'index',
            resource: 'role',
        },
    },
    {
        path: '/role/:id',
        name: 'roleEdit',
        component: () => import(/* webpackChunkName: "roleEdit" */ '@/view/role/RoleEdit.vue'),
        meta: {
            action: 'view',
            resource: 'role',
        },
    },
    {
        path: 'scheduledtask',
        name: 'scheduledtask',
        component: () => import(/* webpackChunkName: "role" */ '@/view/scheduledtask/ScheduledTaskList.vue'),
        meta: {
            action: 'index',
            resource: 'scheduledtask',
        },
    },
    {
        path: '/scheduledtask/:id',
        name: 'scheduledtaskEdit',
        component: () => import(/* webpackChunkName: "roleEdit" */ '@/view/scheduledtask/ScheduledTaskEdit.vue'),
        meta: {
            action: 'view',
            resource: 'scheduledtask',
        },
    },
    {
        path: 'substatus',
        name: 'substatus',
        component: () => import(/* webpackChunkName: "subStatus" */ '@/view/substatus/SubStatusList.vue'),
        meta: {
            action: 'index',
            resource: 'substatus',
        },
    },
    {
        path: '/substatus/:id',
        name: 'substatusEdit',
        component: () => import(/* webpackChunkName: "subStatusEdit" */ '@/view/substatus/SubStatusEdit.vue'),
        meta: {
            action: 'view',
            resource: 'substatus',
        },
    }
]
