import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    data: null,
    houseNumberAdditions: [],
    loadingSearch: false,
    disabledGlobalSearchButton: true,
    loadingHouseNumberAdditions: false,
    errorSearch: false,
    errorHouseNumberAdditions: false,
    streetViewImageFileURL: null,
});

const actions = {
    /**
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async globalSearch({commit}, data) {
        try {
            await commit('dataBegin');
            const response = await DataService.get('/offerflow/globalsearch', data);
            await commit('dataSuccess', response.data);
        } catch (err) {
            await commit('dataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async globalSearchWithCallback({commit}, data) {
        try {
            await commit('dataBegin');
            const response = await DataService.get('/offerflow/globalsearch', data.value);
            await commit('dataSuccess', response.data);
            data.setAddress(response.data);
        } catch (err) {
            await commit('dataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async houseNumberAdditions({commit}, data) {
        try {
            await commit('dataHouseNumberAdditionsBegin');
            const response = await DataService.get('/search/housenumberadditions', data);
            await commit('dataHouseNumberAdditionsSuccess', response.data);
        } catch (err) {
            await commit('dataHouseNumberAdditionsErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },

    async cleanGlobalSearch({commit}) {
        await commit('cleanGlobalSearch');
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
