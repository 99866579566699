export default {
    dataBegin(state) {
        state.loading = true;
        state.data = {
            postalCode: null,
            houseNumber: null,
            houseNumberAddition: null,
            city: null,
            province: null,
            country: null,
        };
        state.error = false;
    },
    dataSuccess(state, data) {
        state.data = data;
        state.loading = false;
    },
    dataErr(state, err) {
        state.loading = false;
        state.error = err;
    },
};
