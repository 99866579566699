export default {
    questionDataBegin(state) {
        state.loadingQuestion = true;
        state.errorQuestion = false;
    },
    questionDataSuccess(state, data) {
        state.question = data;
        state.showEdit = true;
        state.loadingQuestion = false;
    },
    questionDataErr(state, err) {
        state.loadingQuestion = false;
        state.errorQuestion = err;
    },
    questionsDataBegin(state) {
        state.loadingQuestions = true;
    },
    questionsDataSuccess(state, data) {
        state.questions = data.data;
        // state.count = data.meta.total;
        // state.currentPage = data.meta.current_page;
        state.loadingQuestions = false;
    },
    questionsDataErr(state, err) {
        state.loadingQuestions = false;
        state.errorQuestions = err;
    },
    questionDataCreateBegin(state) {
        state.loadingCreateQuestion = true;
    },
    questionDataCreateSuccess(state) {
        state.loadingCreateQuestion = false;
        state.showEdit = false;
    },
    questionDataCreateErr(state, err) {
        state.loadingCreateQuestion = false;
        state.errorCreateQuestion = err;
    },
    questionDataPatchBegin(state) {
        state.loadingPatchQuestion = true;
    },
    questionDataPatchSuccess(state) {
        state.loadingPatchQuestion = false;
    },
    questionDataPatchErr(state, err) {
        state.loadingPatchQuestion = false;
        state.errorPatchQuestion = err;
    },
    questionDataDeleteBegin(state) {
        state.loadingDeleteQuestion = true;
    },
    questionDataDeleteSuccess(state) {
        state.loadingDeleteQuestion = false;
    },
    questionDataDeleteErr(state, err) {
        state.loadingDeleteQuestion = false;
        state.errorDeleteQuestion = err;
    },
    setCurrentPageQuestions(state, page) {
        state.currentPage = page;
    },
};
