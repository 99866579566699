export default {
    senderDataBegin(state) {
        state.loadingSender = true;
        state.errorSender = false;
    },
    senderDataSuccess(state, data) {
        state.sender = data;
        state.loadingSender = false;
    },
    senderDataErr(state, err) {
        state.loadingSender = false;
        state.errorSender = err;
    },
    sendersDataBegin(state) {
        state.loadingSenders = true;
    },
    sendersDataSuccess(state, data) {
        state.senders = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingSenders = false;
    },
    sendersDataErr(state, err) {
        state.loadingSenders = false;
        state.errorSenders = err;
    },
    senderDataCreateBegin(state) {
        state.loadingCreateSender = true;
    },
    senderDataCreateSuccess(state) {
        state.loadingCreateSender = false;
    },
    senderDataCreateErr(state, err) {
        state.loadingCreateSender = false;
        state.errorCreateSender = err;
    },
    senderDataPatchBegin(state) {
        state.loadingPatchSender = true;
    },
    senderDataPatchSuccess(state) {
        state.loadingPatchSender = false;
    },
    senderDataPatchErr(state, err) {
        state.loadingPatchSender = false;
        state.errorPatchSender = err;
    },
    senderDataDeleteBegin(state) {
        state.loadingDeleteSender = true;
    },
    senderDataDeleteSuccess(state) {
        state.loadingDeleteSender = false;
    },
    senderDataDeleteErr(state, err) {
        state.loadingDeleteSender = false;
        state.errorDeleteSender = err;
    },
    showHideEdit(state, bool) {
        state.showEdit = bool;
    },
    setCurrentPageSenders(state, page) {
        state.currentPage = page;
    },
};
