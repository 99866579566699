import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    calculation: null,
    calculationOrder: null,
    loadingCalculation: false,
    errorCalculation: false,
    loadingCalculationOrder: false,
    errorCalculationOrder: false,
});

const actions = {
    /**
     *
     * Get solar simple calculation
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async getSolarSimpleCalculation({commit}, data) {
        try {
            await commit('solarSimpleCalculationBegin');
            const response = await DataService.get('/offerflow/solarsimple/calculation', data);
            await commit('solarSimpleCalculationSuccess', response.data);
        } catch (err) {
            await commit('solarSimpleCalculationErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    async getSolarSimpleCalculationForOrder({commit}, id) {
        try {
            await commit('solarSimpleCalculationOrderBegin');
            const response = await DataService.get(`/orders/${id}/solarsimplecalculation`);
            await commit('solarSimpleCalculationOrderSuccess', response.data);
        } catch (err) {
            await commit('solarSimpleCalculationOrderErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
