export default {
    profileDataBegin(state) {
        state.loadingProfile = true;
        state.errorProfile = false;
    },
    profileDataSuccess(state, data) {
        state.profile = data;
        localStorage.setItem('id', data.id)
        localStorage.setItem('tenant', data.tenantID)
        state.loadingProfile = false;
    },
    profileDataErr(state, err) {
        state.loadingProfile = false;
        state.errorProfile = err;
    },
    profileDataPatchBegin(state) {
        state.loadingPatchProfile = true;
    },
    profileDataPatchSuccess(state) {
        state.loadingPatchProfile = false;
    },
    profileDataPatchErr(state, err) {
        state.loadingPatchProfile = false;
        state.errorPatchProfile = err;
    },
};
