export default {
    productDataBegin(state) {
        state.errorProduct = false;
        state.loadingProduct = true;
    },
    productDataSuccess(state, data) {
        state.loadingProduct = false;
        if (!data.customerApprovals) {
            data.customerApprovals = [];
        }
        state.product = data;
    },
    productDataErr(state, err) {
        state.loadingProduct = false;
        state.errorProduct = err;
    },
    productsDataBegin(state) {
        state.loadingProducts = true;
    },
    productsDataSuccess(state, data) {
        state.products = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingProducts = false;
    },
    productsDataErr(state, err) {
        state.loadingProducts = false;
        state.errorProducts = err;
    },
    productDataCreateBegin(state) {
        state.loadingCreateProduct = true;
    },
    productDataCreateSuccess(state) {
        state.loadingCreateProduct = false;
    },
    productDataCreateErr(state, err) {
        state.loadingCreateProduct = false;
        state.errorCreateProduct = err;
    },
    productDataPatchBegin(state) {
        state.loadingPatchProduct = true;
    },
    productDataPatchSuccess(state) {
        state.loadingPatchProduct = false;
    },
    productDataPatchErr(state, err) {
        state.loadingPatchProduct = false;
        state.errorPatchProduct = err;
    },
    productDataDeleteBegin(state) {
        state.loadingDeleteProduct = true;
    },
    productDataDeleteSuccess(state) {
        state.loadingDeleteProduct = false;
    },
    productDataDeleteErr(state, err) {
        state.loadingDeleteProduct = false;
        state.errorDeleteProduct = err;
    },
    setCurrentPageProduct(state, page) {
        state.currentPage = page;
    },
    productFlowQuestionsDataBegin(state) {
        state.errorFlowQuestions = false;
        state.loadingFlowQuestions = true;
    },
    productFlowQuestionsDataSuccess(state, data) {
        state.loadingFlowQuestions = false;
        state.productFlowQuestions = data;
    },
    productFlowQuestionsDataErr(state, err) {
        state.loadingFlowQuestions = false;
        state.errorFlowQuestions = err;
    },
    productFlowQuestionDataPatchBegin(state) {
        state.errorPatchFlowQuestions = false;
        state.loadingPatchFlowQuestions = true;
    },
    productFlowQuestionDataPatchSuccess(state) {
        state.loadingPatchFlowQuestions = false;
    },
    productFlowQuestionDataPatchErr(state, err) {
        state.loadingPatchFlowQuestions = false;
        state.errorPatchFlowQuestions = err;
    },
    productFilesDataBegin(state) {
        state.loadingProductFiles = true;
        state.errorProductFiles = false;
    },
    productFilesDataSuccess(state, data) {
        state.files = data.data;
        state.filesCount = data.meta.total;
        state.filesCurrentPage = data.meta.current_page;
        state.loadingProductFiles = false;
    },
    productFilesDataErr(state, err) {
        state.loadingProductFiles = false;
        state.errorProductFiles = err;
    },
    productFilesUploadBegin(state) {
        state.loadingProductFilesUpload = true;
        state.errorProductFilesUpload = false;
    },
    productFilesUploadSuccess(state) {
        state.loadingProductFilesUpload = false;
    },
    productFilesUploadErr(state, err) {
        state.loadingProductFilesUpload = false;
        state.errorProductFilesUpload = err;
    },
    productFileDeleteBegin(state) {
        state.loadingDeleteProductFile = true;
        state.errorDeleteProductFile = false;
    },
    productFileDeleteSuccess(state) {
        state.loadingDeleteProductFile = false;
    },
    productFileDeleteErr(state, err) {
        state.loadingDeleteProductFile = false;
        state.errorDeleteProductFile = err;
    },
    syncTariffForProductBegin(state) {
        state.loadingSyncTariffForProduct = true;
        state.errorSyncTariffForProduct = false;
    },
    syncTariffForProductSuccess(state) {
        state.loadingSyncTariffForProduct = false;
    },
    syncTariffForProductErr(state, err) {
        state.loadingSyncTariffForProduct = false;
        state.errorSyncTariffForProduct = err;
    },
    availableProductTypesBegin(state) {
        state.errorProductTypes = false;
        state.loadingProductTypes = true;
    },
    availableProductTypesSuccess(state, data) {
        state.loadingProduct = false;
        state.types = data;
    },
    availableProductTypesErr(state, err) {
        state.loadingProductTypes = false;
        state.errorProductTypes = err;
    },
};
