import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    trails: [],
    count: 0,
    currentPage: 1,
    pageSize: 20,
    filters: {
        page: 1,
        pageSize: state.pageSize,
    },
    filesCount: 0,
    filesCurrentPage: 1,
});

const actions = {
    /**
     *
     * Get trails (depending on acl)
     *
     * @param commit
     * @returns {Promise<void>}
     */
    async getTrails({commit}, data) {
        try {
            await commit('trailsDataBegin');
            const response = await DataService.get('/audits', data);
            await commit('trailsDataSuccess', response.data);
        } catch (err) {
            await commit('trailsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
    /**
     *
     * @param commit
     * @param page
     * @returns {Promise<void>}
     */
    async setCurrentPageTrail({commit}, page) {
        await commit('setCurrentPageTrail', page);
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
