export default {
    flowsDataBegin(state) {
        state.loadingFlows = true;
        state.errorFlows = false;
    },
    flowsDataSuccess(state, data) {
        state.flows = data;
        state.loadingFlows = false;
    },
    flowsDataErr(state, err) {
        state.loadingFlows = false;
        state.errorFlows = err;
    },
    cleanFlows(state) {
        state.flows = [];
    },
};
