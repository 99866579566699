import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    data: [],
    loading: false,
    error: null,
});

const actions = {
    /**
     *
     * Get relation (depending on acl)
     *
     * @param commit
     * @param data
     * @returns {Promise<void>}
     */
    async searchCocNL({commit}, data) {
        try {
            await commit('dataBegin');
            const response = await DataService.get('/search/cocnl', {q:data});
            await commit('dataSuccess', response.data.data);
        } catch (err) {
            await commit('dataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
