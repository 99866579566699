export default {
    solarSubProductDataBegin(state) {
        state.loadingSolarSubProduct = true;
        state.errorSolarSubProduct = false;
    },
    solarSubProductDataSuccess(state, data) {
        let solarSubProduct = JSON.parse(JSON.stringify(state.solarSubProduct));
        solarSubProduct.active = data.solarSubProduct;
        solarSubProduct.description = data.description;
        solarSubProduct.id = data.id;
        solarSubProduct.imageFile = data.imageFile;
        solarSubProduct.imageFileURL = data.imageFileURL;
        solarSubProduct.maxWattPeak = data.maxWattPeak;
        solarSubProduct.name = data.name;
        solarSubProduct.price = data.price;
        solarSubProduct.productID = data.productID;
        solarSubProduct.roofType = data.roofType;
        solarSubProduct.type = data.type;
        solarSubProduct.wattPeak = data.wattPeak;
        solarSubProduct.active = data.active;
        solarSubProduct.minPanelCount = data.minPanelCount;
        solarSubProduct.maxPanelCount = data.maxPanelCount;
        state.solarSubProduct = solarSubProduct;
        state.loadingSolarSubProduct = false;
    },
    solarSubProductDataErr(state, err) {
        state.loadingSolarSubProduct = false;
        state.errorSolarSubProduct = err;
    },
    solarSubProductsDataBegin(state) {
        state.loadingSolarSubProducts = true;
    },
    solarSubProductsDataSuccess(state, data) {
        state.solarSubProducts = data.data;
        state.count = data.total;
        state.currentPage = data.current_page;
        state.loadingSolarSubProducts = false;
    },
    solarSubProductsDataErr(state, err) {
        state.loadingSolarSubProducts = false;
        state.errorSolarSubProducts = err;
    },
    solarSubProductsForOrderDataBegin(state) {
        state.loadingSolarSubProductsForOrder = true;
        state.errorSolarSubProductsForOrder = true;
        state.subProductsForOrder = [];
    },
    solarSubProductsForOrderDataSuccess(state, data) {
        state.subProductsForOrder = data;
        state.loadingSolarSubProductsForOrder = false;
    },
    solarSubProductsForOrderDataErr(state, err) {
        state.loadingSolarSubProductsForOrder = false;
        state.errorSolarSubProductsForOrder = err;
    },
    solarSubProductDataCreateBegin(state) {
        state.loadingCreateSolarSubProduct = true;
    },
    solarSubProductDataCreateSuccess(state) {
        state.loadingCreateSolarSubProduct = false;
        state.showEdit = false;
    },
    solarSubProductDataCreateErr(state, err) {
        state.loadingCreateSolarSubProduct = false;
        state.errorCreateSolarSubProduct = err;
    },
    solarSubProductDataPatchBegin(state) {
        state.loadingPatchSolarSubProduct = true;
    },
    solarSubProductDataPatchSuccess(state) {
        state.loadingPatchSolarSubProduct = false;
    },
    solarSubProductDataPatchErr(state, err) {
        state.loadingPatchSolarSubProduct = false;
        state.errorPatchSolarSubProduct = err;
    },
    solarSubProductDataDeleteBegin(state) {
        state.loadingDeleteSolarSubProduct = true;
    },
    solarSubProductDataDeleteSuccess(state) {
        state.loadingDeleteSolarSubProduct = false;
    },
    solarSubProductDataDeleteErr(state, err) {
        state.loadingDeleteSolarSubProduct = false;
        state.errorDeleteSolarSubProduct = err;
    },
    setCurrentPageSolarSubProduct(state, page) {
        state.currentPage = page;
    },
};
