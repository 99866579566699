import mutations from './mutations';
import {notification} from "ant-design-vue";
import {DataService} from "../../../config/dataService/dataService";

const addNotificationError = (err) => {
    notification.error({
        message: err,
    });
};

const state = () => ({
    dns: null,
    loadingDns: true,
    errorDns: null,
});

const actions = {
    /**
     *
     * Get dns
     *
     * @param commit
     * @param id
     * @returns {Promise<void>}
     */
    async getDns({commit}, id) {
        try {
            await commit('dnsDataBegin');
            const response = await DataService.get(`/senders/${id}/dnsrecords`);
            await commit('dnsDataSuccess', response.data);
        } catch (err) {
            await commit('dnsDataErr', err);
            addNotificationError(err?.response?.data?.message);
        }
    },
};

export default {
    namespaced: false,
    state,
    actions,
    mutations,
};
