export default {
    scriptDataBegin(state) {
        state.loadingScript = true;
        state.script = {
            id: null,
            name: null,
            description: '',
        };
        state.errorScript = false;
    },
    scriptDataSuccess(state, data) {
        state.script = data;
        state.showEdit = true;
        state.loadingScript = false;
    },
    scriptDataErr(state, err) {
        state.loadingScript = false;
        state.errorScript = err;
    },
    scriptsDataBegin(state) {
        state.loadingScripts = true;
    },
    scriptsDataSuccess(state, data) {
        state.scripts = data.data;
        state.count = data.meta.total;
        state.currentPage = data.meta.current_page;
        state.loadingScripts = false;
    },
    scriptsDataErr(state, err) {
        state.loadingScripts = false;
        state.errorScripts = err;
    },
    scriptDataCreateBegin(state) {
        state.loadingCreateScript = true;
    },
    scriptDataCreateSuccess(state) {
        state.loadingCreateScript = false;
        state.showEdit = false;
    },
    scriptDataCreateErr(state, err) {
        state.loadingCreateScript = false;
        state.errorCreateScript = err;
    },
    scriptDataPatchBegin(state) {
        state.loadingPatchScript = true;
    },
    scriptDataPatchSuccess(state) {
        state.loadingPatchScript = false;
    },
    scriptDataPatchErr(state, err) {
        state.loadingPatchScript = false;
        state.errorPatchScript = err;
    },
    scriptDataDeleteBegin(state) {
        state.loadingDeleteScript = true;
    },
    scriptDataDeleteSuccess(state) {
        state.loadingDeleteScript = false;
    },
    scriptDataDeleteErr(state, err) {
        state.loadingDeleteScript = false;
        state.errorDeleteScript = err;
    },
    setCurrentPageScript(state, page) {
        state.currentPage = page;
    },
};
