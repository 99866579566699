export default [
    {
        name: 'userList',
        path: 'user',
        component: () => import(/* webpackChunkName: "user" */ '@/view/user/UserList.vue'),
        meta: {
            action: 'index',
            resource: 'user',
        },
    },
    {
        name: 'userEdit',
        path: 'user/:id',
        component: () => import(/* webpackChunkName: "userEdit" */ '@/view/user/UserEdit.vue'),
        meta: {
            action: 'view',
            resource: 'user',
        },
    },
    {
        name: 'team',
        path: 'team',
        component: () => import(/* webpackChunkName: "team" */ '@/view/team/TeamList.vue'),
        meta: {
            action: 'index',
            resource: 'team',
        },
    },
    {
        name: 'teamEdit',
        path: 'team/:id',
        component: () => import(/* webpackChunkName: "teamEdit" */ '@/view/team/TeamEdit.vue'),
        meta: {
            action: 'view',
            resource: 'team',
        },
    },
    {
        name: 'organizationList',
        path: 'organization',
        component: () => import(/* webpackChunkName: "organization" */ '@/view/organization/OrganizationList.vue'),
        meta: {
            action: 'index',
            resource: 'organisation',
        },
    },
    {
        name: 'organizationEdit',
        path: 'organization/:id',
        component: () => import(/* webpackChunkName: "organizationEdit" */ '@/view/organization/OrganizationEdit.vue'),
        meta: {
            action: 'view',
            resource: 'organisation',
        },
    },
    {
        name: 'senderList',
        path: 'sender',
        component: () => import(/* webpackChunkName: "sender" */ '@/view/sender/SenderList.vue'),
        meta: {
            action: 'index',
            resource: 'sender',
        },
    },
    {
        name: 'senderEdit',
        path: 'sender/:id',
        component: () => import(/* webpackChunkName: "senderEdit" */ '@/view/sender/SenderEdit.vue'),
        meta: {
            action: 'view',
            resource: 'sender',
        },
    },
    {
        path: 'me',
        name: 'me',
        redirect: '/me/profile',
        component: () => import(/* webpackChunkName: "me" */ '@/view/me/Me.vue'),
        children: [
            {
                path: 'profile',
                name: 'profile',
                component: () => import(/* webpackChunkName: "profile" */ '@/view/me/overview/Profile.vue'),
            },
            {
                path: 'password',
                name: 'mePassword',
                component: () => import(/* webpackChunkName: "mePassword" */ '@/view/me/overview/Password.vue'),
            },
        ]
    },
    {
        name: 'apps',
        path: 'apps',
        component: () => import(/* webpackChunkName: "apps" */ '@/view/apps/Apps.vue'),
        meta: {
            action: 'list',
            resource: 'apps',
        },
    },
]
